
export default {
  name: "Footer",
  methods: {
    verAvisoPrivacidad() {
      const rutaPDF = process.env.BASE_URL + "pdfs/Aviso_simplificado_de_tramites_en_linea.pdf";

      window.open(rutaPDF, "_blank");
      
    },
  },
};
