
import Vue from "vue";
import CambioActividadEconomicaService from '@/services/CambioActividadEconomicaService';
import { Establecimiento } from '@/models/Establecimiento';
import EventBus from "@/plugins/event-bus";
import CustomFile from "@/components/Common/CustomFile.vue";
import rules from '@/mixins/rules';
import EstablecimientoService from '@/services/EstablecimientoService';
import { CambioActividadEconomica } from "@/models/CambioActividadEconomica";
import ContribuyenteService from '@/services/ContribuyenteService';
import { mapGetters } from "vuex";

export default Vue.extend({
    name: 'CambioActividadEconomica',
    mixins: [rules],
    components: {
        CustomFile,
    },
    props: {
        establecimiento: {
            type: Object,
            default: {} as any,
        },
        dialogPrincipal: {
            type: Boolean,
            default: false,

        },
    },
    data() {
        return {
            dialog: false,
            dialogView: false,
            dialogRechazar: false,
            dialogGiro: false,
            documento: {} as any,
            formData: {
                ...new Establecimiento(),
            },
            formDataNueva: new CambioActividadEconomica(),
            disabled: false,
            solicitudes: [],
            solicitud: {} as any,
            idSolicitud: null,
            motivoRechazo: '',
            actividades: [] as any,
            actividadesSecundarias: [] as any,
            isForLicenses: false,
            alertMessage: '',
            solicitud_giro: "",
        }
    },
    async mounted() {
        await EstablecimientoService.getActividades().then((response) => {
            this.actividades = response.data;
        });
        await EstablecimientoService.getActividadesSecundarias().then((response) => {
            this.actividadesSecundarias = response.data;
        });
    },
    watch: {
        dialogPrincipal: {
            handler(newVal) {
                if (this.dialogPrincipal) {
                    this.getSolicitudesCambioActividadEconomica(this.establecimiento);
                }
            },
            deep: true,
        },
        dialog() {
            if (this.dialog) {
                if (!this.formDataNueva.id) {
                    this.formDataNueva.has_alcohol = this.hasAlcohol;
                }
            }
        },
    },
    methods: {
        getActividadesEconomicas() {
            CambioActividadEconomicaService.getActividadesEconomicas(this.establecimiento.id)
                .then((response) => {
                    this.formData.actividades_secundarias = response.data;
                })
        },
        agregarSolicitud() {
            this.dialog = true;
            this.getActividadesEconomicas();
        },
        closeDialog() {
            this.disabled = false;
            this.dialog = false;
            this.solicitud = {};
            let cambioActividadEconomica = new CambioActividadEconomica();
            this.formDataNueva = {
                ...new CambioActividadEconomica(),
            }
        },
        getSolicitudesCambioActividadEconomica(item: any) {
            CambioActividadEconomicaService.getSolicitudesCambioActividadEconomica(item.id)
                .then((response) => {
                    //console.log(response);
                    this.solicitudes = response.data;
                })
        },
        cerrar() {
            this.disabled = false;
            this.formDataNueva = new CambioActividadEconomica();
            this.$emit('close', this.dialog);
        },
        guardarSolicitud(item: any) {
            // @ts-ignore
            let validate = this.$refs.form.validate();
            if (validate) {
                EventBus.$emit("update-direccion");
                const formData = this.objectFormData(this.formDataNueva);
                CambioActividadEconomicaService.setSolicitudCambioActividadEconomica(item.id, formData)
                    .then((response) => {
                        this.$toast.success(response.data.message);
                        let data = response.data.data;
                        this.verSolicitud(data);
                        this.getSolicitudesCambioActividadEconomica(this.establecimiento);
                    })
                    .catch((error) => {
                        this.$toast.error(error.response.data.message);
                    })
            } else {
                this.$toast.warning('Por favor, completa los campos obligatorios');
            }
        },
        objectFormData(data: any) {
            const formData = new FormData();
            formData.append(`id`, data.id);
            formData.append(`renovacion`, data.renovacion as any);
            formData.append(`has_licencia_bebidas`, data.has_licencia_bebidas as any);
            formData.append(`patente_propia`, data.patente_propia ? 1 : 0 as any);
            formData.append(`folio_licencia_bebida_alcoholica`, data.folio_licencia_bebida_alcoholica);
            formData.append(`has_alcohol`, this.hasAlcohol ? 1 : 0 as any);
            formData.append(`giro_actividad_id`, data.giro_actividad_id.id_giro);
            formData.append(`numero_habitaciones`, data.numero_habitaciones);

            Object.entries(data.actividades_secundarias).forEach(([key, value]) => {
                let v = value as any;
                const baseKey = `actividades_secundarias[${key}]`;
                formData.append(`${baseKey}`, v.id_giro);
            });

            Object.entries(data.documentos).forEach(([key, documento]) => {
                let d = documento as any;
                const baseKey = `files[${key}]`;
                formData.append(`${baseKey}[file]`, d.file);
                formData.append(`${baseKey}[code]`, key);
            });

            return formData;
        },
        verSolicitud(item: any) {
            CambioActividadEconomicaService.getSolicitudCambioActividadEconomica(this.establecimiento.id, item.id)
                .then((response) => {
                    this.dialog = true;
                    let solicitud = response.data;
                    this.solicitud = solicitud;
                    this.formDataNueva.id = solicitud.id;
                    this.formDataNueva.folio_licencia_bebida_alcoholica = item.folio_licencia_bebida_alcoholica;
                    this.formDataNueva.has_alcohol = item.has_alcohol ? true : false;
                    this.formDataNueva.giro_actividad_id = solicitud.giro_opb;
                    this.formDataNueva.actividades_secundarias = solicitud.actividades_economicas;
                    this.setFiles(solicitud);
                    this.getActividadesEconomicas();
                    if (this.solicitud.estatus_id != 1) {
                        this.disabled = true;
                    }
                })
                .catch((error) => {
                    console.log(error);
                })
        },
        setFiles(solicitud: any) {
            if (solicitud.constancia_r_s) {
                const rs = solicitud.constancia_r_s;
                this.formDataNueva.documentos['scdd_constancia_rs'].code = 'scdd_constancia_rs';
                this.formDataNueva.documentos['scdd_constancia_rs'].data = rs;
            }

            if (solicitud.saneamiento) {
                const saneamiento = solicitud.saneamiento;
                this.formDataNueva.documentos['scdd_saneamiento'].code = 'scdd_saneamiento';
                this.formDataNueva.documentos['scdd_saneamiento'].data = saneamiento;
            }

            if (solicitud.licencia_alcohol) {
                const licenciaAlcohol = solicitud.licencia_alcohol;
                this.formDataNueva.documentos['licencia'].code = 'licencia';
                this.formDataNueva.documentos['licencia'].data = licenciaAlcohol;
            }

            if (solicitud.licencia_bebida) {
                const licenciaBebidas = solicitud.licencia_bebida;
                this.formDataNueva.documentos['pago_licencia_bebidas'].code = 'licencia_bebidas';
                this.formDataNueva.documentos['pago_licencia_bebidas'].data = licenciaBebidas;
            }

            if (solicitud.licencia_comodato) {
                const licenciaComodato = solicitud.licencia_comodato;
                this.formDataNueva.documentos['licencia_comodato'].code = 'licencia_comodato';
                this.formDataNueva.documentos['licencia_comodato'].data = licenciaComodato;
            }

            if (solicitud.impuesto_hospedaje) {
                const impuestoHospedaje = solicitud.impuesto_hospedaje;
                this.formDataNueva.documentos['impuesto_hospedaje'].code = 'impuesto_hospedaje';
                this.formDataNueva.documentos['impuesto_hospedaje'].data = impuestoHospedaje;
            }
        },
        changeStatusSolicitudCambioActividadEconomica(item: any, estatus: any) {
            let data = {
                estatus_id: estatus
            }
            CambioActividadEconomicaService.changeStatusSolicitudCambioActividadEconomica(this.establecimiento.id, item.id, data)
                .then((response) => {
                    if (response.data.success) {
                        this.$toast.success(response.data.message);
                        this.getSolicitudesCambioActividadEconomica(this.establecimiento);
                    } else {
                        this.$toast.error(response.data.message);
                    }
                })
                .catch((error) => {
                    this.$toast.error(error.response.data.message);
                });
        },
        closeDialogRechazar() {
            this.disabled = false;
            this.dialogRechazar = false;
            this.motivoRechazo = '';
            this.solicitud = {};
        },
        verMotivoRechazo(item: any) {
            this.solicitud = item;
            this.dialogRechazar = true;
            this.motivoRechazo = item.motivo_rechazo;
        },
        checkAlcoholActivities() {
            if (this.formData.tipo_proceso_id == 2) {
                if (this.hasAlcohol) {
                    this.alertMessage = 'Tu establecimiento tiene un giro y/o actividad secundaria de Alcohol, por lo tanto, tu solicitud será cambiada de área al Departamento de Licencias de Funcionamiento.';
                    this.isForLicenses = true;
                }
            }
        },
        async sendGiro() {
            const object = {
                'solicitud_giro': this.solicitud_giro,
            }
            let { data } = await ContribuyenteService.sendGiro(this.contribuyente.id, object);
            if (data.success) {
                this.$toast.success(data.message);
                this.dialog = false;
            } else {
                this.$toast.error(data.message)
            }
        },
        updateSolicitudCambioActividadEconomica(item: any) {
            // @ts-ignore
            let validate = this.$refs.form.validate();
            if (validate) {
                EventBus.$emit("update-direccion");
                const formData = this.objectFormData(this.formDataNueva);
                CambioActividadEconomicaService.updateSolicitudCambioActividadEconomica(this.establecimiento.id, item.id, formData)
                    .then((response) => {
                        this.$toast.success(response.data.message);
                        let data = response.data.data;
                        this.verSolicitud(data);
                        this.getSolicitudesCambioActividadEconomica(this.establecimiento);
                    })
                    .catch((error) => {
                        this.$toast.error(error.response.data.message);
                    })
            } else {
                this.$toast.warning('Por favor, completa los campos obligatorios');
            }
        }
    },
    computed: {
        ...mapGetters({
            usuario: "usuario",
            id: "id",
            contribuyente: "contribuyente",
            mini: "sidebar",
        }),
        esHoteleria: function () {
            let hasHotel = this.formDataNueva.actividades_secundarias.find((item: any) => item.d_giro.includes("HOTEL"));

            if (hasHotel != undefined) {
                return true;
            }

            if (this.formDataNueva.giro_actividad_id != null) {
                //@ts-ignore
                if (this.formDataNueva.giro_actividad_id?.descripcion) {
                    //@ts-ignore
                    if (this.formDataNueva.giro_actividad_id.descripcion.includes("HOTEL") || this.formDataNueva.giro_actividad_id.descripcion.includes("MOTEL")) {
                        return true;
                    }
                }
                //@ts-ignore
                if (this.formDataNueva.giro_actividad_id?.d_giro) {
                    //@ts-ignore
                    if (this.formDataNueva.giro_actividad_id.d_giro.includes("HOTEL") || this.formDataNueva.giro_actividad_id.d_giro.includes("MOTEL")) {
                        return true;
                    }
                }
            } else {
                return false;
            }
            return false;
        },
        hasAlcohol: function () {
            let hasPatente = this.formDataNueva.actividades_secundarias.find((item: any) => item.req_patente == "SI");

            if (hasPatente != undefined) {
                return true;
            } else {
                /* this.formData.horario_extendido = false; */
            }

            if (this.formDataNueva.giro_actividad_id != null) {
                //@ts-ignore
                if (this.formDataNueva.giro_actividad_id.req_patente == "SI") {
                    return true;
                } else {
                    /* this.formData.horario_extendido = false; */
                }
            } else {
                /* this.formData.horario_extendido = false; */
                return false;
            }
            return false;
        }
    }
})
