
import Vue from 'vue'
import { VForm } from "@/types/formvalidate";
import UnMask from '@/common/unmask';
import EventBus from "@/plugins/event-bus";

export default Vue.extend({
    name: "PhoneInput",
    components: {
    },
    props: {
        value: String,
        label: {
            type: String,
            default: 'Teléfono'
        },
        required: {
            type: Boolean,
            default: false,
        },
        disabled: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            phone: '',
            refresh: 0,
            rulesComponent: [],
            rules: {
                required: (v: any) => (!!v || v===0) || 'El campo es requerido',
                phone: (v: string) => {
                  if((v || '').length==0){
                      return true;
                  }else{
                    return (v || '').length == 14 || 'Proporcione 10 digitos';
                  }
                },
            }
        }
    },
    computed: {
        form(): VForm {
            return this.$refs.form as VForm;
        },
    },
    watch: {
        value: function(value){
            this.phone = value;
            this.refresh++;
        },
        required: {
            handler(value){
                if(value){
                    //@ts-ignore
                    this.rulesComponent.push(this.rules.required);
                }
            },
            immediate: true
        }
    },
    mounted() {
        //@ts-ignore
        this.rulesComponent.push(this.rules.phone);
    },
    methods: {
        updateData(e: any) {
            //this.$emit('input', this.unMaskPhone(e));
            this.$emit('input', UnMask.unmask(e, '(###) ### ####'));
        },
        unMaskPhone(obj: string): string{
            if(!obj)
                return '';
            return obj.replace("(", "")
                    .replace(")", "")
                    .replaceAll(" ", "")
        }
    }

});

