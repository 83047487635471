import { DefaultService } from "@/services/DefaultService";
import API from "@/plugins/axios";

class HorarioService extends DefaultService {
  constructor() {
    super('horarios');
  }
  getHorarioOrdinario(id: any): Promise<any>{
    return API.get(`horarios/gethorarioordinario/${id}`);
  }

  getHorarioExtraordinario(id: any): Promise<any>{
    return API.get(`horarios/gethorarioextraordinario/${id}`);
  }

  getEstablecimientos(): Promise<any>{
    return API.get(`horarios/establecimientos`);
  }
}

export default new HorarioService;