
/**
 * TODO: Queda pendiente agregar a los otros dos formularios lo mismo que tiene RequestForm.
 * Asi mismo, debo validar si usaré el EstablishmentService.
 */
import Vue from "vue";
import RULES from "@/mixins/rules";
import { mapGetters } from "vuex";
import RequestFormGral from "../RequestFormGral.vue";
import LicenciaService from "@/services/LicenciaService";

export default Vue.extend({
  name: "CreateEstablishment",
  mixins: [RULES],
  directives: {},
  components: {
    RequestFormGral
  },
  computed: {
    ...mapGetters({
      usuario: "usuario",
      id: "id",
      contribuyente: "contribuyente",
      mini: "sidebar",
    }),
  },
  data: () => ({
    tipo_proceso: "0" as any,
    sucursal: "0" as any,
    guardar: false,
    actualizar: false,
    enviado_revision: false,
    creado: false,
    id_creado: 0,
    id_licencia_creada: 0
  }),
  mounted() {
    this.tipo_proceso = this.$route.query.tipo;
    this.sucursal     = this.$route.query.sucursal;
  },
  methods: {
    async submitForm(payload: any){
      console.log("form submited", payload);
      this.guardar = false;
      if (payload.success) {
        this.creado = true;
        this.id_creado = payload.location.id;
        this.id_licencia_creada = payload.location.licencia[0].id;
      }
    },
    async enviarRevision(){
      const object = {
        'estatus_licencia': 10,
      }
      //@ts-ignore
      this.$refs.requestFormGral.handleFormSubmit(2);
      console.log('this.$refs.requestFormGral.handleFormSubmit()');
      let { data } = await LicenciaService.changeEstatus(this.id_licencia_creada, object);
      if (data.success) {
        this.enviado_revision = true;
        this.$toast.success(data.message);
      } else {
        this.$toast.error(data.message)
      }
    },
  }
});
