import {DefaultService} from "@/services/DefaultService";
import API from "@/plugins/axios";

class UserService extends DefaultService{

    constructor() {
        super('usuarios');
    }

    getRfcData(rfc: any): Promise<any>{
        return API.post(`/contribuyentes/${rfc}/getDataOPB`);
    }

    concesionarioByRFC(rfc: any): Promise<any>{
        return API.get(`/locales-mercados/concesionarioByRFC/${rfc}`);
    }
}

export default new UserService();
