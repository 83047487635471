
import Vue from "vue";
import router from "@/router";
import LicenciaMovimientoService from "@/services/LicenciaMovimientoService";

export default Vue.extend({
    name: "CambioDomicilio",
    data() {
        return {
            licecniaId: this.$route.params.licecniaId,
            establecimiento: this.$route.params.establecimiento,
            licenciaMovimientos: [] as any,
            data: {
                tipo_movimiento_id: 2
            }
        }
    },
    methods: {
        async getData() {
            await LicenciaMovimientoService.getMovementsByType(this.licecniaId, this.data).then((response) => {
                this.licenciaMovimientos = response.data;
            })
        },
    },
    mounted() {
        this.getData();
    }
});
