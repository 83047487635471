import { DefaultService } from "@/services/DefaultService";
import API from "@/plugins/axios";

class CambioActividadEconomicaService extends DefaultService {
  
  constructor() {
    super('cambioactividadeconomica');
  }

  getActividadesEconomicas(establecimiento: any): Promise<any>{
    return API.get(`cambioactividadeconomica/getActividadesEconomicas/${establecimiento}`);
  }

  getSolicitudesCambioActividadEconomica(establecimiento: any): Promise<any>{
    return API.get(`cambioactividadeconomica/getSolicitudesCambioActividadEconomica/${establecimiento}`);
  }

  setSolicitudCambioActividadEconomica(establecimiento: any, data: any): Promise<any>{
    return API.post(`cambioactividadeconomica/setSolicitudCambioActividadEconomica/${establecimiento}`, data);
  }

  getSolicitudCambioActividadEconomica(establecimiento: any, solicitud: any): Promise<any>{
    return API.get(`cambioactividadeconomica/getSolicitudCambioActividadEconomica/${establecimiento}/${solicitud}`);
  }

  changeStatusSolicitudCambioActividadEconomica(establecimiento: any, solicitud: any, data: any): Promise<any>{
    return API.post(`cambioactividadeconomica/changeStatusSolicitudCambioActividadEconomica/${establecimiento}/${solicitud}`, data);
  }

  updateSolicitudCambioActividadEconomica(establecimiento: any, solicitud: any, data: any): Promise<any>{
    return API.post(`cambioactividadeconomica/updateSolicitudCambioActividadEconomica/${establecimiento}/${solicitud}`, data);
  }
}

export default new CambioActividadEconomicaService;