
import Vue from "vue";
import EstablecimientoService from '@/services/EstablecimientoService';
import CustomViewPDF from '@/components/Common/CustomViewPDF.vue';

export default Vue.extend({
    name: 'Header',
    mixins: [],
    directives: {},
    components: {CustomViewPDF},
    props: {
        establecimientoId: {
            type: Number,
        },
        total: {
            type: Number,
        },
        nombre: {
            type: String,
        },
        disabled: {
            type: Boolean,
            default: false
        }
    },
    data: () => ({
        dialog: false,
        notificaciones: [],
        totalNotificaciones: null
    }),
    created() {
    },
    mounted() {
        //@ts-ignore
        this.totalNotificaciones = this.total;
    },
    computed: {
    },
    watch: {
        dialog: function(value) {
            if(value){
                this.getNotifications();
            } else {
                this.notificaciones = [];
            }
        }
    },
    methods: {
        cerrarNotify() {
            this.dialog = false;
        },
        leerNotify(notify: any, index: number) {
            this.notificaciones.splice(index, 1);
            //@ts-ignore
            --this.totalNotificaciones;
            EstablecimientoService.readNotificaciones(this.establecimientoId, notify.id).then(response => {
            });
        },
        getNotifications() {
            EstablecimientoService.getNotificaciones(this.establecimientoId).then((response) => {
                this.notificaciones = response.data;
            }) 
        },

    }
});
