interface _Representante {
    nombre: string | null
    apellido1: string | null
    apellido2: string | null
    telefono_fijo: number | null
    telefono_movil: number | null
    telefono_oficina: number | null
    email: string | null
    identificacion: any
    identificacion_binary: any
    documento: any
    documento_binary: any
    testigo1: any
    testigo1_binary: any
    testigo2: any
    testigo2_binary: any
    tipo: string
}

class Representante implements _Representante{
    nombre: string
    apellido1: string
    apellido2: string
    telefono_fijo: number | null
    telefono_movil: number | null
    telefono_oficina: number | null
    email: string | null
    identificacion: any
    identificacion_binary: any
    documento: any
    documento_binary: any
    testigo1: any
    testigo1_binary: any
    testigo2: any
    testigo2_binary: any
    tipo: string

    constructor() {
        this.nombre = ''
        this.apellido1 = ''
        this.apellido2 = ''
        this.telefono_fijo = null
        this.telefono_movil = null
        this.telefono_oficina = null
        this.email = null
        this.identificacion = null
        this.identificacion_binary = '';
        this.documento = null
        this.documento_binary = '';
        this.tipo = ''
        this.testigo1 = null
        this.testigo1_binary = '';
        this.testigo2 = null
        this.testigo2_binary = '';
    }
}

export {Representante, _Representante}