
import Vue from 'vue'
import UserService from '@/services/UserService'
import {_Usuario,Usuario} from '@/models/Usuario'
import RULES from '@/mixins/rules'
import {VForm} from '@/types/formvalidate'
import UnMask from '@/common/unmask'
import {mapGetters} from "vuex";

export default Vue.extend({
  name: 'FormAdmin',
  props: {
    show: {
      type: Boolean,
      default: false
    },
    usuario: {
      type: Object as () => _Usuario,
      default: null,
      required: false,
    }
  },
  mixins: [RULES],
  directives: {},
  data: () => ({
    catalogos: {
      tipo_usuarios: [
        {id: 'administrador', label: 'Administrador'},
        {id: 'operativo', label: 'Operativo'},
        {id: 'profesionista', label: 'Profesionista'},
        {id: 'brigadista', label: 'Brigadista'},
        {id: 'coordinador', label: 'Coordinador'},
        {id: 'analista', label: 'Analista'},
      ],
      servicios: [
        {id: 1, label: 'Medicina General'},
        {id: 2, label: 'Psicología'},
        {id: 3, label: 'Podología'},
        {id: 4, label: 'Nutrición'},
        {id: 5, label: 'Activación Física'},
      ],
      sexo: [
        {id:'H', label: 'Hombre'},
        {id:'M', label: 'Mujer'},
      ]
    },
    dialog: false,
    modal: false,
    showNip: false,
    showNip2: false,
    formUser: new Usuario(),
    formDefault: new Usuario(),
  }),
  mounted() {
    if(this.$store.state.role==='administrador')
      this.catalogos.tipo_usuarios.splice(0,1);
  },
  computed: {
    isProfesionista(){
      return this.formUser.tipo_usuario==='profesionista';
    },
    form(): VForm {
      return this.$refs.form as VForm
    },
  },
  watch: {
    usuario: {
      immediate: true,
      deep: true,
      handler(val){
        if(val!=null){
          let tmp_phone = val.telefono;
          this.formUser = Object.assign({}, val);
          // @ts-ignore
          this.formUser.telefono = this.$root.$options.filters.VMask(tmp_phone,'(###) ### ####');
        }else
          this.formUser = Object.assign({}, this.formDefault)
      }
    },
    show: {
      immediate: true,
      handler(val) {
        this.dialog = val;
      }
    }
  },
  methods: {
    close(reload: boolean = false) {
      this.form.resetValidation();
      this.$emit('close-dialog', reload);
    },
    async save() {
      if (this.form.validate()) {
        let dataForm = Object.assign({},this.formUser);
        dataForm.telefono = UnMask.unmask(dataForm.telefono, '(###) ### ####')
        let response;
        if(this.formUser.id!=null)
          response = await UserService.update(this.formUser.id, dataForm);
        else
          response = await UserService.create(dataForm);
        let {data} = response;
        if(data.success){
          this.formUser = Object.assign({}, this.formDefault);
          this.$toast.success(`Usuario registrado exitosamente`);
          this.close(true);
        }else{
          this.$toast.error(data.message);
        }
      }else
        this.$toast.error(`Por favor ingrese la información en los campos marcados`);
    }
  }
})
