
import Vue from 'vue';
import { VInput } from 'vuetify/lib';
import { Anuncio } from '@/models/Anuncio';
import AnuncioService from '@/services/AnuncioService';
import MaterialService from '@/services/MaterialService';
import SectorService from '@/services/SectorService';
import GiroDesarrolloService from '@/services/GiroDesarrolloService';
import ZonaUsoService from '@/services/ZonaUsoService';
import RULES from '@/mixins/rules';

export default Vue.extend(
  {
    props: {
      value2: Object,
    },
    mixins: [RULES],
    data: () => ({
      defaultLatitud: 18.520326,
      defaultLongitud: -88.305718,
      date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      menu: false,
      loading: false,
      editable: false,
      formData: new Anuncio(),
      disabled: false,
      disabledForm: {
        localidad: true,
        asentamiento: true,
      },
      sectores: [] as any[],
      zona_usos: [] as any[],
      giro_desarrollos: [] as any[],
    }),
    methods: {
      async handleFormSubmit() {
        
        this.disabled = true;        
        const formData = new FormData();
        
        Object.keys(this.formData).forEach((key) => {
          const value = this.formData[key];
        });

        this.formData.locacion_id = 1;
        let response = await AnuncioService.create(this.formData).then((response) => {
          this.$toast.success('Anuncio registrado exitosamente');
          this.$emit('formSubmitted', response.data);
        }).catch((error) => {
          this.disabled = false;
        });
      },
      async getData() {
        await SectorService.getAll().then((response) => {
          this.sectores = response.data;
        });
        await GiroDesarrolloService.getAll().then((response) => {
          this.giro_desarrollos = response.data;
        });
        await ZonaUsoService.getAll().then((response) => {
          this.zona_usos = response.data;
        });
      },
    },
    components: { VInput },
    computed: {},
    mounted() {
      this.getData();
    }
  }
);
