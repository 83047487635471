import { DefaultService } from "@/services/DefaultService";
import API from "@/plugins/axios";

class AnuncioService extends DefaultService {
  
  constructor() {
    super('anuncio');
  }

  getByEstablecimiento(id: string): Promise<any>{
    return API.get(`anuncio/establecimiento/${id}`);
  }

  changeStatus(id: number, data: any): Promise<any>{
    return API.post(`anuncio/${id}/changestatus`, data);
  }

  viewPrintedRequest(url: any, data: any, type: any, name: string){
    return API.request({
        url: url,
        data: data,
        method: type,
        responseType: 'blob'
    });
  }

    generarFmtAutorizacion(url: any, type: any, name: string): Promise<any>{
        return API.request({
            url,
            method: type,
            responseType: 'blob'
        })
    }

}

export default new AnuncioService;