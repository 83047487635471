interface _OtroDomicilio{
    id: any
    is_otra_colonia: boolean
    otra_colonia: string
    otra_calle: string
    otra_cruzamiento1: string
    otra_cruzamiento2: string
}

class OtroDomicilio implements _OtroDomicilio{
    id: any
    is_otra_colonia: boolean
    otra_colonia: string
    otra_calle: string
    otra_cruzamiento1: string
    otra_cruzamiento2: string
    constructor() {
        this.id = null
        this.is_otra_colonia = false
        this.otra_colonia = ''
        this.otra_calle = ''
        this.otra_cruzamiento1 = ''
        this.otra_cruzamiento2 = ''
    }
}
interface _Domicilio{
    id: string | null
    codigo_postal: string
    entidad_id: number | null
    municipio_id: number | null
    localidad_id: number | null
    colonia_id: number | null
    tipo_vialidad_id: number | null
    calle_id: number | null
    numero_exterior: string
    numero_interior: string
    cruzamiento1_id: number | null
    cruzamiento2_id: number | null
    cruzamiento3_id: number | null
    latitud: number | null
    longitud: number | null
    croquis: string
    otro_domicilio: OtroDomicilio
}

class Domicilio implements _Domicilio{
    id: string | null
    codigo_postal: string
    entidad_id: number | null
    municipio_id: number | null
    localidad_id: number | null
    colonia_id: number | null
    tipo_vialidad_id: number | null
    calle_id: number | null
    numero_exterior: string
    numero_interior: string
    cruzamiento1_id: number | null
    cruzamiento2_id: number | null
    cruzamiento3_id: number | null
    latitud: number | null
    longitud: number | null
    croquis: string
    otro_domicilio: OtroDomicilio

    constructor() {
        this.id = null;
        this.codigo_postal = ''
        this.entidad_id = 23
        this.municipio_id = 4
        this.localidad_id = null
        this.colonia_id = null
        this.tipo_vialidad_id = null
        this.calle_id = null
        this.numero_exterior = ''
        this.numero_interior = ''
        this.cruzamiento1_id = null
        this.cruzamiento2_id = null
        this.cruzamiento3_id = null
        this.latitud = null
        this.longitud = null
        this.croquis = '0'
        this.otro_domicilio = new OtroDomicilio()
    }
}

export {Domicilio, _Domicilio}