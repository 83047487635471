
import Vue from 'vue'
import { VForm } from "@/types/formvalidate";
import UnMask from '@/common/unmask';
import EventBus from "@/plugins/event-bus";
import createNumberMask from 'text-mask-addons/dist/createNumberMask';

export default Vue.extend({
    name: "MoneyInput",
    components: {
    },
    props: {
        value: Number,
        label: String,
        required: {
            type: Boolean,
            default: false,
        },
        prefix: {
            type: String,
            default: ''
        },
        allowDecimal: {
            type: Boolean,
            default: true
        },
        includeThousandsSeparator: {
            type: Boolean,
            default: true
        },
        allowNegative: {
            type: Boolean,
            default: true
        },
        external_prefix: {
            type: String,
            default: ''
        },
        external_suffix: {
            type: String,
            default: ''
        },
        disabled: {
            type: Boolean,
            default: false
        }
    },
    data: () => ({
        currencyMask: null,
        myInputModel: '',
        rulesComponent: [],
        refresh: 0,
        rules: {
                required: (v: any) => (!!v || v===0) || 'El campo es requerido',
        }
    }),
    computed: {
        form(): VForm {
            return this.$refs.form as VForm;
        },
    },
    watch: {
        value: function(value){
            this.myInputModel = value.toString();
            this.refresh++;
        },
        required: {
            handler(value){
                if(value){
                    //@ts-ignore
                    this.rulesComponent.push(this.rules.required);
                }
            },
            immediate: true
        }
    },
    mounted() {
        //@ts-ignore
        //this.rulesComponent.push(this.rules.phone);
        this.currencyMask = createNumberMask({
            prefix: this.prefix,
            allowDecimal: this.allowDecimal,
            includeThousandsSeparator: this.includeThousandsSeparator,
            allowNegative: this.allowNegative,
            integerLimit: 9,
        })
        this.myInputModel = this.value.toString();
    },
    methods: {
        updateData(e: any) {
            //this.$emit('input', this.unMaskPhone(e));
            this.$emit('input', e.replace(/,/g,''));
        },
    }

});

