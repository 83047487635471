
import Vue from 'vue'
import RULES from "@/mixins/rules"
import {Domicilio, _Domicilio} from "@/models/Domicilio";
import DomicilioService from "@/services/DomicilioService";
import {VForm} from "@/types/formvalidate";
import EventBus from "@/plugins/event-bus";
import { googlemaps } from "googlemaps";

export default Vue.extend({
  name: 'Direccion',
  props: {
    domicilio: {
      type: Domicilio,
    },
    original: {
      type: Domicilio,
    },
    disabled: {
      type: Boolean,
      default: false
    },
    map: {
      type: Boolean,
      default: false
    },
    renovacion: {
      type: Number,
      default: false
    }
  },
  mixins: [RULES],
  directives: {},
  data: () => ({
    showForm: false,
    catalogos: {
      entidades: [{
        id: 23,
        label: 'QUINTANA ROO'
      }],
      municipios: [{
        id: 4,
        label: 'OTHON P. BLANCO'
      }],
      localidades: [],
      asentamientos: [],
      calles: [],
      cp_localidades: [],
      entidadDefault: 23,
      municipioDefault: null,
      localidadDefault: null,
      prellenar: false,
    },
    defaultLatitud: 18.520326,
    defaultLongitud: -88.305718,
    formDom: new Domicilio(),
    formDefault: new Domicilio(),
    mounted: false,
    isInicializacion: false,
  }),
  created() {
  },
  mounted() {
    EventBus.$on('reset-direccion', () => {
        this.reset();
    });
    EventBus.$on('update-direccion', () => {
      this.update();
    });
    this.mounted = true;
    

    this.cargar();
    this.inicializacion();
  },
  computed: {
    form(): VForm {
      return this.$refs.formDireccion as VForm
    },
    localidadesList(): any {
      if(this.catalogos.cp_localidades.length > 0){
        //@ts-ignore
        return this.catalogos.localidades.filter((item: any) => this.catalogos.cp_localidades.includes(item.id))
      }else{
        return this.catalogos.localidades;
      }
    }
  },
  watch: {
    domicilio: {
        handler(d){
          this.asignar();
          if(this.map && this.mounted){
            //@ts-ignore
            this.inicializacion();
          }
        },
        deep: true
    },
    /*'formDom.entidad_id': {
        handler(){
          if (!this.catalogos.prellenar && this.formDom.entidad_id != null)
            this.cargarMunicipios(this.formDom.entidad_id);
        },
      immediate: true
    },*/
    'formDom.municipio_id': function () {
      if (!this.catalogos.prellenar && this.formDom.municipio_id != null)
        this.cargarLocalidades(this.formDom.entidad_id, this.formDom.municipio_id);
    },
    'formDom.localidad_id': function () {
      if (!this.catalogos.prellenar && this.formDom.localidad_id != null)
        this.cargarColonias(this.formDom.localidad_id);
    },
    'formDom.colonia_id': function () {
      /*this.formDom.calle_id = null;
      this.formDom.cruzamiento1_id = null;
      this.formDom.cruzamiento2_id = null;*/
      console.log("this.catalogos.prellenar",this.catalogos.prellenar);
      console.log("this.formDom.colonia_id",this.formDom.colonia_id);
      if (!this.catalogos.prellenar && this.formDom.colonia_id != null)
        this.cargarCalles(this.formDom.colonia_id);
    },
    
  },
  methods: {
    revisaIsOtraColonia(otro_domicilio: any){
      if (otro_domicilio) {
        if (otro_domicilio.is_otra_colonia) {
          return true;
        }
      }
      return false;
    },
    reset(){
      this.catalogos.localidades = [];
      this.formDom = Object.assign({}, this.formDefault);
      this.resetValidation();
    },
    validate() {
      console.log("internal validation",this.form.validate());
      return this.form.validate();
    },
    resetValidation() {
      return this.form.resetValidation();
    },
    update() {
      if (!this.formDom.entidad_id) {
        this.formDom.entidad_id = 23;
        this.formDom.municipio_id = 4;
      }
      this.$emit('update',this.formDom)
    },
    async asignar() {
      //this.formDom = Object.assign({}, this.domicilio);
      this.formDom.id = this.domicilio.id;
      this.formDom.codigo_postal = this.domicilio.codigo_postal;
      this.formDom.localidad_id = this.domicilio.localidad_id;
      this.formDom.colonia_id = this.domicilio.colonia_id;
      this.formDom.numero_exterior = this.domicilio.numero_exterior;
      this.formDom.latitud = this.domicilio.latitud;
      this.formDom.longitud = this.domicilio.longitud;
      if (this.domicilio.calle_id) {
        this.formDom.calle_id = this.domicilio.calle_id;
      }
      if (this.domicilio.cruzamiento1_id) {
        this.formDom.cruzamiento1_id = this.domicilio.cruzamiento1_id;
      }
      if (this.domicilio.cruzamiento2_id) {
        this.formDom.cruzamiento2_id = this.domicilio.cruzamiento2_id;
      }
      if (this.domicilio.otro_domicilio) {
        if (this.domicilio.otro_domicilio.is_otra_colonia) {
          this.formDom.otro_domicilio.is_otra_colonia = this.domicilio.otro_domicilio.is_otra_colonia;
          this.formDom.otro_domicilio.otra_colonia = this.domicilio.otro_domicilio.otra_colonia;
          this.formDom.otro_domicilio.otra_calle = this.domicilio.otro_domicilio.otra_calle;
          this.formDom.otro_domicilio.otra_cruzamiento1 = this.domicilio.otro_domicilio.otra_cruzamiento1;
          this.formDom.otro_domicilio.otra_cruzamiento2 = this.domicilio.otro_domicilio.otra_cruzamiento2;
        }
      }
    },
    async cargar() {
      let response_localidades = await DomicilioService.getLocalidades(23, 4);
      this.catalogos.localidades = response_localidades.data;
      
      this.formDom = Object.assign({}, this.domicilio);
      await this.cargarCP(this.formDom.codigo_postal);
      this.formDom.colonia_id = this.domicilio.colonia_id;
      this.catalogos.prellenar = false; //revisar carga inicial y cuando se resetean datos
    },
    prellenarCP() {
      /*if (this.formDom.codigo_postal != '') {
        if (this.formDom.codigo_postal.length == 5) {
          return new Promise((resolve, reject) => {
            this.cargarCP(this.formDom.codigo_postal).then(r => {
              if(r!=null){
                
                if(this.catalogos.cp_localidades.length == 1){
                  this.cargarColonias(this.catalogos.cp_localidades[0]).then(r => {
                    //this.formDom.localidad_id = this.catalogos.localidadDefault;
                  }).then(r => {
                    this.catalogos.prellenar = false;
                    this.formDom.colonia_id = this.domicilio.colonia_id;
                    resolve(1);
                  });
                }else{
                  this.catalogos.prellenar = false;
                    resolve(1);
                }
              }else{
                this.formDom.codigo_postal = ''
              }
            });
          });
        }
      }*/
    },
    cargarCP(cp: string) {
      return new Promise((resolve, reject) => {
        if (this.formDom.codigo_postal) {
          if (this.formDom.codigo_postal.toString().length == 5) {
            DomicilioService.getAsentamientos(cp).then(response => {
              let {data} = response;
              //@ts-ignore
              this.catalogos.cp_localidades = [...new Set(data.map((item: any) => item.id_localidad))];

              if (data.length > 0) {
                
                this.catalogos.prellenar = true;
                if(this.catalogos.cp_localidades.length == 1){
                  this.formDom.localidad_id = this.catalogos.cp_localidades[0]
                }
                this.catalogos.entidadDefault = data[0].cve_ent;
                this.catalogos.municipioDefault = data[0].cve_mun;
                this.catalogos.localidadDefault = data[0].cve_loc;
                this.formDom.colonia_id = null;
                resolve(true);
              } else {
                //this.formDom.codigo_postal = '';
                resolve(null);
              }
            }).catch(error => {
              reject(0);
            });
          }
        }
      })
    },
    cargarLocalidades(entidad_id: number | null, municipio_id: number | null) {
      return new Promise((resolve, reject) => {
        if (entidad_id != null && municipio_id != null) {
          DomicilioService.getLocalidades(entidad_id, municipio_id).then(response => {
            let {data} = response;
            this.catalogos.localidades = data;
            resolve(data);
          });
        }
      });
    },
    cargarColonias(localidadId: any) {
      return new Promise((resolve, reject) => {
        if (localidadId != null) {
          DomicilioService.getColonias(localidadId).then(response => {
            let {data} = response;
            this.catalogos.asentamientos = data;
            resolve(data);
          });
        }
      });  
    },
    cargarCalles(coloniaId: any) {
      return new Promise((resolve, reject) => {
        if (coloniaId != null) {
          DomicilioService.getCalles(coloniaId).then(response => {
            let {data} = response;
            this.catalogos.calles = data;
            resolve(data);
          });
        }
      });  
    },
    inicializacion() {
      let latitud =
        this.formDom.latitud !== 0 && this.formDom.latitud !== null
          ? this.formDom.latitud
          : this.defaultLatitud;
      let longitud =
        this.formDom.longitud !== 0 &&
          this.formDom.longitud !== null
          ? this.formDom.longitud
          : this.defaultLongitud;

      var mapOptions = {
        center: new google.maps.LatLng(latitud, longitud),
        zoom: 13,
        mapTypeId: google.maps.MapTypeId.ROADMAP,
        scrollwheel: false,
        disableDoubleClickZoom: true,
      };

      var mapa = new google.maps.Map(document.getElementById("map-canvas")!);
      mapa.setOptions(mapOptions);

      var marcador: any = null;
      var initialLocationuser = null;
      let defaultInit = false;

      /*if (navigator.geolocation && this.formDom.latitud === 0) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            initialLocationuser = new google.maps.LatLng(
              position.coords.latitude,
              position.coords.longitude
            );
            mapa.setCenter(initialLocationuser);
            marcador = new google.maps.Marker({
              position: initialLocationuser,
              map: mapa,
            });
          },
          () => {
            initialLocationuser = new google.maps.LatLng(latitud, longitud);
            mapa.setCenter(initialLocationuser);
            marcador = new google.maps.Marker({
              position: initialLocationuser,
              map: mapa,
            });
          }
        );
      } else {*/
        initialLocationuser = new google.maps.LatLng(latitud, longitud);
        mapa.setCenter(initialLocationuser);
        marcador = new google.maps.Marker({
          position: initialLocationuser,
          map: mapa,
        });
      //}

      var geocoder = new google.maps.Geocoder();
      var infowindow = new google.maps.InfoWindow();

      google.maps.event.addListener(mapa, "click", (evento: any) => {
        if (marcador != null) marcador.setMap(null);
        //Obtengo las coordenadas separadas
        var latitud = evento.latLng.lat();
        var longitud = evento.latLng.lng();
        this.formDom.latitud = latitud;
        this.formDom.longitud = longitud;

        var coordenadas = new google.maps.LatLng(latitud, longitud);
        marcador = new google.maps.Marker({
          position: coordenadas,
          map: mapa,
          animation: google.maps.Animation.DROP,
          title: "Establecimiento",
        });
        var latlng = { lat: parseFloat(latitud), lng: parseFloat(longitud) };
        geocoder.geocode({ location: latlng }, (results: any, status: any) => {
          if (status === "OK") {
            if (results[0]) {
              mapa.setZoom(14);

              let address = results[0].address_components;

              let cp = address.filter(
                (item: any) => item.types[0] == "postal_code"
              );

              infowindow.setContent(results[0].formatted_address);
              infowindow.open(mapa, marcador);
            } else {
              window.alert("No results found");
            }
          } else {
            window.alert("Geocoder failed due to: " + status);
          }
        });
      });

      this.isInicializacion = true;
    },
  }
})
