
    import Vue from 'vue'
    import RULES from "@/mixins/rules";
    import {VForm} from "@/types/formvalidate";
    import LoginService from "@/services/LoginService";

    export default Vue.extend({
        name: 'SignUpForm',
        mixins: [RULES],
        directives: {},
        components: {},
        data: () => ({
            vista: 0,
            showPassword: false,
            showPass1: false,
            showPass2: false,
            formSignup: {
                nombre: '',
                apellido1: '',
                apellido2: '',
                email: '',
                telefono: '',
                rfc: '',
                curp: '',
                password: '',
                confirmPassword: ''
            },
        }),
        created() {
        },
        mounted() {
        },
        computed: {
            form(): VForm {
                return this.$refs.signup as VForm
            },
        },
        watch: {
        },
        methods: {
            async login(){
                if(this.form.validate()){
                    let {data} = await LoginService.signup(this.formSignup);
                    if(data.success){
                        this.$toast.success(data.message);
                        await this.$router.push({name: 'LoginForm'});
                    }else
                      this.$toast.error(data.message);
                }
            },
        }
    })
