import { DefaultService } from "@/services/DefaultService";
import API from "@/plugins/axios";

class TipoAnuncioService extends DefaultService {
  
  constructor() {
    super('tipoanuncio');
  }
}

export default new TipoAnuncioService;