
import { Domicilio } from "./Domicilio";
import { Anuncio } from "./Anuncio";

interface _FileDocument {
  code: string,
  file: string,
  binary: string,
  data: any  
}

class FileDocument implements _FileDocument {
  code: string
  file: string
  binary: string
  data: any  

  constructor() {
    this.code = '';
    this.file = '';
    this.binary = '';
    this.data = null;
  }
}

interface _Establecimiento {
  id: number | null;
  contribuyente_id: number | null;
  giro_actividad_id: any;
  giro_subactividad_id: any;
  tipo_propiedad_id: number | null;
  clave_catastral: any;
  categoria_id: number | null;
  local: string | null,
  telefono_oficina: string;
  superficie_m2: string;
  cajones_estacionamiento: string;
  numero_empleados: string;
  inversion_estimada: string;
  numero_habitaciones: string;
  tipo_local_id: any;
  tipo_local_detalle_id: any;
  nombre_comercial: string;
  domicilio: Domicilio | null
  documentos: {
    arrendamiento_contrato: FileDocument,
    arrendamiento_identificacion: FileDocument,
    arrendador_identificacion: FileDocument,
    arrendamiento_testigos: FileDocument,
    licencia: FileDocument,
    licencia_comodato: FileDocument,
    impuesto_hospedaje: FileDocument,
    dictamen_ifeqroo: FileDocument,
    licencia_construccion: FileDocument,
    constancia_uso_suelo: FileDocument,
    titulo_escritura: FileDocument,
    pago_licencia_bebidas: FileDocument,
    dictamen_anuencia: FileDocument,
    aviso_apertura_sucursal: FileDocument,
    constancia_donativo: FileDocument,
    last_license: FileDocument,
    documento_anexo: FileDocument,
    recibo_pago_donativo: FileDocument,
    contrato_consecion: FileDocument,
    autorizacion_arrendamiento: FileDocument
  },
  fotos: {
    frente: FileDocument,
    interior: FileDocument,
    wc: FileDocument,
    lateralIquierdo: FileDocument,
    lateralDerecho: FileDocument
  },
  tipo_proceso_id: any,
  patente_propia: any,
  informacion_complementaria: any,
  anuncios: Anuncio[] | null,
  tiene_uso_suelo: boolean
  tiene_anuncios: boolean
  fecha_alta_hacienda: string
  actividades_secundarias: any[];
  actividad_libre: any;
  has_licencia_bebidas: boolean;
  tiene_anuencia: boolean;
  zofemat: any;
  zofemat_expediente: any;
  horario_extendido: any;
  folio_licencia_bebida_alcoholica: any;
  donativo: any
  folio_donativo: any
  numero_oficio_constancia_catastro: any,
  folio_recibo_pago_donativo: any,
  estado_edo_dntv_id: any
  estado_pago_dtv_id: any
  user_edo_dntv_id: any
  renovacion: any
  mes_pago_residuos: any
  mes_pago_piso_f: any
  pasillo: any,
  arrendatario_mercado: any
}

export class Establecimiento implements _Establecimiento {
  [key: string]: number | string | null | File[] | Domicilio |  Anuncio[] | boolean | any[] ;
  id = null;
  contribuyente_id = null;
  giro_actividad_id = null;
  giro_subactividad_id = null;
  tipo_propiedad_id = null;
  clave_catastral: any = "";
  categoria_id = null;
  local = null;
  telefono_oficina = "";
  superficie_m2 = "";
  cajones_estacionamiento = "";
  numero_empleados = "";
  inversion_estimada = "";
  numero_habitaciones = "";
  tipo_local_id = null;
  tipo_local_detalle_id = null;
  nombre_comercial = "";
  domicilio = new Domicilio();
  documentos: any = {
    arrendamiento_contrato: new FileDocument(),
    arrendamiento_identificacion: new FileDocument(),
    arrendador_identificacion: new FileDocument(),
    arrendamiento_testigos: new FileDocument(),
    licencia: new FileDocument(),
    licencia_comodato: new FileDocument(),
    impuesto_hospedaje: new FileDocument(),
    dictamen_ifeqroo: new FileDocument(),
    licencia_construccion: new FileDocument(),
    constancia_uso_suelo: new FileDocument(),
    titulo_escritura: new FileDocument(),
    pago_licencia_bebidas: new FileDocument(),
    dictamen_anuencia: new FileDocument(),
    aviso_apertura_sucursal: new FileDocument(),
    constancia_donativo: new FileDocument(),
    last_license: new FileDocument(),
    documento_anexo: new FileDocument(),
    recibo_pago_donativo: new FileDocument(),
    contrato_consecion: new FileDocument(),
    autorizacion_arrendamiento: new FileDocument()
  };
  fotos: any = {
    frente: new FileDocument(),
    interior: new FileDocument(),
    wc: new FileDocument(),
    lateralIzquierdo: new FileDocument(),
    lateralDerecho: new FileDocument()
  };
  tipo_proceso_id: any = "";
  patente_propia: any = true;
  informacion_complementaria: any = "";
  anuncios = [new Anuncio ()];
  tiene_uso_suelo = false;
  tiene_anuncios = false;
  fecha_alta_hacienda = "";
  actividades_secundarias = [];
  actividad_libre = "";
  has_licencia_bebidas = true;
  tiene_anuencia = false;
  zofemat = false;
  zofemat_expediente = "";
  horario_extendido = false;
  folio_licencia_bebida_alcoholica = "";
  donativo = false;
  zona_federal = false;
  no_cuento_clave = false;
  folio_donativo = "";
  numero_oficio_constancia_catastro = "";
  folio_recibo_pago_donativo = "";
  estado_edo_dntv_id = null;
  estado_pago_dtv_id = null;
  user_edo_dntv_id = null;
  renovacion: any = 0;
  mes_pago_residuos: any = "01";
  mes_pago_piso_f: any = "01";
  pasillo: any = false;
  arrendatario_mercado: any = false
}

interface _Documentation {
  id: number;
  archivo_id: number; //Ya existe el modelo File.
  locacion_id: number; //Ya existe el modelo Establishment (Establecimiento).
  url: string;
  valido: boolean;
}

export class Documentation implements _Documentation {
  id = 0;
  archivo_id = 0;
  locacion_id = 0;
  url = "";
  valido = false;
}

interface _Payment {}

export class Payment implements _Payment {}
