
import Vue from 'vue'
import RULES from "@/mixins/rules"
import { VForm } from "@/types/formvalidate";
import EventBus from "@/plugins/event-bus";
import EstablecimientoService from '@/services/EstablecimientoService';
import numeric from "@/directives/numeric";

export interface Documento{
    id: number,
    title: string,
    url: string,
    mime: string,
    binary: any
}

export default Vue.extend({
    name: "CustomFileInput",
    components: {
    },
    mixins: [RULES],
    props: {
        code: String,
        file: Object || null,
        representante: {
          type: Object,
          default: () => ({}),
          required: false,
        },
        locacion: {
          type: String,
          default: "0",
          required: false
        },
        contribuyente: {
          type: String,
          default: "0",
          required: false
        },
        update: {
            type: Boolean,
            default: false
        },
        disabled: {
            type: Boolean,
            default: false
        },
        accept: {
            type: String,
            default: '.pdf'
        },
        icon: {
            type: String,
            default: 'file.jpg'
        },
        loading: {
            type: Boolean,
            default: false,
        },
        customProgress: {
            type: Boolean,
            default: false
        },
        progress: {
            type: Number,
            default: 0,
        },
        multipart: {
            type: Boolean,
            default: true
        },
        requiredArchivo: {
            type: Boolean,
            default: true
        },
        renovacion: {
            type: Number,
            default: 0,
        }
    },
    data() {
        return {
            documento: Document || null,
            binary: '',
            dialog: false,
            show: false,
            fileDeleted: false,
            tipoArchivos: [
                {
                    code: 'identificacion',
                    description: 'Identificación'
                },
                {
                    code: 'constancia',
                    description: 'Constancia SAT'
                },
                {
                    code: 'acta_constitutiva',
                    description: 'Acta Constitutiva'
                },
                {
                    code: 'carta_poder',
                    description: 'Carta Poder'
                },
                {
                    code: 'poder_notarial',
                    description: 'Poder Notarial'
                },
                {
                    code: 'testigo1',
                    description: 'Identificación Testigo 1'
                },
                {
                    code: 'testigo2',
                    description: 'Identificación Testigo 2'
                },
                {
                    code: 'arrendamiento_contrato',
                    description: 'Contrato de Arrendamiento o comodato'
                },
                {
                    code: 'arrendamiento_identificacion',
                    description: 'Identificación de Arrendatario'
                },
                {
                    code: 'arrendador_identificacion',
                    description: 'Identificación de Arrendador'
                },
                {
                    code: 'arrendamiento_testigos',
                    description: 'Testigos de Arrendamiento'
                },
                {
                    code: 'licencia',
                    description: 'Licencia de Bebidas Alcohólicas'
                },
                {
                    code: 'licencia_comodato',
                    description: 'Oficio de Comodato (Bebidas Alcohólicas)'
                },
                {
                    code: 'impuesto_hospedaje',
                    description: 'Saneamiento ambiental'
                },
                {
                    code: 'dictamen_ifeqroo',
                    description: 'Dictamen IFEQROO'
                },
                {
                    code: 'licencia_construccion',
                    description: 'Licencia de Construcción'
                },
                {
                    code: 'titulo_escritura',
                    description: 'Título o escritura'
                },
                {
                    code: 'constancia_uso_suelo',
                    description: 'Constancia de uso de suelo'
                },
                {
                    code: 'frente',
                    description: 'Frente/Fachada'
                },
                {
                    code: 'interior',
                    description: 'Interior'
                },
                {
                    code: 'wc',
                    description: 'Baño'
                },
                {
                    code: 'lateralIzquierdo',
                    description: 'Lateral Lado Izquierdo'
                },
                {
                    code: 'lateralDerecho',
                    description: 'Lateral Lado Derecho'
                },
                {
                    code: 'pago_licencia_bebidas',
                    description: 'Pago de licencia de bebidas'
                },
                {
                    code: 'dictamen_anuencia',
                    description: 'Dictamen de anuencia'
                },
                {
                    code: 'fotografia_anuncio',
                    description: 'Fotografía'
                },
                {
                    code: 'memoria_calculo',
                    description: 'Memoria de cálculo estructura'
                },
                {
                    code: 'responsiva_terceros',
                    description: 'Carta responsiva de daño a terceros'
                },
                {
                    code: 'aviso_apertura_sucursal',
                    description: 'Aviso de apertura de sucursal'
                },
                {
                    code: 'solicitud_fiscalizacion_firmada',
                    description: 'Solicitud de horario extraordinario firmada'
                },
                {
                    code: 'constancia_donativo',
                    description: 'Constancia de catastro'
                },
                {
                    code: 'recibo_pago_extemporaneo',
                    description: 'Recibo de pago extemporáneo'
                },
                {
                    code: 'last_license',
                    description: 'Licencia de año anterior',
                },
                {
                    code: 'scd_identificacion',
                    description: 'Identificación oficial vigente',
                },
                {
                    code: 'scd_constancia',
                    description: 'Constancia de situación fiscal',
                },
                {
                    code: 'sbe_constancia',
                    description: 'Constancia de situación fiscal',
                },
                {
                    code: 'sbe_aviso_baja',
                    description: 'Aviso de cierra de establecimiento ante el SAT',
                },
                {
                    code: 'sbe_identificacion',
                    description: 'Identificación oficial vigente',
                },
                {
                  code: 'documento',
                  description: 'Poder notarial',
                },
                {
                    code: 'documento_anexo',
                    description: 'Documento Anexo',
                },
                {
                    code: 'recibo_pago_donativo',
                    description: 'Recibo de pago donativo',
                },
                {
                    code: 'scdd_impuesto',
                    description: 'Impuesto prdial',
                },
                {
                    code: 'scdd_constancia_us',
                    description: 'Constancia de uso de suelo',
                },
                {
                    code: 'scdd_constancia_rs',
                    description: 'Derecho de recolección y Transporte de Residuos Solidos',
                },
                {
                    code: 'scdd_licencia_alcohol',
                    description: 'Licencia de bebidas alcoholicas',
                },
                {
                    code: 'scdd_saneamiento',
                    description: 'Copia de declaración de saneamiento ambiental del mes anterior',
                },
                {
                    code: 'scdd_identificacion',
                    description: 'Identificación oficial vigente',
                },
                {
                    code: 'contrato_consecion',
                    description: 'Contrato de concesión',
                },
                {
                    code: 'autorizacion_arrendamiento',
                    description: 'Autorización del arrendamiento',
                },

            ],
            filesNotRequired: [
                "testigo1",
                "testigo2",
                "licencia",
                "constancia",
                "identificacion",
                "acta_constitutiva",
                "poder_notarial",
                "carta_poder",
                "constancia_donativo",
                "constancia_uso_suelo",
                "titulo_escritura",
                "arrendamiento_contrato",
                "arrendamiento_identificacion",
                "arrendador_identificacion",
                "arrendamiento_testigos",
                "pago_licencia_bebidas",
                "licencia_comodato",
                "impuesto_hospedaje",
                "dictamen_ifeqroo",
                "licencia_construccion",
                "aviso_apertura_sucursal",
                "frente",
                "interior",
                "wc",
                "lateralIzquierdo",
                "lateralDerecho",
                'documento_anexo',
                'contrato_consecion',
                'autorizacion_arrendamiento'
            ],
            typesImageAllowed: [
                "image/jpeg",
                "image/png",
                "image/jpg"
            ],
          labelType:[
            "frente",
            "interior",
            "wc",
            "lateralIzquierdo",
            "lateralDerecho",
          ]
        }
    },
    computed: {
        form(): VForm {
            return this.$refs.form as VForm;
        },
        showInput(): boolean {
            if(!this.update)
                return true;
            else if(this.file == null || this.show)
                return true
            else 
                return false
        },
        label(): string {
            if(this.code != ""){
                //@ts-ignore
                const tipoArchivo = this.tipoArchivos.find((item: any) => {
                    //@ts-ignore
                    return item.code == this.code
                });
                return tipoArchivo?.description ?? '';
            }else
            return '';
            
        },
        requiredFile(): boolean {
            if (this.filesNotRequired.includes(this.code)) {
                return false
            } else {
                return true
            }
        }
    },
    watch: {
        renovacion:{
            immediate: true,
            deep: true,
            handler(d){
                if (this.renovacion == 1) {
                    this.filesNotRequired.push('frente');
                }
            }
        },
    },
    mounted() {
        if (this.renovacion == 1) {
            this.filesNotRequired.push('frente');
        }
    },
    methods: {
        getDataFile(file: any): any {
            return new Promise((resolve, reject) => {
                const fileReader = new FileReader();
                fileReader.addEventListener("load", () => {
                var file = fileReader.result;
                resolve(file);
                });
                fileReader.readAsDataURL(file);
            });
        },
        async setDocument(e: any) {
          if(e != null){

            if(this.labelType.includes(this.code)) {
              if (!this.typesImageAllowed.includes(e.type)) {
                this.$emit('showErrorSnackbar', 'El archivo debe ser una imagen (png, jpg, jpeg).');
                this.binary = '';
                this.$emit('input', this.binary);
                this.clearFileInput();
              }
            }
            if(!this.multipart) {
              this.binary = await this.getDataFile(e);
            } else {
              this.binary = e;
            }

          } else {
            this.binary = '';
          }

          this.$emit('input', this.binary);

        },
        reset() {
          // Reiniciar el valor del input a una cadena vacía
          //@ts-ignore
          this.file = '';

          // Emitir un evento para notificar que el input ha sido reseteado
        },
        clearFileInput() {
          if (this.$refs.form && (this.$refs.form as any).reset) {
            (this.$refs.form as any).reset();
          }
        },
        async showFile(){
            //@ts-ignore
            if (this.file != null){

                //@ts-ignore
                const response = await EstablecimientoService.descargarFormato(`documentacion/${this.file.id}`,{},'GET','Temp');

                let file = new Blob([response.data],{type: response.headers['content-type']});
                const url = window.URL.createObjectURL(file);                

                //@ts-ignore
                this.documento = {
                    //@ts-ignore
                    title: this.label,
                    url: url,
                    mime: response.headers['content-type'] 
                }
                this.dialog = true;
            } 
        },
        changeFile() {
            this.show = true;
        },
        deleteFile() {
          try {
            EstablecimientoService.deleteFiles(this.file.id, this.locacion,this.contribuyente,this.representante.id).then((response:any) => {
              if (response.data.success) {
                this.fileDeleted = true;
                this.$emit('file-upload-success', {message : response.data.message , typeFile: this.file.tipo_archivo});
              }else{
                this.$emit('file-upload-error', {message : response.data.message});
              }
            });
          } catch (error) {
            console.error("Error al eliminar foto:", error);
          }

        }
    }

});

