import { DefaultService } from "@/services/DefaultService";
import API from "@/plugins/axios";

class CambioComicilioService extends DefaultService {
  
  constructor() {
    super('cambiodomicilio');
  }

  getDataDomicilio(establecimiento: any): Promise<any>{
    return API.get(`cambiodomicilio/getDataDomicilio/${establecimiento}`);
  }

  getSolicitudesCambioDomicilio(establecimiento: any): Promise<any>{
    return API.get(`cambiodomicilio/getSolicitudesCambioDomicilio/${establecimiento}`);
  }

  setSolicitudCambioDomicilio(establecimiento: any, data: any): Promise<any>{
    return API.post(`cambiodomicilio/setSolicitudCambioDomicilio/${establecimiento}`, data);
  }

  getSolicitudCambioDomicilio(establecimiento: any, solicitud: any): Promise<any>{
    return API.get(`cambiodomicilio/getSolicitudCambioDomicilio/${establecimiento}/${solicitud}`);
  }

  changeStatusSolicitudCambioDomicilio(establecimiento: any, solicitud: any, data: any): Promise<any>{
    return API.post(`cambiodomicilio/changeStatusSolicitudCambioDomicilio/${establecimiento}/${solicitud}`, data);
  }

  updateSolicitudCambioDomicilio(establecimiento: any, solicitud: any, data: any): Promise<any>{
    return API.post(`cambiodomicilio/updateSolicitudCambioDomicilio/${establecimiento}/${solicitud}`, data);
  }
}

export default new CambioComicilioService;