
import Vue from 'vue'
import LoginService from "@/services/LoginService";
import {mapGetters} from "vuex";
import { Usuario } from '@/models/Usuario';
import Notification from '@/components/Common/Notification.vue';
import rules from '@/mixins/rules';

export default Vue.extend({
    name: 'Sidebar',
    components: {
      Notification
    },
    mixins: [rules],
    data: () => ({
        drawer: true,
        mini: false,
        dialogPassword: false,
        formNewPassword:{
          password: '',
          confirmPassword: '',
        },
        showNewPassword: false,
        timerCount: 300,
        interval: null as any
    }),
    mounted() {
      document.addEventListener("keypress", this.resetTimer, false);
      document.addEventListener("mousemove", this.resetTimer, false);
      document.addEventListener("mousedown", this.resetTimer, false);
      document.addEventListener("touchmove", this.resetTimer, false);
    },
    computed: {
      ...mapGetters({
        usuario: 'usuario', modules: 'modules', contribuyente: 'contribuyente', user_details: 'user_detail', id: 'id'
      })
    },
    watch: {
        mini: function(value){
          this.setMini(value);
        },
    },
    methods: {
      resetTimer() { 
          this.timerCount = 600;
          clearInterval(this.interval);
          if (this.contribuyente) {
            this.interval = setInterval (() => {
                this.timerCount--;
                //console.log(this.timerCount);
                if (this.timerCount <= 0) {
                  this.logout();
                }
            }, 1000);
          }
          
      },
      setMini(value: boolean){
        this.$store.dispatch('setSidebarMini', value);
      },
      logout(){
          clearInterval(this.interval);
        LoginService.logout().then(response => {
          if(response.data.success){
            this.$store.dispatch('logout');
            this.$router.push({name: 'FullLogin'});
          }
        });
      },
      openChangePassword() {
        this.dialogPassword = true;
      },
      closeChangePassword() {
        this.dialogPassword = false;
        this.formNewPassword = {
          password: '',
          confirmPassword: '',
        }
      },
      changePassword() {
        LoginService.newPassword(this.id, this.formNewPassword, 'CONTRIBUYENTE').then(response => {
          if (response.data.success) {
            this.$toast.success(response.data.message);
            this.closeChangePassword();
          } else {
            this.$toast.error(response.data.message);
          }
        })
        .catch(error => {
          this.$toast.error(error.response.data.message);
        })
      } 
    }
})
