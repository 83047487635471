import { Domicilio } from './Domicilio';

interface _FileDocument {
    code: string,
    file: string,
    binary: string,
    data: any  
}

class FileDocument implements _FileDocument {
    code: string
    file: string
    binary: string
    data: any  

    constructor() {
        this.code = '';
        this.file = '';
        this.binary = '';
        this.data = null;
    }
}

interface _CambioDomicilio {
    id: number | string
    clave_catastral: string | number
    donativo: boolean
    has_alcohol: boolean
    numero_oficio_constancia_catastro: string
    folio_recibo_pago_donativo: string
    renovacion: number
    has_licencia_bebidas: boolean
    patente_propia: boolean
    folio_licencia_bebida_alcoholica: string
    domicilio: Domicilio
    documentos: {
        scdd_constancia_rs: FileDocument
        scdd_saneamiento: FileDocument
        constancia_donativo: FileDocument
        recibo_pago_donativo: FileDocument
        licencia: FileDocument
        pago_licencia_bebidas: FileDocument
        licencia_comodato: FileDocument
    }
}

export class CambioDomicilio implements _CambioDomicilio {
    id = ''
    clave_catastral = ''
    donativo = false
    numero_oficio_constancia_catastro = ''
    folio_recibo_pago_donativo = ''
    renovacion = 0
    has_alcohol = false
    has_licencia_bebidas = true
    patente_propia = true
    folio_licencia_bebida_alcoholica = ''
    domicilio = new Domicilio()
    documentos: any = {
        scdd_constancia_rs: new FileDocument(),
        scdd_saneamiento: new FileDocument(),
        constancia_donativo: new FileDocument(),
        recibo_pago_donativo: new FileDocument(),
        licencia: new FileDocument(),
        pago_licencia_bebidas: new FileDocument(),
        licencia_comodato: new FileDocument(),
    }
}