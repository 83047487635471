<template>
    <v-card
        class="mx-auto"
    >
        <v-toolbar
        style="background-color: white; color: black;"
        dark
        >
        <v-toolbar-title>TRÁMITES DISPONIBLES</v-toolbar-title>

        <v-spacer></v-spacer>

        <v-btn icon>
            <v-icon>mdi-magnify</v-icon>
        </v-btn>
        </v-toolbar>

        <v-container fluid>
        <v-row dense>
            <v-col
            v-for="card in cards"
            :key="card.title"
            :cols="card.flex"
            >
            <v-card>
                <v-img
                :src="require(`@/assets/${card.src}`)"
                class="white--text align-end"
                gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
                height="300px"
                >
                <v-card-title>
                    <v-btn color="dark" dark>{{ card.title }}</v-btn>
                </v-card-title>
                </v-img>

                <v-card-actions>
                <v-spacer></v-spacer>

                <v-tooltip left>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn
                        icon
                        v-bind="attrs"
                        v-on="on"
                        >
                            <v-icon>mdi-view-list</v-icon>
                        </v-btn>
                    </template>
                    <span>Requerimientos</span>
                </v-tooltip>
                </v-card-actions>
            </v-card>
            </v-col>
        </v-row>
        </v-container>
    </v-card>
</template>

<script>
export default {
    name: "Ventanilla",
    data: () => ({
      cards: [
        { title: 'LICENCIA DE FUNCIONAMIENTO', src: '1.png', flex: 4 },
        { title: 'ANUNCIOS EXTERNOS', src: '2.png', flex: 4 },
        { title: 'HORARIO EXTRAORDINARIO DE ALCOHOL', src: '3.png', flex: 4 },
        { title: 'PERMISO PARA ANTENAS Y TORRES', src: '4.png', flex: 4 },
        { title: 'ANUNCIOS EN ESTABLECIMIENTOS', src: '5.png', flex: 4 },
        { title: 'PERMISO PARA MÚSICA VIVA', src: '6.png', flex: 4 },
      ],
    }),
}
</script>