
import Vue from 'vue';
import EventBus from '../../plugins/event-bus';
import CustomFile from '../Common/CustomFile.vue';
import rules from '@/mixins/rules';
import BajaEstablecimientoService from '@/services/solicitudes/BajaEstablecimientoService';

export default Vue.extend({
    name: 'BajaEstablecimiento',
    mixins: [rules],
    components: {
        CustomFile,
    },
    props: {
        open: {
            type: Boolean,
            default: false,
        },
        licencia: {
            type: Object,
            default: null as any,
        },
        locacion: {
            type: Object,
            default: null as any,
        }
    },
    watch: {
        open() {
            if (this.open == true) {
                this.getData();
            }
        },  
    },
    data() {
        return {
            disabled: false,
            menu: false,
            form: {
                id: '',
                fecha_sat: new Date().toISOString().substr(0, 10),
                has_alcohol: null as any,
                documentos: {
                    'sbe_constancia': {
                        code: 'sbe_constancia',
                        file: '',
                        binary: '',
                        data: null,
                    },
                    'sbe_aviso_baja': {
                        code: 'sbe_aviso_baja',
                        file: '',
                        binary: '',
                        data: null,
                    },
                    'sbe_identificacion': {
                        code: 'sbe_identificacion',
                        file: '',
                        binary: '',
                        data: null,
                    },
                }
            },
            solicitud: {} as any,
        }
    },
    methods: {
        close() {
            this.$emit('closeBajaEstablecimiento');
            this.disabled = false;
            this.form = {
                id: '',
                fecha_sat: new Date().toISOString().substr(0, 10),
                has_alcohol: null as any,
                documentos: {
                    'sbe_constancia': {
                        code: 'sbe_constancia',
                        file: '',
                        binary: '',
                        data: null,
                    },
                    'sbe_aviso_baja': {
                        code: 'sbe_aviso_baja',
                        file: '',
                        binary: '',
                        data: null,
                    },
                    'sbe_identificacion': {
                        code: 'sbe_identificacion',
                        file: '',
                        binary: '',
                        data: null,
                    },
                }
            }
        },
        async save() {
            // @ts-ignore
            let validate = this.$refs.form.validate();
            if (validate) {
                this.disabled = true;
    
                const formData = new FormData();
                formData.append('id', this.form.id);
                formData.append('fecha_sat', this.form.fecha_sat);
                formData.append('has_alcohol', this.hasAlcohol ? 1 : 0 as any);
    
                Object.entries(this.form.documentos).forEach(([key, documento]) => {
                    const baseKey = `files[${key}]`;
                    formData.append(`${baseKey}[file]`, documento.file);
                    formData.append(`${baseKey}[code]`, documento.code);
                });
    
                BajaEstablecimientoService.bajaEstablecimiento(this.licencia.id, formData)
                .then((response) => {
                    if (response.data.success) {
                        this.$toast.success(response.data.message);
                        this.$emit('reloadData');
                        this.getData();
                    } else {
                        this.$toast.error(response.data.message);
                    }
                    this.disabled = false;
                })
                .catch((error) => {
                    this.$toast.error(error.response.data.message);
                    this.disabled = false;
                });
            } else {
                this.$toast.warning('Por favor, completa los campos obligatorios');
            }
        },
        getData() {
            BajaEstablecimientoService.getBajaEstablecimiento(this.licencia.id)
            .then((response) => {
                let solicitud = response.data;
                this.solicitud = solicitud;
                if (solicitud) {
                    this.form.id = solicitud.id;
                    this.form.fecha_sat = solicitud.fecha_sat;
                    const constancia = solicitud.constancia;
                    this.form.documentos['sbe_constancia'].code = 'sbe_constancia';
                    this.form.documentos['sbe_constancia'].data = constancia;
                    const aviso = solicitud.aviso;
                    this.form.documentos['sbe_aviso_baja'].code = 'sbe_aviso_baja';
                    this.form.documentos['sbe_aviso_baja'].data = aviso;
                    const identificacion = solicitud.identeficacion;
                    this.form.documentos['sbe_identificacion'].code = 'sbe_identificacion';
                    this.form.documentos['sbe_identificacion'].data = identificacion;
                    if (solicitud.estatus_id == 10) {
                        this.disabled = true;
                    }
                }
            })
        },
        updateBajaEstablecimiento() {
            // @ts-ignore
            let validate = this.$refs.form.validate();
            if (validate) {
                this.disabled = true;
    
                const formData = new FormData();
                formData.append('fecha_sat', this.form.fecha_sat);
                formData.append('has_alcohol', this.hasAlcohol ? 1 : 0 as any);
    
                Object.entries(this.form.documentos).forEach(([key, documento]) => {
                    const baseKey = `files[${key}]`;
                    formData.append(`${baseKey}[file]`, documento.file);
                    formData.append(`${baseKey}[code]`, documento.code);
                });
    
                BajaEstablecimientoService.updateBajaEstablecimiento(this.licencia.id, this.form.id, formData)
                .then((response) => {
                    if (response.data.success) {
                        this.$toast.success(response.data.message);
                        this.$emit('reloadData');
                        this.getData();
                    } else {
                        this.$toast.error(response.data.message);
                    }
                    this.disabled = false;
                })
                .catch((error) => {
                    this.$toast.error(error.response.data.message);
                    this.disabled = false;
                });
            } else {
                this.$toast.warning('Por favor, completa los campos obligatorios');
            }
        },
        chageEstatusBajaEstablecimiento(estatus_id: any) {
            let data = {
                estatus_id: estatus_id,
            }
            BajaEstablecimientoService.chageEstatusBajaEstablecimiento(this.licencia.id, this.form.id, data)
            .then((response) => {
                if (response.data.success) {
                    this.$toast.success(response.data.message);
                    EventBus.$emit('create-nombre-comercial');
                    this.close();
                } else {
                    this.$toast.error(response.data.message);
                }
            })
            .catch((error) => {
                this.$toast.error(error.response.data.message);
            });
        },
        notificarPagoAreaBajaEstablecimiento(area: any) {
            let data = {
                area: area,
            }
            BajaEstablecimientoService.notificarPagoAreaBajaEstablecimiento(this.licencia.id, this.form.id, data)
            .then((response) => {
                if (response.data.success) {
                    this.$toast.success(response.data.message);
                    this.getData();
                } else {
                    this.$toast.error(response.data.message);
                }
            })
            .catch((error) => {
                this.$toast.error(error.response.data.message);
            })
        },
        getColor(estatus: any) {
            console.log(estatus);

            switch (estatus) {
                case 1:
                    return 'orange';
                case 4:
                    return 'green';
            }
        },
        getClass(estatus: any) {
            switch (estatus) {
                case 4:
                    return 'mb-2 white--text';
            }
        },
    },
    computed: {
        adeudoRS() {
            if (this.solicitud.estatus_id == 10 && this.solicitud.adeudo_rs == 1 && !this.solicitud.pago_rs)
                return true;
            return false;
        },
        adeudoLA() {
            if (this.solicitud.estatus_id == 10 && this.solicitud.adeudo_la == 1 && !this.solicitud.pago_la)
                return true;
            return false;
        },
        adeudoPC() {
            if (this.solicitud.estatus_id == 10 && this.solicitud.adeudo_pc == 1 && !this.solicitud.pago_pc)
                return true;
            return false;
        },
        adeudoUS() {
            if (this.solicitud.estatus_id == 10 && this.solicitud.adeudo_us == 1 && !this.solicitud.pago_us)
                return true;
            return false;
        },
        hasAlcohol() {
            if (this.locacion?.has_alcohol_giro || this.locacion?.has_alcohol_actividad.length > 0) {
                return true;
            }
            return false;
        }
    }
})
