interface _Horario {
    id: any
    fecha: any
    hora_inicio: any
    hora_fin: any
    horas_solicitadas: any | number
    horas_aprobadas: any
    aprobado: any
}

export class Horario implements _Horario{
    id = 0
    fecha = null
    hora_inicio = null
    hora_fin = null
    horas_solicitadas = 0
    horas_aprobadas = 0
    aprobado = 0
}
