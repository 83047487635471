import Json from '@/types/json'
import { Domicilio } from './Domicilio'
import { Representante } from './Representante';
import { DireccionExterna } from './DireccionExterna';

interface _Contribuyente {
    id: number | null
    user_id: number | null
    isSameUser: boolean
    nombre: string
    apellido1: string
    apellido2: string
    rfc: string
    curp: string
    telefono_fijo: string
    telefono_movil: string
    telefono_oficina: string
    email: string
    tipo_persona: number
    razon_social: string
    domicilio: Domicilio | null
    representantes: Representante[] | null
    tramitadores: Representante[] | null
    constancia: any
    identificacion: any
    acta_constitutiva: any
    constancia_binary: any
    identificacion_binary: any
    acta_constitutiva_binary: any
    delete_representantes: any
    delete_tramitadores: any
    estatus: number
    es_externo: boolean
    domicilioExterno: DireccionExterna | null
    tipo_proceso_id: any
    motivo_rechazo: any
    mercado: false
}

class Contribuyente implements _Contribuyente{
    id = null
    user_id = 0
    isSameUser = false
    nombre = ''
    apellido1 = ''
    apellido2 = ''
    rfc = ""
    curp = ""
    telefono_fijo = ""
    telefono_movil = ""
    telefono_oficina = ""
    email = ""
    tipo_persona = 1
    razon_social = ""
    domicilio = new Domicilio ()
    representantes = [new Representante ()]
    tramitadores = [new Representante ()]
    constancia = null;
    identificacion = null;
    acta_constitutiva = null;
    constancia_binary = '';
    identificacion_binary = '';
    acta_constitutiva_binary = '';
    delete_representantes = [] as any;
    delete_tramitadores = [] as any;
    estatus = 1;
    es_externo = false;
    domicilioExterno = new DireccionExterna ();
    tipo_proceso_id: any = "1";
    motivo_rechazo: any = "";
    mercado: any = false;

    setData(data: Json){

    }
}

export {_Contribuyente,Contribuyente}