
import Vue from "vue";
import CambioDomicilioService from '@/services/CambioDomicilioService';
import { Establecimiento } from '@/models/Establecimiento';
import { Domicilio } from '@/models/Domicilio';
import Direccion from "@/components/Beneficiarios/Direccion.vue";
import EventBus from "@/plugins/event-bus";
import CustomFile from "@/components/Common/CustomFile.vue";
import rules from '@/mixins/rules';
import EstablecimientoService from '@/services/EstablecimientoService';
import CatastroService from '@/services/CatastroService';
import AlertasPredial from '@/components/Solicitudes/AlertasPredial.vue';
import { CambioDomicilio } from "@/models/CambioDomicilio";

export default Vue.extend({
    name: 'CambioDomicilio',
    mixins: [rules],
    components: {
        Direccion,
        CustomFile,
        AlertasPredial,
    },
    props: {
        establecimiento: {
            type: Object,
            default: {} as any,
        },
        dialogPrincipal: {
            type: Boolean,
        },
    },
    data() {
        return {
            dialog: false,
            dialogCatastro: false,
            disabledPredial: false,
            dialogClaveCatastral: false,
            dialogView: false,
            dialogPredial: false,
            dialogRechazar: false,
            documento: {} as any,
            formData: {
                ...new Establecimiento(),
            },
            formDataNueva: new CambioDomicilio(),
            disabled: false,
            solicitudes: [],
            solicitud: {} as any,
            idSolicitud: null,
            motivoRechazo: '',
        }
    },
    watch: {
        dialogPrincipal: {
            handler(newVal) {
                if (this.dialogPrincipal) {
                    this.getSolicitudesCambioDomicilio(this.establecimiento);
                }
            },
            deep: true,
        },
        'formDataNueva.donativo': {
            handler(value) {
                if (value) {
                    this.formDataNueva.clave_catastral = 'DONATIVO';
                    if (!this.solicitud) {
                        this.disabled = false;
                    }
                    this.dialogCatastro = false;
                } else {
                    this.formDataNueva.clave_catastral = '';
                    /* this.disabled = true; */
                }
            },
            deep: true,
        },
        dialog() {
            if (this.dialog) {
                if (!this.formDataNueva.id) {
                    this.formDataNueva.has_alcohol = this.hasAlcohol;
                }
                this.getDataDomicilio(this.establecimiento);
            }
        },
    },
    methods: {
        agregarSolicitud() {
            /* this.getDataDomicilio(this.establecimiento); */
            /* this.dialog = true; */
            this.dialogCatastro = true;
        },
        closeDialogCatastro() {
            this.dialogCatastro = false;
            this.disabled = false;
            this.formDataNueva = new CambioDomicilio();
            this.disabledPredial = false;
        },
        closeDialogClaveCatastral(e: any) {
            this.dialogClaveCatastral = false;
            if (e) {
                this.dialog = true;
                this.disabled = false;
            } else {
                this.closeDialogCatastro();
            }
        },
        closeDialogPredial() {
            this.dialogPredial = false;
        },
        closeDialog() {
            this.disabled = false;
            this.dialog = false;
            this.disabledPredial = false;
            this.solicitud = {};
            let cambioDomicilio = new CambioDomicilio();
            this.formDataNueva = {
                ...new CambioDomicilio(),
                domicilio: cambioDomicilio.domicilio,
            }
        },
        async consultarCatastro() {
            let { data: { resultados: catastro } } = await CatastroService.searchByClaveCatastral(this.formDataNueva.clave_catastral);

            if (catastro != false) {
                this.formDataNueva.domicilio.codigo_postal = catastro.cp.toString();
                this.formDataNueva.domicilio.localidad_id = catastro.id_localidad;
                this.formDataNueva.domicilio.colonia_id = catastro.id_colonia;
                this.formDataNueva.domicilio.calle_id = catastro.id_calle;
                this.formDataNueva.domicilio.numero_exterior = catastro.num_predio;

                let { data } = await EstablecimientoService.getPagoPredial(this.formDataNueva.clave_catastral);
                /* if(this.sucursal === "1"){
                    this.disabledCategoria = true;
                } */
                if (data.data) {
                    this.disabled = false;
                    this.disabledPredial = true;
                    this.dialogCatastro = false;
                    this.getDataDomicilio(this.establecimiento);
                    this.dialog = true;
                    this.$toast.success(data.message);
                } else {
                    this.$toast.error(data.message)
                    this.dialogPredial = true;
                }
            } else {
                this.$toast.error("La clave no ha sido encontrada en catastro")
            }
        },
        openDialogClaveCatastral(event: any) {
            if (event)
                this.dialogClaveCatastral = true
        },
        getDataDomicilio(item: any) {
            CambioDomicilioService.getDataDomicilio(item.id)
                .then((response) => {
                    //console.log(response);
                    this.formData = {
                        ...this.formData,
                        domicilio: response.data.locacion_domicilio.domicilio
                    };
                })
        },
        getSolicitudesCambioDomicilio(item: any) {
            CambioDomicilioService.getSolicitudesCambioDomicilio(item.id)
                .then((response) => {
                    //console.log(response);
                    this.solicitudes = response.data;
                })
        },
        setDomicilio(domicilio: Domicilio) {
            this.formData.domicilio = Object.assign({}, domicilio);
        },
        setDomicilioNueva(domicilio: Domicilio) {
            this.formDataNueva.domicilio = Object.assign({}, domicilio);
        },
        cerrar() {
            this.disabled = false;
            this.formDataNueva = new CambioDomicilio();
            this.$emit('close', this.dialog);
        },
        guardarSolicitud(item: any) {
            // @ts-ignore
            let validate = this.$refs.form.validate();
            if (validate) {
                EventBus.$emit("update-direccion");
                const formData = this.objectFormData(this.formDataNueva);
                CambioDomicilioService.setSolicitudCambioDomicilio(item.id, formData)
                    .then((response) => {
                        this.$toast.success(response.data.message);
                        let data = response.data.data;
                        this.verSolicitud(data);
                        this.getSolicitudesCambioDomicilio(this.establecimiento);
                    })
                    .catch((error) => {
                        this.$toast.error(error.response.data.message);
                    })
            } else {
                this.$toast.warning('Por favor, completa los campos obligatorios');
            }
        },
        objectFormData(data: any) {
            const formData = new FormData();
            formData.append(`id`, data.id);
            formData.append(`clave_catastral`, data.clave_catastral);
            formData.append(`donativo`, data.donativo ? 1 : 0 as any);
            formData.append(`numero_oficio_constancia_catastro`, data.numero_oficio_constancia_catastro ? data.numero_oficio_constancia_catastro : '');
            formData.append(`folio_recibo_pago_donativo`, data.folio_recibo_pago_donativo ? data.folio_recibo_pago_donativo : '');
            formData.append(`renovacion`, data.renovacion as any ? 1 : 0 as any);

            formData.append(`has_licencia_bebidas`, data.has_licencia_bebidas as any ? 1 : 0 as any);
            formData.append(`patente_propia`, data.patente_propia ? 1 : 0 as any);
            formData.append(`folio_licencia_bebida_alcoholica`, data.folio_licencia_bebida_alcoholica ? data.folio_licencia_bebida_alcoholica : '');

            formData.append(`has_alcohol`, this.hasAlcohol ? 1 : 0 as any);

            Object.entries(data.domicilio).forEach(([key, value]) => {
                let v = value as any;
                if (key == 'otro_domicilio') {
                    Object.entries(v).forEach(([key2, val]) => {
                        let d = val as any;
                        const baseKey2 = `domicilio[otro_domicilio][${key2}]`;
                        if (key2 == 'is_otra_colonia') {
                            formData.append(`${baseKey2}`, d ? 1 : 0 as any);
                        } else {
                            formData.append(`${baseKey2}`, d ? d : '');
                        }
                    });
                } else {
                    const baseKey = `domicilio[${key}]`;
                    formData.append(`${baseKey}`, v ? v : '');
                }
            });

            Object.entries(data.documentos).forEach(([key, documento]) => {
                let d = documento as any;
                const baseKey = `files[${key}]`;
                formData.append(`${baseKey}[file]`, d.file);
                formData.append(`${baseKey}[code]`, key);
            });

            return formData;
        },
        verSolicitud(item: any) {
            CambioDomicilioService.getSolicitudCambioDomicilio(this.establecimiento.id, item.id)
                .then((response) => {
                    this.dialog = true;
                    let solicitud = response.data;
                    this.solicitud = solicitud;
                    this.formDataNueva.id = solicitud.id;
                    this.formDataNueva.clave_catastral = solicitud.clave_catastral;
                    this.formDataNueva.donativo = solicitud.donativo == 1 ? true : false;
                    this.formDataNueva.numero_oficio_constancia_catastro = solicitud.numero_oficio_constancia_catastro;
                    this.formDataNueva.folio_recibo_pago_donativo = solicitud.folio_recibo_pago_donativo;
                    this.formDataNueva.renovacion = solicitud.renovacion ? 1 : 0;
                    this.formDataNueva.folio_licencia_bebida_alcoholica = item.folio_licencia_bebida_alcoholica;
                    this.formDataNueva.has_alcohol = item.has_alcohol ? true : false;

                    this.setFiles(solicitud);

                    this.formDataNueva.domicilio = {
                        ...solicitud.domicilio,
                    }

                    if (!solicitud.domicilio.otro_domicilio) {
                        let domicilio = new Domicilio();
                        this.formDataNueva.domicilio.otro_domicilio = domicilio.otro_domicilio;
                    }

                    if (this.solicitud.estatus_id != 1) {
                        this.disabled = true;
                    }
                })
                .catch((error) => {
                    console.log(error);
                })
        },
        setFiles(solicitud: any) {
            if (solicitud.constancia_r_s) {
                const rs = solicitud.constancia_r_s;
                this.formDataNueva.documentos['scdd_constancia_rs'].code = 'scdd_constancia_rs';
                this.formDataNueva.documentos['scdd_constancia_rs'].data = rs;
            }

            if (solicitud.saneamiento) {
                const saneamiento = solicitud.saneamiento;
                this.formDataNueva.documentos['scdd_saneamiento'].code = 'scdd_saneamiento';
                this.formDataNueva.documentos['scdd_saneamiento'].data = saneamiento;
            }

            if (solicitud.constancia_donativo) {
                const constanciaDonativo = solicitud.constancia_donativo;
                this.formDataNueva.documentos['constancia_donativo'].code = 'constancia_donativo';
                this.formDataNueva.documentos['constancia_donativo'].data = constanciaDonativo;
            }

            if (solicitud.recibo_pago) {
                const reciboPagoDonativo = solicitud.recibo_pago;
                this.formDataNueva.documentos['recibo_pago_donativo'].code = 'recibo_pago_donativo';
                this.formDataNueva.documentos['recibo_pago_donativo'].data = reciboPagoDonativo;
            }

            if (solicitud.licencia_alcohol) {
                const licenciaAlcohol = solicitud.licencia_alcohol;
                this.formDataNueva.documentos['licencia'].code = 'licencia';
                this.formDataNueva.documentos['licencia'].data = licenciaAlcohol;
            }

            if (solicitud.licencia_bebida) {
                const licenciaBebidas = solicitud.licencia_bebida;
                this.formDataNueva.documentos['pago_licencia_bebidas'].code = 'licencia_bebidas';
                this.formDataNueva.documentos['pago_licencia_bebidas'].data = licenciaBebidas;
            }

            if (solicitud.licencia_comodato) {
                const licenciaComodato = solicitud.licencia_comodato;
                this.formDataNueva.documentos['licencia_comodato'].code = 'licencia_comodato';
                this.formDataNueva.documentos['licencia_comodato'].data = licenciaComodato;
            }
        },
        changeStatusSolicitudCambioDomicilio(item: any, estatus: any) {
            let data = {
                estatus_id: estatus
            }
            CambioDomicilioService.changeStatusSolicitudCambioDomicilio(this.establecimiento.id, item.id, data)
                .then((response) => {
                    if (response.data.success) {
                        this.$toast.success(response.data.message);
                        this.getSolicitudesCambioDomicilio(this.establecimiento);
                    } else {
                        this.$toast.error(response.data.message);
                    }
                })
                .catch((error) => {
                    this.$toast.error(error.response.data.message);
                });
        },
        closeDialogRechazar() {
            this.disabled = false;
            this.dialogRechazar = false;
            this.motivoRechazo = '';
            this.solicitud = {};
        },
        verMotivoRechazo(item: any) {
            this.solicitud = item;
            this.dialogRechazar = true;
            this.motivoRechazo = item.motivo_rechazo;
        },
        updateSolicitudCambioDomicilio(item: any) {
            EventBus.$emit('update-direccion');
            // @ts-ignore
            let validate = this.$refs.form.validate();
            if (validate) {
                const formData = this.objectFormData(this.formDataNueva);
                CambioDomicilioService.updateSolicitudCambioDomicilio(this.establecimiento.id, item.id, formData)
                    .then((response) => {
                        this.$toast.success(response.data.message);
                        let data = response.data.data;
                        this.verSolicitud(data);
                        this.getSolicitudesCambioDomicilio(this.establecimiento);
                    })
                    .catch((error) => {
                        this.$toast.error(error.response.data.message);
                    })
            } else {
                this.$toast.warning('Por favor, completa los campos obligatorios');
            }
        }
    },
    computed: {
        hasAlcohol() {
            if (this.establecimiento?.has_alcohol_giro || this.establecimiento?.has_alcohol_actividad.length > 0) {
                return true;
            }
            return false;
        }
    }
})
