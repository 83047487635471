
import Vue from "vue";
import RULES from "@/mixins/rules";
import EstablecimientoService from "@/services/EstablecimientoService";

export default Vue.extend({
  name: 'HistoryLocation',
  mixins: [RULES],
  directives: {},
  components: {},
  props: {
    establecimiento: {
      type: Object,
      default: null,
      required: true,
    },
    estatusLicencia: {
      type: Number,
      required: true,
    }
  },
  data: () => ({
    dialog: false,
    dialogDelete: false,
    motivo_eliminar: ''
  }),
  created() {
  },
  mounted() {
    //@ts-ignore
  },
  computed: {},
  methods: {
    cerrarNotify() {
      this.dialog = false;
    },
    async deleteLocation() {
      let motivoEliminar: string = '0';
      if(this.estatusLicencia === 5){
        motivoEliminar = this.motivo_eliminar;
      }

      EstablecimientoService.deleteLocacion(this.establecimiento.id, motivoEliminar, this.estatusLicencia)
          .then(
              (response) => {
            if (response.data.success) {
              this.dialog = false;
              //this.$emit('emitDeleteLocacion', {establecimiento : this.establecimiento.id});
              this.$emit('emitDeleteLocacion', {
                establecimiento: this.establecimiento.id,
                motivoEliminar: motivoEliminar,
                estatusLicencia: this.estatusLicencia
              });
              this.$toast.success(response.data.message);
            } else {
              this.$toast.error(response.data.message);
            }
          })
    },
  },
});
