import Vue from 'vue';
import Vuex from 'vuex';

import contribuyente from './contribuyente';

import VuexPersistence from "vuex-persist";

Vue.use(Vuex);

const vuexPersistence = new VuexPersistence({
  key: 'apptest-global',
  storage: window.localStorage
})

export default new Vuex.Store({
  plugins: [vuexPersistence.plugin],
  state: {
    sidebar_mini: null,
    id: null,
    usuario: null,
    perfil_completo: null,
    user_detail: {
      nombre: '',
      apellido1: '',
      apellido2: '',
      email: '',
      rfc: '',
      curp: '',
    },
    role: null,
    session: false,
    token: null,
    expire_at: null,
    modules: [],
    routes: [],
    contribuyente: null,
    clave_temporal: null,
  },
  getters: {
    perfil_completo(state){
      return state.perfil_completo
    },
    usuario(state){
      return state.usuario
    },
    user_detail(state){
      return state.user_detail
    },
    role(state){
      return state.role
    },
    id(state){
      return state.id
    },
    session(state){
      return state.session;
    },
    modules(state){
      return state.modules;
    },
    routes(state){
      return state.routes;
    },
    contribuyente(state){
      return state.contribuyente;
    },
    sidebar(state){
      return state.sidebar_mini;
    },
    clave_temporal(state){
      return state.clave_temporal;
    }
  },
  mutations: {
    setSidebar(state, value){
      console.log("update mini", value);
      state.sidebar_mini = value;
    },
    updateUsuario(state, payload){
      state.usuario = payload;
    },
    updateContribuyente(state, payload){
      state.contribuyente = payload;
    },
    setToken(state, payload){
      state.id = payload.id;
      state.usuario = payload.name;
      state.perfil_completo = payload.perfil_completo;
      state.token = payload.token;
      state.session = payload.session;
      state.expire_at = payload.expires_at;
      state.modules = payload.modules;
      state.routes = payload.routes;
      state.role = payload.role;
      state.contribuyente = payload.contribuyente;
      state.clave_temporal = payload.clave_temporal;

      state.user_detail.nombre = payload.nombre;
      state.user_detail.apellido1 = payload.apellido1;
      state.user_detail.apellido2 = payload.apellido2;
      state.user_detail.email = payload.email;
      state.user_detail.rfc = payload.rfc;
      state.user_detail.curp = payload.curp;
    },
    logout(state){
      state.id = null;
      state.usuario = null;
      state.perfil_completo = null;
      state.token = null;
      state.session = false;
      state.expire_at = null;
      state.modules = [];
      state.routes = [];
      state.role = null;
      state.contribuyente = null;
      state.clave_temporal = null;

      state.user_detail.nombre = '';
      state.user_detail.apellido1 = '';
      state.user_detail.apellido2 = '';
      state.user_detail.email = '';
      state.user_detail.rfc = '';
      state.user_detail.curp = '';

    }
  },
  actions: {
    setSidebarMini(context, payload){
      console.log(payload);
      context.commit('setSidebar',payload);
    },
    updateUser(context, payload){
      context.commit('updateUsuario', payload);
    },
    updateContribuyente(context, payload){
      context.commit('updateContribuyente', payload);
    },
    setToken(context, payload){
      context.commit('setToken', payload);
    },
    logout(context){
      context.commit('logout');
    }
  },
  modules: {
    contribuyente
  }
})
