import { DefaultService } from "@/services/DefaultService";
import API from "@/plugins/axios";

class SectorService extends DefaultService {
  
  constructor() {
    super('sector');
  }
}

export default new SectorService;