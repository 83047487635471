
import Vue from "vue";
import { mapGetters } from "vuex";
import router from "@/router";
import RULES from "@/mixins/rules";
import LicenciaMovimientoService from "@/services/LicenciaMovimientoService";
import { googlemaps } from "googlemaps";
import Direccion from "@/components/Beneficiarios/Direccion.vue";
import { Domicilio } from "@/models/Domicilio";
import LicenciaService from "@/services/LicenciaService";
import EventBus from "@/plugins/event-bus";
import inputFile2 from "@/components/Common/InputFile2.vue";

export default Vue.extend({
    name: "CambioDomicilioForm",
    mixins: [RULES],
    components: {Direccion,inputFile2},
    data() {
        return {
            disabledOldDomicilio: true,
            licecniaId: this.$route.params.licecniaId,
            establecimiento: this.$route.params.establecimiento,
            defaultLatitud: 18.520326,
            defaultLongitud: -88.305718,
            oldDomicilio: new Domicilio(),
            formData: {
                tipo_movimiento_id: 2,
                domicilio: new Domicilio(),
                inePropietario: "",
                ineArrendatario: "",
                ineTestigos: [] as any[],
                hasArrendatario: false,
                hasTestigos: false,
            }
        }
    },
    computed: {
        ...mapGetters({
            usuario: "usuario",
            id: "id",
            contribuyente: "contribuyente",
            mini: "sidebar",
        }),
    },
    watch: {
        'formData.hasArrendatario': function() {
            if (!this.formData.hasArrendatario) {
                this.formData.ineArrendatario = "";
            }
        },
        'formData.hasTestigos': function() {
            if (!this.formData.hasTestigos) {
                this.formData.ineTestigos = [];
            }
        }
    },
    methods: {
        setDomicilio(domicilio: Domicilio) {
            this.formData.domicilio = Object.assign({}, domicilio);
        },
        inicializacionOldDomicilioMap(canvas: string) {
            let latitud =
            this.oldDomicilio.latitud !== 0 && this.oldDomicilio.latitud !== null
                ? this.oldDomicilio.latitud
                : this.defaultLatitud;

            let longitud =
            this.oldDomicilio.longitud !== 0 && this.oldDomicilio.longitud !== null
                ? this.oldDomicilio.longitud
                : this.defaultLongitud;
            this.map(latitud, longitud, canvas);
        },
        inicializacionDomicilioMap(canvas: string) {
            let latitud =
            this.formData.domicilio.latitud !== 0 && this.formData.domicilio.latitud !== null
                ? this.formData.domicilio.latitud
                : this.defaultLatitud;

            let longitud =
            this.formData.domicilio.longitud !== 0 && this.formData.domicilio.longitud !== null
                ? this.formData.domicilio.longitud
                : this.defaultLongitud;
            this.map(latitud, longitud, canvas);
        },
        map(latitud: number, longitud: number, canvas: string) {
            let mapOptions = {
                center: new google.maps.LatLng(latitud, longitud),
                zoom: 13,
                mapTypeId: google.maps.MapTypeId.ROADMAP,
                scrollwheel: false,
                disableDoubleClickZoom: true,
            };

            let mapa = new google.maps.Map(document.getElementById(canvas)!);
            
            mapa.setOptions(mapOptions);

            let marcador: any = null;
            let initialLocationuser = null;
            initialLocationuser = new google.maps.LatLng(latitud, longitud);
            mapa.setCenter(initialLocationuser);
            marcador = new google.maps.Marker({
                position: initialLocationuser,
                map: mapa,
            });
            let geocoder = new google.maps.Geocoder();
            let infowindow = new google.maps.InfoWindow();

            if (canvas === "map-canvas") {
                google.maps.event.addListener(mapa, "click", (evento: any) => 
                    this.handlerOldDomicilio(evento, marcador, mapa, geocoder, infowindow)
                );
            }else {
                google.maps.event.addListener(mapa, "click", (evento: any) => 
                    this.handlerDomicilio(evento, marcador, mapa, geocoder, infowindow)
                );
            }
        },
        handlerOldDomicilio(evento: any, marcador: any, mapa: any, geocoder: any, infowindow: any) {
            if (marcador != null) marcador.setMap(null);
            let latitud = evento.latLng.lat();
            let longitud = evento.latLng.lng();
            this.oldDomicilio.latitud = latitud;
            this.oldDomicilio.longitud = longitud;
            let coordenadas = new google.maps.LatLng(latitud, longitud);
            marcador = new google.maps.Marker({
                position: coordenadas,
                map: mapa,
                animation: google.maps.Animation.DROP,
                title: "Sede",
            });
            let latlng = { lat: parseFloat(latitud), lng: parseFloat(longitud) };
            geocoder.geocode({ location: latlng }, (results: any, status: any) => {
                if (status === "OK") {
                    if (results[0]) {
                        mapa.setZoom(14);

                        let address = results[0].address_components;

                        let cp = address.filter(
                            (item: any) => item.types[0] == "postal_code"
                        );

                        infowindow.setContent(results[0].formatted_address);
                        infowindow.open(mapa, marcador);
                    } else {
                        window.alert("No results found");
                    }
                } else {
                    window.alert("Geocoder failed due to: " + status);
                }
            });
        },
        handlerDomicilio(evento: any, marcador: any, mapa: any, geocoder: any, infowindow: any) {
            if (marcador != null) marcador.setMap(null);
            let latitud = evento.latLng.lat();
            let longitud = evento.latLng.lng();
            EventBus.$emit("update-direccion");
            this.formData.domicilio.latitud = latitud;
            this.formData.domicilio.longitud = longitud;
            let coordenadas = new google.maps.LatLng(latitud, longitud);
            marcador = new google.maps.Marker({
                position: coordenadas,
                map: mapa,
                animation: google.maps.Animation.DROP,
                title: "Sede",
            });
            let latlng = { lat: parseFloat(latitud), lng: parseFloat(longitud) };
            geocoder.geocode({ location: latlng }, (results: any, status: any) => {
                if (status === "OK") {
                    if (results[0]) {
                        mapa.setZoom(14);

                        let address = results[0].address_components;

                        let cp = address.filter(
                            (item: any) => item.types[0] == "postal_code"
                        );

                        infowindow.setContent(results[0].formatted_address);
                        infowindow.open(mapa, marcador);
                    } else {
                        window.alert("No results found");
                    }
                } else {
                    window.alert("Geocoder failed due to: " + status);
                }
            });
        },
        getData(){
            LicenciaService.get(this.licecniaId).then((response)=>{
                this.oldDomicilio = Object.assign({}, response.data.locacion.locacion_domicilio.domicilio);
                /* this.inicializacionOldDomicilioMap("map-canvas"); */
            })
        },
        async submit() {
            EventBus.$emit("update-direccion");
            await LicenciaMovimientoService.createNewMovement(this.licecniaId, this.formData)
            .then((response)=>{
                if (response.data.success) {
                    this.$toast.success('Movimiento solicitado exitosamente');
                }else{
                    this.$toast.error(response.data.message);
                }
            })
            .catch((error)=>{
                this.$toast.error(error.response.data.message);
            })
        },
        addTestigo(){
            this.formData.ineTestigos.push('');
        },
        removeTestigo(index: number){
            this.formData.ineTestigos.splice(index, 1);
        },
    },
    mounted() {
        this.getData();
        this.inicializacionDomicilioMap("map-canvas-domicilio");
    }
})
