
  import Vue from 'vue';
  import TramiteService from "@/services/TramiteService";
  import {mapGetters} from "vuex";
  import RULES from '@/mixins/rules';
  
  export default Vue.extend({
    name: 'Tramites',
    components: {
    },
    mixins: [RULES],
    data () {
      return {
        locaciones: []
      }
    },
    computed: {
        ...mapGetters({
            usuario: 'usuario', id: 'id', contribuyente: 'contribuyente'
        }),
    },
    mounted() {
        this.getData();
    },
    methods: {
        async getData(){
            let {data} = await TramiteService.get(this.contribuyente.id);     
            this.locaciones = data.locaciones;
        },
    }
  })
  