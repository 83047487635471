import { Convenio } from "./Convenio"; 

interface _LicenciaConvenio {
    id: number | null
    licencia_id: number | null
    locacion_id: number | null
    convenio_id: number | null
    vigencia_inicio: string | null
    vigencia_fin: string | null
    documento: any | null
    convenio: Convenio | null
    vigencia_inicio_menu: boolean
    vigencia_fin_menu: boolean
    documento_archivo: any
    file: any
}

class LicenciaConvenio implements _LicenciaConvenio {
    /* [key: string]: number | string | null | File | Convenio | boolean; */
    id: number | null
    licencia_id: number | null
    locacion_id: number | null
    convenio_id: number | null
    vigencia_inicio: string | null
    vigencia_fin: string | null
    documento: any | null
    convenio: Convenio | null
    vigencia_inicio_menu: boolean
    vigencia_fin_menu: boolean
    documento_archivo: any
    file: any
    constructor() {
        this.id = null;
        this.licencia_id = null;
        this.locacion_id = null;
        this.convenio_id = null;
        this.vigencia_inicio = null;
        this.vigencia_fin = null;
        this.documento = null;
        this.convenio = new Convenio();
        this.vigencia_inicio_menu = false;
        this.vigencia_fin_menu = false;
        this.documento_archivo = null;
        this.file = null;
    }
    
}

export { _LicenciaConvenio, LicenciaConvenio };