import { DefaultService } from "@/services/DefaultService";
import API from "@/plugins/axios";

class Licencia extends DefaultService {
  constructor() {
    super('licencia');
  }

  changeEstatus(licencia: number, data: any): Promise<any>{
    return API.post(`licencia/${licencia}/changestatus`, data);
  }

  getConvenio(): Promise<any>{
    return API.get('convenio/list');
  }

  saveConvenios(licencia: number, data: any): Promise<any>{
    return API.post(`licencia/${licencia}/saveConvenios`, data);
  }

  checkPendingLiquorLicenses(): Promise<any>{
    return API.get('establecimientos/check-pending-licencias-bebidas-alcoholicas');
  }

  saveSolicitudFiscalizacion(licencia: number, data: any): Promise<any>{
    return API.post(`licencia/${licencia}/saveSolicitudFiscalizacion`, data);
  }

  getSolicitudExtraordinarias(licencia: any): Promise<any>{
    return API.get(`licencia/${licencia}/getSolicitudExtraordinarias`);
  }

  findSolicitudExtraordinaria(licencia: any, solicitud: any): Promise<any>{
    return API.get(`licencia/${licencia}/findSolicitudExtraordinaria/${solicitud}`);
  }

  getReciboPago(locacion: any, tipo: any): Promise<any>{
    return API.post(`licencia/${locacion}/findReciboPago/${tipo}`);
  }

  actualizarSolicitudFiscalizacion(licencia: number, solicitud: any, data: any): Promise<any>{
    return API.post(`licencia/${licencia}/actualizarSolicitudFiscalizacion/${solicitud}`, data);
  }

  imprimirSolicitud(url: any, data: any, type: any, name: string){
    return API.request({
        url: url,
        data: data,
        method: type,
        responseType: 'blob'
    });
  }

  uploadSolicitudFirmada(licencia: any, solicitud: any, data: any): Promise<any>{
    return API.request({
      url: `licencia/${licencia}/uploadSolicitudFirmada/${solicitud}`,
      data: data,
      method: `POST`,
      headers: {
        'Content-Type': 'multipart/form-data'
      },
      withCredentials: true,
    });
  }

  uploadReciboPago(locacion: any, data: any): Promise<any>{
    return API.request({
      url: `licencia/${locacion}/uploadReciboPago`,
      data: data,
      method: `POST`,
      headers: {
        'Content-Type': 'multipart/form-data'
      },
      withCredentials: true,
    });
  }

  changeSolicitudEstatus(licencia: any, solicitud: any, data: any): Promise<any>{
    return API.post(`licencia/${licencia}/changeSolicitudEstatus/${solicitud}`, data);
  }

  viewEdoCuentaFiscalizacion(url: any, data: any, type: any, name: string): Promise<any>{
    return API.request({
      url: url,
      data: data,
      method: type,
      responseType: 'blob'
    });
  }

  viewConstanciaExtraordinaria(url: any, data: any, type: any, name: string): Promise<any>{
    return API.request({
      url: url,
      data: data,
      method: type,
      responseType: 'blob'
    });
  }

  cambioNombreComercial(licencia: any, data: any): Promise<any>{
    return API.post(`licencia/${licencia}/cambioNombreComercial`, data);
  }

  getCambioNombreComercial(licencia: any): Promise<any>{
    return API.get(`licencia/${licencia}/getCambioNombreComercial`);
  }

  chageEstatusNombreComercial(licencia: any, solicitud: any, data: any): Promise<any>{
    return API.post(`licencia/${licencia}/chageEstatusNombreComercial/${solicitud}`, data);
  }
  
  notifyEdoRs(locacion: any, data: any): Promise<any>{
    return API.post(`contribuyente/notifyEdoRs/${locacion}`, data);
  }

  notificarPagoRealizadoNombreComercial(licencia: any, solicitud: any, data: any): Promise<any>{
    return API.post(`licencia/${licencia}/notificarPagoRealizadoNombreComercial/${solicitud}`, data);
  }

  updateCambioNombreComercial(licencia: any, solicitud: any, data: any): Promise<any>{
    return API.post(`licencia/${licencia}/updateCambioNombreComercial/${solicitud}`, data);
  }
}

export default new Licencia;