import { DefaultService } from "@/services/DefaultService";
import API from "@/plugins/axios";

class MaterialService extends DefaultService {
  
  constructor() {
    super('material');
  }
}

export default new MaterialService;