
/**
 * TODO: Queda pendiente agregar a los otros dos formularios lo mismo que tiene RequestForm.
 * Asi mismo, debo validar si usaré el EstablishmentService.
 */
import Vue from "vue";
import RULES from "@/mixins/rules";
import { mapGetters } from "vuex";
import { Establecimiento } from "@/models/Establecimiento";
import EstablecimientoService from "@/services/EstablecimientoService";
import { VForm } from "@/types/formvalidate";
import Direccion from "@/components/Beneficiarios/Direccion.vue";
import { Domicilio } from "@/models/Domicilio";
import EventBus from "@/plugins/event-bus";
import CatastroService from "@/services/CatastroService";
import AnuncioForm from "@/components/Anuncios/Create/RequestForm.vue";
import { Anuncio } from "@/models/Anuncio";
import PhoneInput from "@/components/Common/PhoneInput.vue";
import CustomFile from "@/components/Common/CustomFile.vue";
import MoneyInput from "@/components/Common/MoneyInput.vue";
import CustomFileNotRequired from "@/components/Common/CustomFileNotRequired.vue";

import ContribuyenteService from '@/services/ContribuyenteService';
export default Vue.extend({
  name: "CreateEstablishment",
  mixins: [RULES],
  directives: {},
  components: {
    Direccion, AnuncioForm, CustomFile, PhoneInput, MoneyInput, CustomFileNotRequired
  },
  props: {
      sucursal: {
        type: String,
        default: "0",
        required: false
      },
      idSolicitud: {
        type: String,
        default: "0"
      },
      update: {
        type: Boolean,
        default: false
      },
      guardar: {
        type: Boolean,
        default: false
      },
      actualizar: {
        type: Boolean,
        default: false
      },
      disabledIndex: {
        type: Boolean,
        default: false
      }
  },
  data: () => ({
    date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),
    menu: false,
    loading: false,
    editable: false,
    formData: {
      ...new Establecimiento(),
    },
    disabled: true,
    disabledPredial: false,
    disabledCategoria: false,
    disabledMercado : false,
    disabledForm: {
      localidad: true,
      asentamiento: true,
    },
    licenciasRenovar: [] as any[],
    dialogLicenciasRenovar: false,
    dialogClaveCatastral: false,
    giroActividades: [] as any[],
    giroSubactividades: [] as any[],
    actividades: [] as any,
    tipoPropiedades: [],
    categorias: [],
    tipoLocales: [] as any[],
    subtiposLocales: [] as any[],
    localidades: [],
    asentamientos: [],
    isSchool: false,
    archivos: [],
    showInput: {},
    giro: "",
    dialog: false,
    dialogPredial: false,
    formAnuncios: [] as Anuncio[],
    actividades_secundarias: [] as any[],
    hasAnuncios: false,
    uploadPercentage: 0,
    notLoading: false,
    folio: "",
    documento: null,
    dialogView: false,
    solicitud_giro: "",
    estatus_licencia: null as any,
    meses: [
        { id: "01", descripcion: "Enero" },
        { id: "02", descripcion: "Febrero" },
        { id: "03", descripcion: "Marzo" },
        { id: "04", descripcion: "Abril" },
        { id: "05", descripcion: "Mayo" },
        { id: "06", descripcion: "Junio" },
        { id: "07", descripcion: "Julio" },
        { id: "08", descripcion: "Agosto" },
        { id: "09", descripcion: "Septiembre" },
        { id: "10", descripcion: "Octubre" },
        { id: "11", descripcion: "Noviembre" },
        { id: "12", descripcion: "Diciembre" },
    ],
    tableClave: {
      headers: [
        {text: 'Clave catastral', align: 'center', sortable: false, value: 'cve_catastral'},
        {text: 'Establecimiento', align: 'center', sortable: false, value: 'd_establecimiento'},
        {text: 'Clave establecimiento', align: 'center', sortable: false, value: 'id_establecimiento'},
        {text: 'Estatus', align: 'center', sortable: false, value: 'estatus'},
      ],
      items: [],
    },
    errorSnackbar: false,
    errorMessage: '',
    isForLicenses: false,
    alertMessage: '',
  }),
  watch: {
        "formData.tipo_local_id": async function (tipoLocalId) {
            if (tipoLocalId !== null && tipoLocalId !== undefined) {
                //@ts-ignore
                await this.getSubtipoLocalesByTipoLocal(tipoLocalId);
            }
        },
        "formData.superficie_m2": {
          deep: true,
          handler(newVal) {
            if(newVal > 300) {
                // this.formData.tipo_proceso_id = "1";
                this.isForLicenses = true;
                this.alertMessage = "Tu establecimiento tiene más de 300 m2 de superficie, por lo tanto, tu solicitud será cambiada de área al Departamento de Licencias de Funcionamiento.";
            }
          }
        },
        tipo_proceso: {
            handler(newVal) {
                this.formData.tipo_proceso_id = this.tipo_proceso;
                //this.updated();
            },
            deep: true,
        },
        establecimiento: {
            handler(newVal) {
                if (this.establecimiento != 0) {
                    //@ts-ignore
                    //this.getDataEstablecimiento();
                }
            },
            deep: true,
        },
        guardar: {
            handler(newVal) {
                if (this.guardar == true) {
                    //@ts-ignore
                    this.handleFormSubmit(1);
                }
            },
            deep: true,
        },
        actualizar: {
            handler(newVal) {
                if (this.actualizar == true) {
                    //@ts-ignore
                    this.handleFormSubmit(2);
                }
            },
            deep: true,
        },
        disabledIndex: {
            handler(newVal) {
                this.disabled = this.disabledIndex;
            },
            deep: true,
        },
        esHoteleria: {
            handler(newVal) {
                if (this.esHoteleria) {
                  //@ts-ignore
                  this.verifyZofemat();
                }else{
                  this.formData.zofemat = false;
                }
            }
        },
        'formData.donativo': function (value){
          console.log("donativo",value)
          if(value){
            this.formData.clave_catastral = 'DONATIVO';
            this.disabled = false;
          } else {
            //this.formData.clave_catastral = this.formData.clave_catastral;
            if (this.formData.clave_catastral === 'DONATIVO' || this.estatus_licencia === 10 || this.estatus_licencia === 4 || this.estatus_licencia === 3) {
              this.disabled = true;
            } else {
              this.disabled = false;
            }
          }
        },
        'formData.no_cuento_clave': function (value){
          if (this.formData.clave_catastral != 'MERCADO' && this.formData.clave_catastral != 'ZONA FEDERAL') {
            this.formData.clave_catastral = 'DONATIVO';
            this.disabledPredial = false;
            this.formData.donativo = true;
          }
        },
        'formData.zona_federal': function (value){
          if(value){
            console.log("zona federal",value)
            this.formData.clave_catastral = 'ZONA FEDERAL';
            this.disabled = false;
          } else {
            //this.formData.clave_catastral = this.formData.clave_catastral;
            if (this.formData.clave_catastral === 'ZONA FEDERAL' || this.estatus_licencia === 10 || this.estatus_licencia === 4 || this.estatus_licencia === 3) {
              this.disabled = true;
            } else {
              this.disabled = false;
            }
          }
        }
    },
  async mounted() {
    //@ts-ignore
    await this.getData();
    if(this.sucursal === "1"){
      //@ts-ignore
      this.formData.categoria_id = 3;
    }
    this.formData.tipo_proceso_id = this.$route.query.tipo;
  },
  computed: {
    ...mapGetters({
      usuario: "usuario",
      id: "id",
      contribuyente: "contribuyente",
      mini: "sidebar",
    }),
    form(): VForm {
      return this.$refs.form as VForm;
    },
    formDir(): VForm {
      return this.$refs.formDireccion as VForm;
    },
    isNotOwner: function(){
      return this.formData.tipo_propiedad_id != 1 && this.formData.tipo_propiedad_id != 4 && this.formData.tipo_propiedad_id != null
    },
    isBaldio: function() {
      return this.formData.tipo_propiedad_id == 4;
    },
    isSucursal: function(){
      return this.formData.categoria_id == 3;
    },
    hasUsoSuelo: function() {
      if(this.formData.tipo_proceso_id == 2 && this.formData.tiene_uso_suelo == true){
        return false;
      } else {
        return true;
      }
    },
    hasAttachFiles: function(){
      return this.formData.tipo_propiedad_id != null || this.esHoteleria || this.isBaldio || this.hasAlcohol || this.isSchool || this.formData.tiene_anuencia || this.isSucursal
    },
    esHoteleria: function(){
      let hasHotel = this.formData.actividades_secundarias.find((item: any) => item.d_giro.includes("HOTEL") );
      
      if(hasHotel != undefined){
        return true;
      }

      if(this.formData.giro_actividad_id!=null){
        //@ts-ignore
        if (this.formData.giro_actividad_id?.descripcion) {
          //@ts-ignore
          return this.formData.giro_actividad_id.descripcion.includes("HOTEL")  || this.formData.giro_actividad_id.descripcion.includes("MOTEL");
        }
        //@ts-ignore
        if (this.formData.giro_actividad_id?.d_giro) {
          //@ts-ignore
          return this.formData.giro_actividad_id.d_giro.includes("HOTEL")  || this.formData.giro_actividad_id.d_giro.includes("MOTEL");
        }
      }else{
        return false;
      }
    },
    hasAlcohol: function(){
      let hasPatente = this.formData.actividades_secundarias.find((item: any) => item.req_patente == "SI");
      
      if(hasPatente != undefined){
        return true;
      }else{
        this.formData.horario_extendido = false;
      }

      if(this.formData.giro_actividad_id!=null){
        //@ts-ignore
        if (this.formData.giro_actividad_id.req_patente == "SI") {
          return true;
        } else {
          this.formData.horario_extendido = false;
        }
      }else{
        this.formData.horario_extendido = false;
        return false;
      }
    },
    isRenovacionMercado: function(){
      let tipoLocal = this.formData.tipo_local_id as any;
      console.log(tipoLocal, this.formData.clave_catastral, this.formData.renovacion);
      
      if (this.formData.clave_catastral == 'MERCADO' && this.formData.renovacion && tipoLocal.id == 2) {
        return true;
      }
      return false;
    }
  },
  methods: {
    closeDialog(){
      this.dialogLicenciasRenovar = false;
      //@ts-ignore
      this.tableClave.items = [];
    },
    handleFileUploadSuccess(data: any) {
      const typeFile = data.typeFile;
      const message = data.message;
      switch (typeFile) {
        case 'frente':
        case 'interior':
        case 'wc':
        case 'lateralIzquierdo':
        case 'lateralDerecho':
          this.formData.fotos[typeFile].data = null;
          break;
        default:
          this.formData.documentos[typeFile].data = null ;
          break;
      }
      this.$toast.success(message);
    },
    handleFileUploadError (data: any){
      const message = data.message;
      this.$toast.error(message);
    },
    checkAlcoholActivities() {
      if(this.formData.tipo_proceso_id == 2) {
          if(this.hasAlcohol) {
          this.alertMessage = 'Tu establecimiento tiene un giro y/o actividad secundaria de Alcohol, por lo tanto, tu solicitud será cambiada de área al Departamento de Licencias de Funcionamiento.';
          this.isForLicenses = true;
        }
      }
    },
    showErrorSnackbar(message: any) {
      this.errorMessage = message;
      this.errorSnackbar = true;
    },
    openDialogClaveCatastral(event: any) {
      if(event)
        this.dialogClaveCatastral = true
    },
    pagarPredialLinea(){
      window.open('http://sistemas.opb.gob.mx/epredialopb/', '_blank')
    },
    async generarPagoPredial(){
      await EstablecimientoService.newRequest(`licencia/${this.formData.clave_catastral}/generatePagoPredial`,{},'POST','Temp')
      .then((response) => {
          let file = new Blob([response.data],{type: response.headers['content-type']});
          const url = window.URL.createObjectURL(file);
          const tipoArchivo = 'Estado de cuenta.'
          //@ts-ignore
          this.documento = {
              title: tipoArchivo,
              url: url,
              mime: response.headers['content-type'] 
          }
          this.dialogView = true;
      })
    },
    setDomicilio(domicilio: Domicilio) {
      this.formData.domicilio = Object.assign({}, domicilio);
    },
    incrementAnuncio() {
      this.formAnuncios.push(new Anuncio());
    },
    setAnuncio(anuncio: Anuncio, index: any) {
      this.formAnuncios[index] = Object.assign({}, anuncio);
    },
    deleteAnuncio(index: number) {
      this.formAnuncios.splice(index, 1);
    },
    async sendGiro(){
      const object = {
        'solicitud_giro': this.solicitud_giro,
      }
      let { data } = await ContribuyenteService.sendGiro(this.contribuyente.id, object);
      if (data.success) {
        this.$toast.success(data.message);
        this.dialog = false;
      } else {
        this.$toast.error(data.message)
      }
    },
    async getLicencias(){
      let { data } = await EstablecimientoService.getLicenciasByClaveCatastral(this.formData.clave_catastral);
      if(data.data.length){
        this.tableClave.items = data.data;
        this.dialogLicenciasRenovar = true;
        this.$toast.warning("Si tu establecimiento aparece en el listado, ir al apartado de RENOVACION");
      }
    },
    async consultarCatastro(){
      let {data: {resultados: catastro}} = await CatastroService.searchByClaveCatastral(this.formData.clave_catastral);

      if(catastro != false){
        this.formData.domicilio.codigo_postal = catastro.cp.toString();
        this.formData.domicilio.localidad_id = catastro.id_localidad;
        this.formData.domicilio.colonia_id = catastro.id_colonia;
        this.formData.domicilio.calle_id = catastro.id_calle;
        this.formData.domicilio.numero_exterior = catastro.num_predio;

        let { data } = await EstablecimientoService.getPagoPredial(this.formData.clave_catastral);
        if(this.sucursal === "1"){
          this.disabledCategoria = true;
        }
        if (data.data) {
          this.disabled = false;
          this.disabledPredial = true;
          this.$toast.success(data.message);
        } else {
          this.$toast.error(data.message)
          this.dialogPredial = true;
        }
      }else{
        this.$toast.error("La clave no ha sido encontrada en catastro")
      }
    },
    async handleFormSubmit(option: number) {
      let isValid = true;
      //@ts-ignore
      isValid = this.formDir.validate();
      //@ts-ignore
      if(this.form.validate() && isValid){

        this.disabled = true;
        EventBus.$emit("update-direccion");

        this.formData.anuncios = Object.assign(
            {},
            this.formAnuncios
          );

        /*if (this.formData.superficie_m2 != "") {
          if (parseInt(this.formData.superficie_m2) <= 300 && !this.hasAlcohol) {
            //this.formData.tipo_proceso_id = "2";
          }

          if (this.hasAlcohol) {
            this.formData.tipo_proceso_id = "1";
          }

          if (parseInt(this.formData.superficie_m2) > 300) {
            this.formData.tipo_proceso_id = "1";
          }
        }*/

        const requestJsonData = {
          ...this.formData,
          documentos: null,
          fotos: null
        }

        let success = false;
        let location = null;
        
        if (option == 1) {
          //const { data: { success, data: location } } = await EstablecimientoService.create(requestJsonData);
          let dataApi = await EstablecimientoService.create(requestJsonData).catch(() => this.disabled = false).catch(() => {
            this.disabled = false; 
            success = false;
            this.$emit("formSubmitted", { success: false, location: null, origen: option });
          });
          success = dataApi.data.success;
          location = dataApi.data.data;
        }else{
          let dataApi = await EstablecimientoService.update(this.idSolicitud, requestJsonData).catch(() => {
            this.disabled = false; 
            success = false;
            this.$emit("formSubmitted", { success: false, location: null, origen: option });
          });
          success = dataApi.data.success;
          location = dataApi.data.data;
        }
        
        if(success){
          if (this.actualizar) {
            this.disabled = false;
            this.$toast.success("Establecimiento actualizado exitosamente");
          }else{
            this.$toast.success("Establecimiento registrado exitosamente");
          }
          
          let formDataDocuments = new FormData();
          //@ts-ignore
          formDataDocuments = this.objectToFormData(this.formData.documentos, formDataDocuments)
          formDataDocuments.append('contribuyente_id', location.contribuyente_id);
          formDataDocuments.append('locacion_id', location.id);
          
          const responseFilesDocuments = await EstablecimientoService.sendFiles(formDataDocuments,(progressEvent: any) => {
            this.uploadPercentage = parseInt(Math.round((progressEvent.loaded * 100) / progressEvent.total).toString());
          });

          //@ts-ignore
          if(responseFilesDocuments.status == 200){
            this.uploadPercentage = 0;
            this.$toast.success("Documentos adjuntados con exito");
          }   

          let formDataFotos = new FormData();
          //@ts-ignore
          formDataFotos = this.objectToFormData(this.formData.fotos, formDataFotos)
          formDataFotos.append('contribuyente_id', location.contribuyente_id);
          formDataFotos.append('locacion_id', location.id);
          
          const responseFilesFotos = await EstablecimientoService.sendFiles(formDataFotos,(progressEvent: any) => {
            this.uploadPercentage = parseInt(Math.round((progressEvent.loaded * 100) / progressEvent.total).toString());
          });

          //@ts-ignore
          if(responseFilesFotos.status == 200){
            this.uploadPercentage = 0;
            this.$toast.success("Fotos adjuntados con exito");
          }   

          this.$emit("formSubmitted", { success, location, origen: option });
        }else{
          this.$toast.error("No se pudo registrar el establecimiento, revise sus información");
          this.disabled = false;
          this.$emit("formSubmitted", { success: false, location, origen: option  });
        }
      }else{
        this.$toast.error("Proporcione la información indicada en el formulario");
        this.disabled = false;
        this.$emit("formSubmitted", { success: false, location, origen: option });
      }
        
    },
    async sendFiles(){
      const data = new FormData();
    },
    objectToFormData(obj: any, formData: any) {
      let counterItems = 0;
      Object.entries(obj).forEach(([key, value]) => {
        //@ts-ignore
        formData.append(`documento[${counterItems}][file]`, value.file);
        formData.append(`documento[${counterItems}][code]`, key);
        counterItems++;
        
      });

      return formData;
    },
    async getData() {
      await EstablecimientoService.getGiroActividades().then((response) => {
        this.giroActividades = response.data;
      });
      await EstablecimientoService.getTipoPropiedades().then((response) => {
        this.tipoPropiedades = response.data;
      });
      await EstablecimientoService.getCategorias().then((response) => {
        this.categorias = response.data;
      });
      await EstablecimientoService.getTipoLocales().then((response) => {
        this.tipoLocales = response.data;
      });
      await EstablecimientoService.getActividades().then((response) => {
        this.actividades = response.data;
      });
      await EstablecimientoService.getActividadesSecundarias().then((response) => {
        this.actividades_secundarias = response.data;
      });

      if(this.idSolicitud!="0"){
        this.disabled = false;
        this.disabledPredial = true;
        const {data} = await EstablecimientoService.get(this.idSolicitud);
        this.folio = data?.licencia[0]?.folio;
        this.estatus_licencia = data?.licencia[0]?.estatus_licencia;
        if (data?.licencia[0]?.estatus_licencia == 10) {
          this.disabled = true;
        }
        this.archivos = data.archivos;
        this.formData = {
          ...this.formData,
          ...data,
          domicilio: data.locacion_domicilio.domicilio
        };

        if (data.renovacion) {
          if (this.formData.clave_catastral == 'MERCADO') {
            this.disabledMercado = true;
          }
          //this.formData.tiene_uso_suelo = true;
          if (!data.mes_pago_residuos) {
            this.formData.mes_pago_residuos = "01";
          }
        }

        this.formData.giro_actividad_id = data.giro_opb;
        this.formData.horario_extendido = data?.licencia[0]?.horario_extendido;
        if (data.licencia.length > 0) {
          this.formData.donativo = data?.licencia[0]?.donativo;
          this.formData.zona_federal = data?.licencia[0]?.zona_federal;
        }else{
          this.formData.donativo = data.donativo_renovacion;
          this.formData.zona_federal = data.zona_federal_renovacion;
        }

        let tipoLocal = data.tipo_local_id;
        if (this.formData.donativo == true || this.formData.zona_federal == true || (data.clave_catastral == 'MERCADO' && tipoLocal.id == 2)) {
          this.formData.no_cuento_clave = true;
        }
        
        this.formData.folio_donativo = data?.licencia[0]?.folio_donativo;
        this.formData.numero_oficio_constancia_catastro = data?.licencia[0]?.numero_oficio_constancia_catastro;
        this.formData.folio_recibo_pago_donativo = data?.licencia[0]?.folio_recibo_pago_donativo;
        this.formData.folio_licencia_bebida_alcoholica = data?.licencia[0]?.folio_licencia_bebida_alcoholica;

        const fotos = ['frente','interior','wc','lateralDerecho','lateralIzquierdo'];

        let documentos: [] = data.archivos.filter((archivo: any) => !fotos.includes(archivo.archivo.tipo_archivo)).map((archivo: any) => {
          return {
            code: archivo.archivo.tipo_archivo,
            file: '',
            binary: '',
            data: archivo.archivo  
          }
        });

        const frente = data.archivos.find((archivo: any) => archivo.archivo.tipo_archivo == "frente");
        if(frente != undefined){
          this.formData.fotos.frente = {
            code: frente.archivo.tipo_archivo,
            file: '',
            binary: '',
            data: frente.archivo  
          }
        }

        const interior = data.archivos.find((archivo: any) => archivo.archivo.tipo_archivo == "interior");
        if(interior != undefined){
          this.formData.fotos.interior = {
            code: interior.archivo.tipo_archivo,
            file: '',
            binary: '',
            data: interior.archivo  
          }
        }
        const wc = data.archivos.find((archivo: any) => archivo.archivo.tipo_archivo == "wc");
        if(wc != undefined){
          this.formData.fotos.wc = {
            code: wc.archivo.tipo_archivo,
            file: '',
            binary: '',
            data: wc.archivo  
          }
        }
        const lateralIzquierdo = data.archivos.find((archivo: any) => archivo.archivo.tipo_archivo == "lateralIzquierdo");
        if(lateralIzquierdo != undefined){
          this.formData.fotos.lateralIzquierdo = {
            code: lateralIzquierdo.archivo.tipo_archivo,
            file: '',
            binary: '',
            data: lateralIzquierdo.archivo  
          }
        }
        const lateralDerecho = data.archivos.find((archivo: any) => archivo.archivo.tipo_archivo == "lateralDerecho");
        if(lateralDerecho != undefined){
          this.formData.fotos.lateralDerecho = {
            code: lateralDerecho.archivo.tipo_archivo,
            file: '',
            binary: '',
            data: lateralDerecho.archivo  
          }
        }
        
        //@ts-ignore
        let finalDocuments = [];
        
        documentos.forEach((element: any, index: any)=>{
          //@ts-ignore
          if (!finalDocuments.hasOwnProperty(element.code)) {
            finalDocuments[element.code] = element;
          }
        })
        //@ts-ignore
        this.formData.documentos = {
          ...this.formData.documentos,
          //@ts-ignore
          ...finalDocuments
        };  
        let location = data;
        this.$emit("formSubmitted", { success: true, location, origen: 2 });
      }
      this.notLoading = true;

    },
    async getSubtipoLocalesByTipoLocal(tipoLocalId: number) {
      try {
        const response = await EstablecimientoService.getSubtiposLocales(
          tipoLocalId
        );
        let tipos = response.data;
        tipos.forEach((tipo: any) => {
          let subtipos = tipo.subtipos;
          this.subtiposLocales = [];
          subtipos.forEach((subtipo: any) => {
            this.subtiposLocales = [...this.subtiposLocales, subtipo];
          });
        });
      } catch (error) {
        console.error("Error al obtener subactividades:", error);
      }
    },
    getPagoMessage(){
      if (!this.formData.documentos.hasOwnProperty('pago_licencia_bebidas')) {
        return {
          message: 'Usted tiene 2 meses para subir su licencia de bebidas.',
          subirLicencia: false,
        }
      }
      if (!this.formData.documentos['pago_licencia_bebidas'].data) {
        return {
          message: '',
          subirLicencia: false,
        }
      }
      let date = new Date(this.formData.documentos['pago_licencia_bebidas'].data.created_at);
      date.setMonth(date.getMonth() + 2);
      let fechaActual = new Date();
      if (date <= fechaActual) {
        return {
          message: 'Su licencia de funcionamiento quedará deshabilitada.',
          subirLicencia: false,
        }
      } else {
        this.formData.has_licencia_bebidas = true
        return {
          message: 'Usted cuenta con dos meses a partir de ahora para subir su licencia de bebidas.',
          subirLicencia: true,
        }
      }
    },
    async verifyZofemat(){
      const {data} = await EstablecimientoService.verifyZofemat(this.formData.clave_catastral);
      if (data.data) {
        this.formData.zofemat = true;
        this.$toast.success(data.message);
        //@ts-ignore
        this.expedienteZofemat(data.data);
      }else{
        this.formData.zofemat = false;
        /* this.disabled = true; */
        this.$toast.error(data.message);
      }
    },
    async expedienteZofemat(resultado: any){
      let expediente = resultado[0].num_expediente
      const {data} = await EstablecimientoService.expedienteZofemat(expediente);
      if (data.data) {
        if (data.data.estatus_pago == 'PAGADO') {
          this.formData.zofemat_expediente = expediente;
          this.disabled = false;
          this.$toast.success('Impuestos pagados de Zona Federal');
        }else{
          this.$toast.error('Debe pagar tus impuestos de Zona Federal primero.');
          this.disabled = true;
        }
      }else{
        this.$toast.error(data.message);
        this.disabled = true;
      }
    },
    lastLicense(item: any) {
      return item.renovacion == 1 && item.tipo_proceso_renovacion_id == 2;
    },
  },
});
