import { DefaultService } from "@/services/DefaultService";
import API from "@/plugins/axios";

class LicenciaMovimientoService extends DefaultService {
  
  constructor() {
    super('licencia_movimiento');
  }

  getMovementsByType(licencia_id: number | string, data: any) : Promise<any>{
    let params = new URLSearchParams(data).toString();
    return API.get(`/${this.url}/${licencia_id}/getmovementsbytype?${params}`);
  }

  createNewMovement(licencia_id: number | string, data: any) : Promise<any>{
    return API.post(`/${this.url}/${licencia_id}/createnewmovement`, data);

  }
}

export default new LicenciaMovimientoService;