interface _Convenio {
  id: number | null;
  descripcion: string | null;
  activo: number | null;
}

class Convenio implements _Convenio {
  id = null;
  descripcion = null;
  activo = null;
}

export { _Convenio, Convenio };