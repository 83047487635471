
/**
 * TODO: Queda pendiente agregar a los otros dos formularios lo mismo que tiene RequestForm.
 * Asi mismo, debo validar si usaré el EstablishmentService.
 */
import Vue from 'vue';
import { mapGetters } from "vuex";
import RequestForm from './RequestForm.vue';
import RULES from "@/mixins/rules";

export default Vue.extend(
  {
    name: "CreateUsoSuelo",
    mixins: [RULES],
    components: {
      RequestForm
    },
    directives: {},
    data(){
      return {
        currentStep: 1,
        totalSteps: 3,
        giro: "",
        dialog: false,
        formData: {
          request: {},
          documentation: {},
          payment: {}
        }
      };
    },
    mounted() {
        console.log("testing");
    },
    computed: {
      ...mapGetters({
        usuario: 'usuario', id: 'id', contribuyente: 'contribuyente', mini: 'sidebar'
      }),
    },
    watch: {},
    methods: {
      handleFormSubmitted(data: any) {
        this.$set(this.formData, 'request', data);
        this.submitData();
      },
      submitData() {
        console.log('Enviar datos:', this.formData);
      },
    },
  }
)
