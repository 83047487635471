
import Vue from 'vue'
import UserService from "@/services/UserService";
import {mapGetters} from "vuex";
import {Usuario} from "@/models/Usuario";
import RULES from '@/mixins/rules'
import UnMask from '@/common/unmask'

export default Vue.extend({
  name: 'Perfil',
  mixins: [RULES],
  data () {
    return {
      loading: false,
      editable: false,
      formUser: new Usuario(),
      formDefault: new Usuario(),
    }
  },
  computed: {
    ...mapGetters({
      usuario: 'usuario', id: 'id'
    })
  },
  mounted() {
    this.getData();
  },
  methods: {
    async getData(){
      let {data} = await UserService.get(this.id);
      data.data.telefono = Vue.filter('VMask')(data.data.telefono, '(###) ### ####'); // this.$options.filters.VMask(data.data.telefono, '(###) ### ####');
      this.formDefault = data.data;
      this.formUser = Object.assign({}, this.formDefault);
    },
    editar(){
      this.editable = true;
    },
    cancelar(){
      this.formUser = Object.assign({}, this.formDefault);
      this.editable = false;
    },
    async update(){
      let formdata = Object.assign({}, this.formUser);
      if(formdata.telefono!=null && formdata.telefono!='')
        formdata.telefono = UnMask.unmask(formdata.telefono, '(###) ### ####')
      let {data} = await UserService.update(this.id, formdata);
      if(data.success) {
        this.$toast.success('Perfil actualizado exitosamente');
        await this.$store.dispatch('updateUser', this.formUser.nombre)
        this.formDefault = Object.assign({}, this.formUser);
      }
      this.editable = false;
    }
  }
})
