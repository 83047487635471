<template>
    <v-container>
        <v-row>
            <v-col>
                <h1 class="text-h3">Dashboard</h1>
            </v-col>
        </v-row>
        <v-row>
          
        </v-row>
    </v-container>
</template>

<script>
import Vue from 'vue'

import LineChart from '@/components/charts/LineChart.js'
import PieChart from "@/components/charts/PieChart";
import AreaChart from "@/components/charts/AreaChart";
import StackedChart from "@/components/charts/StackedChart";
import BarChart from "@/components/charts/BarChart";
import axios from "@/plugins/axios";
import {mapGetters} from "vuex";

export default {
    name: 'Dashboard',
    mixins: [],
    components: {
      LineChart, PieChart, AreaChart, StackedChart, BarChart
    },
    directives: {},
    data: () => ({
      
    }),
    mounted() {
    },
    computed: {
      
    },
    watch: {},
    methods: {
    }
}
</script>
<style>
.small {
  position: relative;
  max-height: 100px;
}
</style>
