
/**
 * TODO: Queda pendiente agregar a los otros dos formularios lo mismo que tiene RequestForm.
 * Asi mismo, debo validar si usaré el EstablishmentService.
 */
import Vue from 'vue';
import { mapGetters } from "vuex";
import RequestForm from './RequestForm.vue';
import RULES from "@/mixins/rules";
import router from '@/router';

export default Vue.extend(
  {
    name: "CreateAnuncio",
    mixins: [RULES],
    components: {
      RequestForm
    },
    directives: {},
    data(){
      return {
        currentStep: 1,
        totalSteps: 3,
        giro: "",
        idEstablecimiento: "",
        dialog: false,
        formData: {
          request: {},
        }
      };
    },
    mounted() {
      console.log(this.$route.params.idEstablecimiento);
      this.idEstablecimiento = this.$route.params.idEstablecimiento;
    },
    computed: {
      ...mapGetters({
        usuario: 'usuario', id: 'id', contribuyente: 'contribuyente', mini: 'sidebar'
      }),
    },
    watch: {},
    methods: {
      handleFormSubmitted(data: any) {
        this.$set(this.formData, 'request', data);
        this.submitData();
      },
      submitData() {
        router.back();
        //console.log('Enviar datos:', this.formData);
        //router.push({ name: 'index-anuncios', params: {idSolicitud: item.id, establecimiento: item.nombre_comercial}})
      },
    },
  }
)
