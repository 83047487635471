import { DefaultService } from "@/services/DefaultService";
import API from "@/plugins/axios";

class ViewPDFService extends DefaultService {
    constructor() {
        super('');
    }
    viewPDF(url: any, data: any, type: any, name: string): Promise<any>{
        return API.request({
            url: url,
            data: data,
            method: type,
            responseType: 'blob'
        });
    }
}

export default new ViewPDFService;