import { DefaultService } from "@/services/DefaultService";
import API from "@/plugins/axios";

class UsoSueloService extends DefaultService {
  
  constructor() {
    super('constancia_uso_suelo');
  }

  getByEstablecimiento(id: string): Promise<any>{
    return API.get(`constancia_uso_suelo/establecimiento/${id}`);
  }
}

export default new UsoSueloService;