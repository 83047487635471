
import Vue from 'vue';
import EventBus from '../../plugins/event-bus';
import CustomFile from '../Common/CustomFile.vue';
import LicenciaService from '@/services/LicenciaService';
import rules from '@/mixins/rules';

export default Vue.extend({
    name: 'NombreComercial',
    mixins: [rules],
    components: {
        CustomFile,
    },
    props: {
        open: {
            type: Boolean,
            default: false,
        },
        licencia: {
            type: Object,
            default: null as any,
        },
        locacion: {
            type: Object,
            default: null as any,
        }
    },
    data() {
        return {
            disabled: false,
            form: {
                id: '',
                nuevo_nombre_comercial: '',
                documentos: {
                    'scd_identificacion': {
                        code: 'scd_identificacion',
                        file: '',
                        binary: '',
                        data: null,
                    },
                    'scd_constancia': {
                        code: 'scd_constancia',
                        file: '',
                        binary: '',
                        data: null,
                    },
                }
            },
            solicitud: {} as any,
        }
    },
    watch: {
        open() {
            if (this.open == true) {
                this.getData();
            }
        },
    },
    methods: {
        close() {
            this.$emit('closeCambioNombreComercial');
            this.clearForm();
        },
        clearForm() {
            this.disabled = false;
            this.form = {
                id: '',
                nuevo_nombre_comercial: '',
                documentos: {
                    'scd_identificacion': {
                        code: 'scd_identificacion',
                        file: '',
                        binary: '',
                        data: null,
                    },
                    'scd_constancia': {
                        code: 'scd_constancia',
                        file: '',
                        binary: '',
                        data: null,
                    },
                }
            }
        },
        async save() {
            // @ts-ignore
            let validate = this.$refs.form.validate();
            if (validate) {
                this.disabled = true;
    
                const formData = new FormData();
                formData.append('id', this.form.id);
                formData.append('nuevo_nombre_comercial', this.form.nuevo_nombre_comercial);
    
                Object.entries(this.form.documentos).forEach(([key, documento]) => {
                    const baseKey = `files[${key}]`;
                    formData.append(`${baseKey}[file]`, documento.file);
                    formData.append(`${baseKey}[code]`, documento.code);
                });
    
                LicenciaService.cambioNombreComercial(this.licencia.id, formData)
                .then((response) => {
                    if (response.data.success) {
                        this.$toast.success(response.data.message);
                        this.$emit('reloadData');
                        this.getData();
                    } else {
                        this.$toast.error(response.data.message);
                    }
                    this.disabled = false;
                })
                .catch((error) => {
                    this.$toast.error(error.response.data.message);
                    this.disabled = false;
                });
            } else {
                this.$toast.warning('Por favor, completa los campos obligatorios');
            }
        },
        getData() {
            LicenciaService.getCambioNombreComercial(this.licencia.id)
            .then((response) => {
                let solicitud = response.data;
                this.solicitud = solicitud;
                if (solicitud) {
                    this.form.id = solicitud.id;
                    this.form.nuevo_nombre_comercial = solicitud.nuevo_nombre_comercial;
                    /* const identificacion = solicitud.identificacion;
                    this.form.documentos['scd_identificacion'].code = 'scd_identificacion';
                    this.form.documentos['scd_identificacion'].data = identificacion;
                    const constancia = solicitud.constancia;
                    this.form.documentos['scd_constancia'].code = 'scd_constancia';
                    this.form.documentos['scd_constancia'].data = constancia; */
                    if (solicitud.estatus_id == 10) {
                        this.disabled = true;
                    }
                }
            })
        },
        chageEstatusNombreComercial(estatus_id: any) {
            let data = {
                estatus_id: estatus_id,
            }
            LicenciaService.chageEstatusNombreComercial(this.licencia.id, this.form.id, data)
            .then((response) => {
                if (response.data.success) {
                    this.$toast.success(response.data.message);
                    this.getData();
                } else {
                    this.$toast.error(response.data.message);
                }
            })
            .catch((error) => {
                this.$toast.error(error.response.data.message);
            });
        },
        notificarPago(estatus: any) {
            let data = {
                pago_realizado: estatus,
            }
            LicenciaService.notificarPagoRealizadoNombreComercial(this.licencia.id, this.form.id, data)
            .then((response) => {
                if (response.data.success) {
                    this.$toast.success(response.data.message);
                    this.getData();
                } else {
                    this.$toast.error(response.data.message);
                }
            })
            .catch((error) => {
                this.$toast.error(error.response.data.message);
            });
        },
        updateCambioNombreComercial() {
            // @ts-ignore
            let validate = this.$refs.form.validate();
            if (validate) {
                this.disabled = true;
    
                const formData = new FormData();
                formData.append('id', this.form.id);
                formData.append('nuevo_nombre_comercial', this.form.nuevo_nombre_comercial);
    
                Object.entries(this.form.documentos).forEach(([key, documento]) => {
                    const baseKey = `files[${key}]`;
                    formData.append(`${baseKey}[file]`, documento.file);
                    formData.append(`${baseKey}[code]`, documento.code);
                });
    
                LicenciaService.updateCambioNombreComercial(this.licencia.id, this.form.id, formData)
                .then((response) => {
                    if (response.data.success) {
                        this.$toast.success(response.data.message);
                        this.$emit('reloadData');
                        this.getData();
                    } else {
                        this.$toast.error(response.data.message);
                    }
                    this.disabled = false;
                })
                .catch((error) => {
                    this.$toast.error(error.response.data.message);
                    this.disabled = false;
                });
            } else {
                this.$toast.warning('Por favor, completa los campos obligatorios');
            }
        },
        getColor(estatus: any) {
            console.log(estatus);

            switch (estatus) {
                case 1:
                    return 'orange';
                case 4:
                    return 'green';
            }
        },
        getClass(estatus: any) {
            switch (estatus) {
                case 4:
                    return 'mb-2 white--text';
            }
        }
    },
})
