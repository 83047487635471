interface _DireccionExterna {
  id: number | null
  localidad: string | null
  colonia: string | null
  calle: string | null
  cruzamiento1: string | null
  cruzamiento2: string | null
}

class DireccionExterna implements _DireccionExterna {
  id: number | null
  localidad: string | null
  colonia: string | null
  calle: string | null
  cruzamiento1: string | null
  cruzamiento2: string | null

  constructor() {
    this.id = null;
    this.localidad = null;
    this.colonia = null;
    this.calle = null;
    this.cruzamiento1 = null;
    this.cruzamiento2 = null;
  }
}

export { DireccionExterna, _DireccionExterna }