
import Vue from 'vue';
import { VInput } from 'vuetify/lib';
import RULES from '@/mixins/rules';
import { LicenciaConvenio } from '@/models/LicenciaConvenio';
import LicenciaService from '@/services/LicenciaService';
import LicenciaConvenioService from '@/services/LicenciaConvenioService';
import EstablecimientoService from '@/services/EstablecimientoService';

export default Vue.extend(
  {
    props: {
      value2: Object,
      idEstablecimiento: {
        type: Number,
        default: 0,
      },
      index: {
        type: Number,
        default: false,
      },
      object: {
        type: Object,
        required: false,
      },
      disabled: {
        type: Boolean,
        required: false,
      },
      estatus: {
        type: Number,
        default: 1
      }
    },
    mixins: [RULES],
    data: () => ({
      formData: {} as LicenciaConvenio,
      dialogView: false,
      documento: null,
      showInputDocument: false,
      convenios: [] as any
    }),
    watch: {
      formData: {
        handler(newVal) {
          this.update();
        },
        deep: true,
      },
    },
    methods: {
      deleteModal(){
        //this.$emit('delete', this.$attrs.index);
        if (this.object.id != null) {
          LicenciaConvenioService.delete(this.object.id).then((response) => {
            if (response.data.success) {
              this.$emit('delete', this.$attrs.index);
              this.$toast.success('Convenio eliminado exitosamente');
            }else{
              this.$toast.error(response.data.message);
            }
            
          }).catch((error) => {
          });
        }else{
          this.$emit('delete', this.$attrs.index);
        }
        
      },
      changeFile() {
        this.showInputDocument = true;
      },
      async showFile(id:any, tipoArchivo: string) {
        //@ts-ignore
        const response = await EstablecimientoService.descargarFormato(`documentacion/${id}`,{},'GET','Temp');

        let file = new Blob([response.data],{type: response.headers['content-type']});
        const url = window.URL.createObjectURL(file);

        //@ts-ignore
        this.documento = {
          //@ts-ignore
          title: tipoArchivo,
          url: url,
          mime: response.headers['content-type'] 
        }
        this.dialogView = true;
      },
      update() {
        this.$emit("update", this.formData, this.index);
      },
      setDocumento(e: any){
        const fileReader = new FileReader()
        fileReader.addEventListener('load', () => {
          var file = fileReader.result;
          this.formData.documento = file + "";
        });
        fileReader.readAsDataURL(e);
      },
      async getData() {
        await LicenciaService.getConvenio().then((response) => {
            this.convenios = response.data;
        })
      },
      async handleFormSubmit() {        
        this.formData.locacion_id = this.idEstablecimiento;
        let response = await LicenciaConvenioService.create(this.formData).then((response) => {
          if (response.data.success) {
            this.$toast.success('Convenio registrado exitosamente');
            this.$emit('formSubmitted', response.data);
          }else{
            this.$toast.error(response.data.message);
          }
          
        }).catch((error) => {
        });
      },
    },
    components: { VInput },
    computed: {},
    mounted() {
      this.getData();
      this.formData = Object.assign({}, this.object);
    }
  }
);
