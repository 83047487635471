
import Vue from "vue";
import RULES from "@/mixins/rules";
import { Representante } from "@/models/Representante";
import EventBus from "@/plugins/event-bus";
import { VForm } from "@/types/formvalidate";
import CustomFile from "../Common/CustomFile.vue";
import PhoneInput from "../Common/PhoneInput.vue";

export default Vue.extend({
  name: "CardRepresentante",
  components: {
    CustomFile, PhoneInput
  },
  data() {
    return {
      representante: {} as Representante,
    };
  },
  props: {
    object: {
      type: Object,
      required: false,
    },
    type: {
      type: String,
      required: true
    },
    index: {
      type: Number,
      default: false,
    },
    testigos: {
      type: Boolean,
      default: false,
    },
    update: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    form(): VForm {
      return this.$refs.formRepresentante as VForm;
    },
  },
  mounted() {
    this.representante = Object.assign({}, this.object);
    /*EventBus.$on("update-representantes", () => {
      this.updateData();
    });*/
  },
  watch: {
    representante: {
      handler(newVal) {
        this.updateData();
      },
      deep: true,
    },
  },
  methods: {
    handleFileUploadSuccess(data: any) {
      // type FormUserKey = keyof typeof this.formUser;
      const typeFile = data.typeFile;
      const message = data.message;
      //@ts-ignore
      this.representante[typeFile] = null;
      this.$toast.success(message);
    },
    handleFileUploadError (data: any){
      const message = data.message;
      this.$toast.error(message);
    },
    validate() {
      return this.form.validate();
    },
    resetValidation() {
      return this.form.resetValidation();
    },
    removeRepresentante() {
      //call api if item is saved
      this.$emit("delete", this.index);
    },
    updateData() {
      this.$emit("update", this.representante, this.index);
    },
  },
  mixins: [RULES],
  directives: {},
});
