// src/plugins/vex.js

import Vue from 'vue'
// @ts-ignore
import Vex from 'vex-js'
// @ts-ignore
import vexDialog from 'vex-dialog'

// Main css
import 'vex-js/dist/css/vex.css'

// Themes (Import all themes you want to use here)
//import 'vex-js/dist/css/vex-theme-default.css'
import 'vex-js/dist/css/vex-theme-os.css'

import '../assets/custom-styles.css'

// Options
Vex.defaultOptions.className = 'vex-theme-os'

// Register vex-dialog
Vex.registerPlugin(vexDialog)

Vex.dialog.buttons.YES.text = "SI"
Vex.dialog.buttons.NO.text = "NO"

export {Vex}

