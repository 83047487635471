
import Vue from "vue";
import UserService from "@/services/UserService";
import ContribuyenteService from "@/services/ContribuyenteService";
import { mapGetters } from "vuex";
import { Contribuyente } from "@/models/Contribuyente";
import RULES from "@/mixins/rules";
import { VForm } from "@/types/formvalidate";
import Direccion from "@/components/Beneficiarios/Direccion.vue";
import DomicilioExterno from "@/components/Beneficiarios/DireccionExterna.vue";
import { Domicilio } from "@/models/Domicilio";
import { DireccionExterna } from "@/models/DireccionExterna";
import EventBus from "@/plugins/event-bus";
import CardRepresentante from "@/components/Contribuyentes/CardRepresentante.vue";
import { _Representante, Representante } from "@/models/Representante";
import CustomFile from "@/components/Common/CustomFile.vue";
import {Vex} from "@/plugins/vex";
import PhoneInput from "@/components/Common/PhoneInput.vue";
import LoginService from "@/services/LoginService";
import CustomFileNotRequired from "@/components/Common/CustomFileNotRequired.vue";
import contribuyente from "@/store/contribuyente";
//import { jsPDF } from "jspdf";

export default Vue.extend({
  name: "FormUpdateContribuyente",
  components: {
    CustomFileNotRequired,
    Direccion,
    CardRepresentante,
    CustomFile,
    PhoneInput,
    DomicilioExterno
  },
  mixins: [RULES],
  data() {
    return {
      dialog: false,
      dialogSegundaPregunta: false,
      controlRefresh: 0,
      show1: false,
      loading: false,
      editable: false,
      delete_representantes: [] as any,
      delete_tramitadores: [] as any,
      showInputSat: false,
      showInputIne: false,
      showInputActa: false,
      hasRepresentante: false,
      hasTramitador: false,
      formUser: new Contribuyente(),
      formDefault: new Contribuyente(),
      formRepresentantes: [] as Representante[],
      formTramitadores: [] as Representante[],
      terminated: false,
      menos300: '1',
      maneja_alcohol: '1',
      tipos_persona: [
        {
          id: 1,
          text: "Persona física",
        },
        {
          id: 2,
          text: "Persona moral",
        },
      ],
      oldData: [] as any,
    };
  },
  computed: {
    contribuyente() {
      return contribuyente
    },
    form(): VForm {
      return this.$refs.form as VForm;
    },
    formDir(): VForm {
      return this.$refs.formDireccion as VForm;
    },
    formDirExterna(): VForm {
      return this.$refs.formDireccionExterna as VForm;
    },
    ...mapGetters({
      usuario: "usuario",
      userDetail: "user_detail",
      id: "id",
      mini: "sidebar",
    }),
  },
  watch: {
    "formUser.isSameUser": function(newVal){
      if(newVal === true){
        this.formUser.email = this.userDetail.email;
        this.formUser.rfc = this.userDetail.rfc;
        this.buscaRfc();
      }else{
        if(this.terminated){
          this.formUser.email = this.formDefault.email;
          this.formUser.rfc = this.formDefault.rfc;
        }else{
          this.formUser.email = '';
          this.formUser.rfc = "";
        }
      }
    },
    "formUser.tipo_persona": function (newVal) {
      if (newVal == 2) {
        this.hasRepresentante = true;
      } else {
        this.hasTramitador = false;
        this.hasRepresentante = false;
      }
    },
    hasRepresentante: function (newVal) {
      if (newVal === true) {
        if (this.formRepresentantes.length == 0)
          this.formRepresentantes = [new Representante()];
      } else this.formRepresentantes = [];
    },
    hasTramitador: function (newVal) {
      if (newVal === true) {
        if (this.formTramitadores.length == 0)
          this.formTramitadores = [new Representante()];
      } else this.formTramitadores = [];
    },
  },
  mounted() {
    this.getData();
  },
  methods: {
    handleFileUploadSuccess(data: any) {
     // type FormUserKey = keyof typeof this.formUser;
      const typeFile = data.typeFile;
      const message = data.message;
      //@ts-ignore
      this.formUser[typeFile] = null;
      this.$toast.success(message);
    },
    handleFileUploadError (data: any){
      const message = data.message;
      this.$toast.error(message);
    },
    segunda_pregunta(){
      this.formUser.mercado = false;
      if (this.menos300 == '2') {
        this.formUser.tipo_proceso_id = '1';
      }else if(this.menos300 == '1'){
        this.dialogSegundaPregunta = true;
      } else if(this.menos300 == '4'){
        this.formUser.mercado = true;
      } else {
        LoginService.logout().then(response => {
          if(response.data.success){
            this.$store.dispatch('logout');
            this.$router.replace({name: 'FullLogin'});
          }
        });
      }
      this.dialog = false;
    },
    buscaRfc(){
      if (this.formUser.rfc.length == 13) {
        UserService.getRfcData(this.formUser.rfc).then(res => {
          if(res.data.success){
            this.formUser.nombre = res.data.data.nombre;
            this.formUser.apellido1 = res.data.data.ap_paterno;
            this.formUser.apellido2 = res.data.data.ap_materno;
            this.formUser.telefono_movil = res.data.data.telefono;
          }
        })
        if (this.formUser.mercado) {
          this.localMercadoByRFC();
        }
      }
    },
    iniciar_solicitud(){
      if (this.maneja_alcohol == '1') {
        this.formUser.tipo_proceso_id = '1';
      }else{
        this.formUser.tipo_proceso_id = '2';
      }
      this.dialogSegundaPregunta = false;
    },
    async getData() {
      this.formUser.user_id = this.id;
      let { data } = await UserService.get(this.id);
      if (data.contribuyente != null) {
        if (data.contribuyente.estatus == 10) {
          this.terminated = true;
        }
        if (data.contribuyente.estatus == 4) {
          this.terminated = true;
        }
        this.formDefault = data.contribuyente;
        
        if(data.contribuyente.es_externo == false){
          if (data.contribuyente.domicilio.length) {
            data.contribuyente.domicilio[0].codigo_postal = data.contribuyente.domicilio[0].codigo_postal ? data.contribuyente.domicilio[0].codigo_postal.toString() : '';
            this.formDefault.domicilio = data.contribuyente.domicilio[0];
          }
        } else if (data.contribuyente.es_externo == true) {
          this.formDefault.domicilioExterno = data.contribuyente.domicilio_externo[0];
          if (data.contribuyente.domicilio.length) {
            this.formDefault.domicilio = Object.assign(data.contribuyente.domicilio[0], new Domicilio());
          }
        }
        this.formDefault.constancia = data.contribuyente.constancia;
        if (data.contribuyente.representantes.length > 0) {
          this.formRepresentantes = data.contribuyente.representantes;
          this.hasRepresentante = true;
        }
        if (data.contribuyente.tramitadores.length > 0) {
          this.formTramitadores = data.contribuyente.tramitadores;
          this.hasTramitador = true;
        }
      }else{
        //Revisar si es concesionario
        UserService.concesionarioByRFC(this.userDetail.rfc)
        .then((res) => {
          let { data } = res;
          if (!data.data) {
            //this.$toast.warning(`No se encuentra en el padrón de locatarios de mercados`);
            this.menos300 = '1';
            this.dialog = true;
            this.formUser.mercado = false;
            this.dialog = true;
          } else {
            this.formUser.mercado = true;
            this.menos300 = '4';
            this.dialog = false;
            this.formUser.nombre = data.data.nombre;
            this.formUser.rfc = data.data.rfc;
            this.formUser.email = this.userDetail.email;
            this.formUser.isSameUser = true;
            this.formUser.tipo_proceso_id = '1';
            this.$toast.success(`Se encuentra en el padrón de locatarios de mercados`);
          }
        })
        //this.dialog = true;
      }
      
      this.formUser = Object.assign({}, this.formDefault);
      //console.log("user",this.formUser);
      
      //@ts-ignore
      this.formUser.isSameUser = this.formUser.isSameUser === 1;
      this.controlRefresh++;
    },
    editar() {
      this.editable = true;
    },
    cancelar() {
      this.formUser = Object.assign({}, this.formDefault);
      this.editable = false;
    },
    setDomicilio(domicilio: Domicilio) {
      this.formUser.domicilio = Object.assign({}, domicilio);
    },
    setDomicilioExterno(domicilio: DireccionExterna) {
      this.formUser.domicilioExterno = Object.assign({}, domicilio);
    },
    async enviarRevision(){
      Vex.dialog.confirm({
        unsafeMessage: `<h1>¿Desea envíar su información a revisión?</h1><p>No podrá editar la información hasta su validación</p>`,
        callback: async (value: any)  => {
          if (value) {
            if(this.formUser!=null){
              //@ts-ignore
              let { data : { success, data: contribuyente } } = await ContribuyenteService.changeStatus(this.formUser.id, 10);

              if(success){
                this.terminated = true;
                this.$toast.success('Su solicitud ha sido enviada exitosamente');
                //window.location.reload();
                await this.update();
              }else{
                this.$toast.error('Ocurrio un error al enviar su solicitud, intente de nuevo');
              }
            }
          }
        }
      })
    },
    async actualizarPerfil(){
      Vex.dialog.confirm({
        unsafeMessage: `<h1>¿Desea actualizar la información de su perfil, esto podría aumentar el tiempo de revisión de sus solicitudes previas</p>`,
        callback: async (value: any)  => {
          if (value) {
            if(this.formUser!=null){
              //@ts-ignore
              let { data : { success, data: contribuyente } } = await ContribuyenteService.changeStatus(this.formUser.id, 10);

              if(success){
                this.terminated = true;
                this.$toast.success('Su solicitud ha sido aceptada exitosamente');
              }else{
                this.$toast.error('Ocurrio un error al enviar su solicitud, intente de nuevo');
              }
            }
          }
        }
      })
    },
    async update() {
      let validDir;
      let validDirExterna;

      if (this.formRepresentantes.length > 0) {
        //@ts-ignore
        let validateInputs = this.$refs.formRepresentante[0].representante.telefono_movil != null && !this.formUser.telefono_movil != null;
        //@ts-ignore
        console.log(this.$refs.formRepresentante[0].representante.telefono_movil, this.formUser.telefono_movil);
        console.log(validateInputs);
        
        //@ts-ignore
        if(this.$refs.formRepresentante[0].representante.telefono_movil === this.formUser.telefono_movil && validateInputs){
          this.$toast.warning('El número del representante no puede ser el mismo que el del contribuyente');
          return 
        }
      }
      
      if (this.formTramitadores.length > 0) {
        //@ts-ignore
        let validateInputs2 = this.$refs.formTramitador[0].representante.telefono_movil != null && this.formUser.telefono_movil != null;
        console.log(validateInputs2);
        
        //@ts-ignore
        if(this.$refs.formTramitador[0].representante.telefono_movil === this.formUser.telefono_movil && validateInputs2){
          this.$toast.warning('El número del tramitador no puede ser el mismo que el del contribuyente');
          return 
        }
      }

      EventBus.$emit("update-direccion");

      if(!this.formUser.es_externo){
        //validDir = this.formDir.validate();
      } else if(this.formUser.es_externo) {
        //validDirExterna = this.formDirExterna.validate();
        validDirExterna = true;
      }

      let validComponents = true;
      
      for (
        let index: number = 0;
        index < this.formRepresentantes.length;
        index++
      ) {
        //@ts-ignore
        let isValid = this.$refs.formRepresentante[index].validate();
        if(!isValid)
          validComponents = false;
      }

      for (
        let index: number = 0;
        index < this.formTramitadores.length;
        index++
      ) {
        //@ts-ignore
        let isValid = this.$refs.formTramitador[index].validate();
        if(!isValid)
          validComponents = false;
      }
      
      if (this.form.validate() && validComponents) {
        EventBus.$emit("update-representantes");
        this.formUser.representantes = Object.assign(
          {},
          this.formRepresentantes
        );
        this.formUser.tramitadores = Object.assign({}, this.formTramitadores);

        let formdata = Object.assign({}, this.formUser);

        formdata.user_id = this.id;
        let data_pre = null;

        let msgSuccess = "Perfil actualizado exitosamente";
        if (this.formUser.id != null) {
          data_pre = await ContribuyenteService.update(
            this.formUser.id,
            formdata
          );
        } else {
          data_pre = await ContribuyenteService.create(formdata);
          msgSuccess = "Perfil creado correctamente";
        }

        let { data: { success, message } } = data_pre;

        if (success) {

          /*this.formUser.id = contribuyente.id
          this.formUser.domicilio.id = contribuyente.domicilio.id;*/
          
          this.$toast.success(msgSuccess);
          await this.$store.dispatch("updateUser", this.formUser.nombre);
          location.reload();
          // await this.$store.dispatch("updateContribuyente", this.formUser);
          //this.formDefault = Object.assign({}, this.formUser);
        } else {
          this.$toast.warning(message);
        }
      }else{
        this.$toast.error("Complete los campos marcados en el formulario");
      }
    },
    incrementCardRepresentante() {
      // @ts-ignore

      if (
        this.formUser.tipo_persona === 1 &&
        this.formRepresentantes.length < 3
      ) {
        // @ts-ignore
        this.formRepresentantes.push(new Representante());
      } else if (
        this.formUser.tipo_persona === 2 &&
        this.formRepresentantes.length < 1
      ) {
        // @ts-ignore
        this.formRepresentantes.push(new Representante());
      } else {
        //this.$toast.warning(`IMPOSIBLE AGREGAR MÁS REPRESENTANTES`);
      }
    },
    setRepresentante(representante: Representante, index: any) {
      //@ts-ignore
      representante.tipo = 'R';
      this.formRepresentantes[index] = Object.assign({}, representante);
    },
    deleteRepresentante(index: number) {
      // devuelve el indice del arreglo empezado  la inicialización siempre en
      if (this.formRepresentantes.length > 1)
        this.formRepresentantes.splice(index, 1);
      else this.$toast.warning(`Debe registrar por lo menos un representante`);
    },
    incrementCardTramitador() {
      // @ts-ignore
      if (
        this.formUser.tipo_persona === 2 &&
        this.formTramitadores.length <= 1
      ) {
        // @ts-ignore
        this.formTramitadores.push(new Representante());
      } else {
        this.$toast.warning(`IMPOSIBLE AGREGAR MÁS TRAMITADORES`);
      }
    },
    setTramitador(item: Representante, index: any) {
      //@ts-ignore
      item.tipo = 'T';
      this.formTramitadores[index] = Object.assign({}, item);
    },
    deleteTramitador(index: number) {
      // devuelve el indice del arreglo empezado  la inicialización siempre en
      if (this.formTramitadores.length > 1)
        this.formTramitadores.splice(index, 1);
      else this.$toast.warning(`Debe registrar por lo menos un representante`);
    },
    DowloadPdf() {
      window.open("http://municipio-licencias-be.test/api/testpdf", "_blank");
      //const doc = new jsPDF();
      //doc.text("Hello world!", 10, 10);
      //doc.save("a4.pdf"); //
    },
    localMercadoByRFC() {
      
    }
  },
});
