
import Vue from "vue";
import {VForm} from "@/types/formvalidate";
import { DireccionExterna } from "@/models/DireccionExterna";
import RULES from "@/mixins/rules";
import EventBus from "@/plugins/event-bus";

export default Vue.extend({
  name: "DireccionExterna",
  props: {
    domicilio: {
      type: DireccionExterna,
    },
    disabled: {
      type: Boolean,
      default: false
    },
    original: {
      type: DireccionExterna,
    }
  },
  mixins: [RULES],
  data: () => ({
    formDom: new DireccionExterna()
  }),
  computed: {
    form(): VForm {
      return this.$refs.formDireccionExterna as VForm
    },
  },
  mounted() {
    EventBus.$on('update-direccion', () => {
      this.update();
    });
  },
  watch: {
    formDom: {
      handler(val) {
        if (val) {
          val.localidad = val.localidad ? val.localidad.toUpperCase() : '';
          val.colonia = val.colonia ? val.colonia.toUpperCase() : '';
          val.calle = val.calle ? val.calle.toUpperCase() : '';
          val.cruzamiento1 = val.cruzamiento1 ? val.cruzamiento1.toUpperCase() : '';
          val.cruzamiento2 = val.cruzamiento2 ? val.cruzamiento2.toUpperCase() : '';
        }
      },
      deep: true,
    },
    domicilio: {
      immediate: true,
      deep: true,
      handler(val) {
        console.log(this.domicilio)
        this.asignar();
      }
    }
  },
  methods: {
    update() {
      this.$emit('update',this.formDom)
    },
    validate() {
      return this.form.validate();
    },
    asignar() {
      this.formDom.id = this.domicilio.id;
      this.formDom.localidad = this.domicilio.localidad;
      this.formDom.colonia = this.domicilio.colonia;
      this.formDom.calle = this.domicilio.calle;
      if (this.domicilio.cruzamiento1) {
        this.formDom.cruzamiento1 = this.domicilio.cruzamiento1;
        
      }
      if (this.domicilio.cruzamiento2) {
        this.formDom.cruzamiento2 = this.domicilio.cruzamiento2;
        
      }
    }
  }
});
