
import Vue from "vue";
import LoginService from '@/services/LoginService';
import {mapGetters} from "vuex";
import CustomViewPDF from './CustomViewPDF.vue';
import RULES from '@/mixins/rules';
import ContribuyenteService from "@/services/ContribuyenteService";

export default Vue.extend({
    name: 'Header',
    mixins: [RULES],
    directives: {},
    components: {CustomViewPDF},
    props: {
        contribuyente: Object
    },
    data: () => ({
        dialog: false,
        notificaciones: [],
        totalNotificaciones: null,
        formNotificacion: {
            message: '',
            areaId: 0,
            establecimientoId: 0,
            rfc: '',
            nombreContribuyente: '',
        },
        dialogNotificacion: false,
        contribuyenteAreaId: 0,
        areas: [
            { nombre: 'Departamento de Licencias', id: 20 },
            { nombre: 'Dirección de Desarrollo Urbano, Medio Ambiente y Ecología', id: 6 },
            { nombre: 'Dirección de Protección civil', id: 7 },
        ],
        establecimientos: [],
    }),
    created() {
    },
    mounted() {
        this.getNotifications();
    },
    computed: {
        ...mapGetters({
            id: 'id',
        })
    },
    watch: {
        dialog: function(value) {
            if(value){
                this.getNotifications();
            }
        },
    },
    methods: {
        getAreaId(areaId: number) {
            const area = this.areas.find(area => area.id === areaId);
            return area ? area.id : 0;
        },
        cerrarNotify() {
            this.dialog = false;
        },
        leerNotify(notify: any, index: number) {
            this.notificaciones.splice(index, 1);
            //@ts-ignore
            --this.totalNotificaciones;
            LoginService.readNotification(this.id, notify.id).then(response => {
            });
        },
        getNotifications() {
            this.getContribuyente();
            LoginService.notificaciones(this.id).then((response) => {
                this.notificaciones = response.data;
            }) 
        },
        async getContribuyente() {
            if (this.contribuyente) {
                await ContribuyenteService.get(this.contribuyente.id).then((response) => {
                    const tipoProcesoId = response.data.contribuyente.tipo_proceso_id;
                    this.establecimientos = response.data.contribuyente.locacion;
                    
                    if (tipoProcesoId === 2) {
                        this.areas = this.areas.map(area => {
                            if (area.nombre === 'Departamento de Licencias') {
                                return { ...area, nombre: 'Coordinación Sare', id: 20 };
                            }
                            return area;
                        });
                    }
                })
            }
        },
        sendNotification() {
            // 20 licencias, 6 usoSuelo, 7 anuencia, 20 residuosSolidos
            const areaId = this.getAreaId(this.formNotificacion.areaId);

            if(!this.$store.getters.contribuyente){
                this.$toast.error('Para enviar una notificación, primero debe crear su perfil de contribuyente y enviar a revisión.');
                return;
            }
            
            this.formNotificacion.rfc = this.$store.getters.contribuyente.rfc;
            this.formNotificacion.nombreContribuyente = this.$store.getters.contribuyente.nombre_completo;
            if (areaId !== 0) {
                LoginService.sendNotification(areaId, this.formNotificacion).then((response) => {
                    if (response.data.success) {
                        this.dialogNotificacion = false;
                        this.formNotificacion = {
                            message: '',
                            areaId: 0,
                            establecimientoId: 0,
                            rfc: '',
                            nombreContribuyente: '',
                        };
                        this.$toast.success(response.data.message);
                    } else {
                        this.$toast.error(response.data.message);
                    }
                })
            } else {
                this.$toast.error('Por favor, llene todos los campos');
            }
        }

    }
});
