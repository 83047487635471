
import Vue from 'vue';
import { VInput } from 'vuetify/lib';
import { Anuncio } from '@/models/Anuncio';
import AnuncioService from '@/services/AnuncioService';
import MaterialService from '@/services/MaterialService';
import TipoAnuncioService from '@/services/TipoAnuncioService';
import RULES from '@/mixins/rules';
import EstablecimientoService from '@/services/EstablecimientoService';
import CustomFile from "@/components/Common/CustomFile.vue";
import CustomFileInputAnuncio from "@/components/Common/CustomFileAnuncio.vue";

export default Vue.extend(
  {
    props: {
      value2: Object,
      idEstablecimiento: {
        type: Number,
        default: 0,
      },
      isNotSubmit: {
        type: Boolean,
        default:true
      },
      object: {
        type: Anuncio,
        default: new Anuncio()
      },
      estatus: {
        type: Number,
        default: 1
      },
      update: {
        type: Boolean,
        default: false
      },
    },
    mixins: [RULES],
    data: () => ({
      uploadPercentage: 0,
      defaultLatitud: 18.520326,
      defaultLongitud: -88.305718,
      menu: false,
      dialog: false,
      menu_fin: false,
      loading: false,
      editable: false,
      formData: new Anuncio(),
      disabled: false,
      disabledForm: {
        localidad: true,
        asentamiento: true,
      },
      materiales: [] as any[],
      tiposAnuncios: [] as any[],
      documento: null,
      showFotografia: false,
      showMemoria: false,
      showResponsiva: false,
      dialogSendReview: false,
      selectedSolicitud: new Anuncio() as any,
      dialogPDF: false,
      usePiezaDia: false,
      usePiezaMes: false,
      usePieza: false,
      fileInputValue: null,
      selectedOption: null,
    }),
    watch: {
      object:{
        immediate: true,
        deep: true,
        handler(d){
          this.formData = this.object;
          this.selectedSolicitud = this.object;

          if(this.estatus == 10 || this.estatus == 5) {
            this.disabled = true;
          }
          
          if (this.formData.is_mecanismo_electrico === 1) {
            //@ts-ignore
            this.selectedOption = true;
          } else if (this.formData.is_accion_viento === 1) {
            //@ts-ignore
            this.selectedOption = false;
          }

        }
      },
      'formData.tipo_anuncio_id': {
        immediate: true,
        handler(value) {
          const piezaDiaAnuncios = [7];
          const piezaMesAnuncios = [1, 8, 10];
          const piezaAnuncio = [4, 5]
          
          this.usePiezaDia = piezaDiaAnuncios.includes(value);
          this.usePiezaMes = piezaMesAnuncios.includes(value) && !piezaDiaAnuncios.includes(value);
          this.usePieza = piezaAnuncio.includes(value);
        }
      },
      'selectedOption': {
        handler(value) {
          if (value === true) {
            this.formData.is_mecanismo_electrico = true;
            this.formData.is_accion_viento = false;
          } else if (value === false) {
            this.formData.is_mecanismo_electrico = false;
            this.formData.is_accion_viento = true;
          }
        }
      }
    },
    methods: {
      cancelarEnvio() {
        this.selectedSolicitud = null;
        this.dialogSendReview = false;
      },
      async showFile(id: any){
        //@ts-ignore
        const response = await EstablecimientoService.descargarFormato(`documentacion/${id}`,{},'GET','Temp');

        let file = new Blob([response.data],{type: response.headers['content-type']});
        const url = window.URL.createObjectURL(file);                

        //@ts-ignore
        this.documento = {
            //@ts-ignore
            title: this.label,
            url: url,
            mime: response.headers['content-type'] 
        }
        this.dialog = true;
      },
      setFotografia(e: any){
        const fileReader = new FileReader()
        fileReader.addEventListener('load', () => {
          var file = fileReader.result;
          this.formData.fotografia = file + "";
        });
        fileReader.readAsDataURL(e);
      },
      setAnteriorPermiso(e: any) {
        const fileReader = new FileReader()
        fileReader.addEventListener('load', () => {
          var file = fileReader.result;
          this.formData.anterior_permiso_anuncio = file + "";
        });
        fileReader.readAsDataURL(e);
      },
      clearFotografia() {
        //@ts-ignore
        //this.$refs.inputFoto.reset();
        this.formData.fotografia = null; // Esto debería borrar el archivo seleccionado
      },
      setMemoriaEstructural(e: any){
        const fileReader = new FileReader()
        fileReader.addEventListener('load', () => {
          var file = fileReader.result;
          this.formData.espectacular = 1;
          this.formData.memoria_calculo_estructural = file + "";
        });
        fileReader.readAsDataURL(e);
      },
      setResponsivaTerceros(e: any){
        const fileReader = new FileReader()
        this.formData.espectacular = 1;
        fileReader.addEventListener('load', () => {
          var file = fileReader.result;
          this.formData.responsiva_terceros = file + "";
        });
        fileReader.readAsDataURL(e);
      },
      async handleFormSubmit() {
        this.formData.locacion_id = this.idEstablecimiento;

        await AnuncioService.create(this.formData)
        .then((response) => {
            if (response.data.success) {
              //@ts-ignore
              this.fileInputValue = null;
              this.$toast.success('Anuncio registrado exitosamente');
              this.$emit('formSubmitted', response.data);
            } else {
              this.$toast.error(response.data.message);
            }
            
          })
          .catch((error) => {
            this.disabled = false;
            this.$toast.error(error.response.data.message);
          });
      },
      async handleFormUpdateSubmit() {

        //this.disabled = false;
        Object.keys(this.formData).forEach((key) => {
          const value = this.formData[key];
        });
        this.formData.locacion_id = this.idEstablecimiento;
        let response = await AnuncioService.update(this.formData.id,this.formData).then((response) => {
          if (response.data.success) {
            this.$toast.success('Anuncio registrado exitosamente');
            this.$emit('formSubmitted', response.data);
          }else{
            this.$toast.error(response.data.message);
          }

        }).catch((error) => {
          this.disabled = false;
        });
      },
      async getData() {
        await MaterialService.getAll().then((response) => {
          this.materiales = response.data;
        });

        await TipoAnuncioService.getAll()
          .then((response) => {
            this.tiposAnuncios = response.data;
          })
      },
      deleteModal(){
        //this.$emit('delete', this.$attrs.index);
        if (this.object.id != null) {
          AnuncioService.delete(this.object.id).then((response) => {
            if (response.data.success) {
              this.$emit('delete', this.$attrs.index);
              this.$toast.success('Anuncio eliminado exitosamente');
            }else{
              this.$toast.error(response.data.message);
            }
            
          }).catch((error) => {
            this.disabled = false;
          });
        }else{
          this.$emit('delete', this.$attrs.index);
        }
        
      },
      changeFile(type: any) {
        switch (type) {
          case 'fotografia':
            this.showFotografia = true;
            break;
          case 'memoria':
            this.showMemoria = true;
            break;
          case 'responsiva':
            this.showResponsiva = true;
            break;
        }
      },
    },
    components: {CustomFileInputAnuncio, CustomFile, VInput },
    computed: {},
    mounted() {
      this.getData();

    }
  }
);
