
import Vue from 'vue'
import RULES from "@/mixins/rules"
import { VForm } from "@/types/formvalidate";
import EventBus from "@/plugins/event-bus";
import EstablecimientoService from '@/services/EstablecimientoService';
import numeric from "@/directives/numeric";

export interface Documento{
    id: number,
    title: string,
    url: string,
    mime: string,
    binary: any
}

export default Vue.extend({
    name: "CustomFileInputAnuncio",
    components: {
    },
    mixins: [RULES],
    props: {
        code: String,
        file: Object || null,

        update: {
            type: Boolean,
            default: false
        },
        disabled: {
            type: Boolean,
            default: false
        },
        accept: {
            type: String,
            default: '.pdf'
        },
        icon: {
            type: String,
            default: 'file.jpg'
        },
        loading: {
            type: Boolean,
            default: false,
        },
        customProgress: {
            type: Boolean,
            default: false
        },
        progress: {
            type: Number,
            default: 0,
        },
        multipart: {
            type: Boolean,
            default: true
        },
        requiredArchivo: {
            type: Boolean,
            default: true
        },
        renovacion: {
            type: Number,
            default: 0,
        }
    },
    data() {
        return {
            documento: Document || null,
            binary: '',
            dialog: false,
            show: false,
            fileDeleted: false,
            tipoArchivos: [
                {
                    code: 'fotografia_anuncio',
                    description: 'Fotografía'
                },
                {
                    code: 'memoria_calculo',
                    description: 'Memoria de cálculo estructura'
                },
                {
                    code: 'responsiva_terceros',
                    description: 'Carta responsiva de daño a terceros'
                },

            ],
            filesNotRequired: [
                "testigo1",
                "testigo2",
                "licencia",
                "constancia",
                "identificacion",
                "acta_constitutiva",
                "poder_notarial",
                "carta_poder",
                "constancia_donativo",
                "constancia_uso_suelo",
                "titulo_escritura",
                "arrendamiento_contrato",
                "arrendamiento_identificacion",
                "arrendador_identificacion",
                "arrendamiento_testigos",
                "pago_licencia_bebidas",
                "licencia_comodato",
                "impuesto_hospedaje",
                "dictamen_ifeqroo",
                "licencia_construccion",
                "aviso_apertura_sucursal",
                "frente",
                "interior",
                "wc",
                "lateralIzquierdo",
                "lateralDerecho",
                'documento_anexo'
            ],
            typesImageAllowed: [
                "image/jpeg",
                "image/png",
                "image/jpg"
            ],
          labelType:[
            "frente",
            "interior",
            "wc",
            "lateralIzquierdo",
            "lateralDerecho",
          ]
        }
    },
    computed: {
        form(): VForm {
            return this.$refs.form2 as VForm;
        },
        showInput(): boolean {
            if(!this.update)
                return true;
            else if(this.file == null || this.show)
                return true
            else 
                return false
        },
        label(): string {
            if(this.code != ""){
                //@ts-ignore
                const tipoArchivo = this.tipoArchivos.find((item: any) => {
                    //@ts-ignore
                    return item.code == this.code
                });
                return tipoArchivo?.description ?? '';
            }else
            return '';
            
        },
        requiredFile(): boolean {
            if (this.filesNotRequired.includes(this.code)) {
                return false
            } else {
                return true
            }
        }
    },
    watch: {
        renovacion:{
            immediate: true,
            deep: true,
            handler(d){
                if (this.renovacion == 1) {
                    this.filesNotRequired.push('frente');
                }
            }
        },
    },
    mounted() {
      console.log("entra");
        if (this.renovacion == 1) {
            this.filesNotRequired.push('frente');
        }
    },
    methods: {
        getDataFile(file: any): any {
            return new Promise((resolve, reject) => {
                const fileReader = new FileReader();
                fileReader.addEventListener("load", () => {
                var file = fileReader.result;
                resolve(file);
                });
                fileReader.readAsDataURL(file);
            });
        },
        async setDocument(e: any) {
          if(e != null){

            if(this.labelType.includes(this.code)) {
              if (!this.typesImageAllowed.includes(e.type)) {
                this.$emit('showErrorSnackbar', 'El archivo debe ser una imagen (png, jpg, jpeg).');
                this.binary = '';
                this.$emit('input', this.binary);
                this.clearFileInput();
              }
            }
            if(!this.multipart) {
              this.binary = await this.getDataFile(e);
            } else {
              this.binary = e;
            }

          } else {
            this.binary = '';
          }

          this.$emit('input', this.binary);

        },
        reset() {
          // Reiniciar el valor del input a una cadena vacía
          //@ts-ignore
          this.file = '';

          // Emitir un evento para notificar que el input ha sido reseteado
        },
        clearFileInput() {
          if (this.$refs.form2 && (this.$refs.form2 as any).reset) {
            (this.$refs.form2 as any).reset();
          }
        },
        async showFile(){
            //@ts-ignore
            if (this.file != null){

                //@ts-ignore
                const response = await EstablecimientoService.descargarFormato(`documentacion/${this.file.id}`,{},'GET','Temp');

                let file = new Blob([response.data],{type: response.headers['content-type']});
                const url = window.URL.createObjectURL(file);                

                //@ts-ignore
                this.documento = {
                    //@ts-ignore
                    title: this.label,
                    url: url,
                    mime: response.headers['content-type'] 
                }
                this.dialog = true;
            } 
        },
        changeFile() {
            this.show = true;
        },
        deleteFile() {
          try {
            EstablecimientoService.deleteFiles(this.file.id).then((response:any) => {
              if (response.data.success) {
                this.fileDeleted = true;
                this.$emit('file-upload-success', {message : response.data.message , typeFile: this.file.tipo_archivo});
              }else{
                this.$emit('file-upload-error', {message : response.data.message});
              }
            });
          } catch (error) {
            console.error("Error al eliminar foto:", error);
          }

        }
    }

});

