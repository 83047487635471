import API from '@/plugins/axios'
import store from '@/store/index'

class LoginService {
    signup(data: any): Promise<any>{
        return API.post(`/signup`, data);
    }   
    login(data: {email: string, password: string}): Promise<any> {
        return API.post(`/login`, data);
    }
    updateNip(id: any, data: {old_password: string, password: string, c_password: string}): Promise<any> {
        return API.post(`/profile/${id}/password`, data);
    }
    logout(): Promise<any>{
        return API.post(`/logout/${store.state.id}`);
    }

    register(data: any): Promise<any> {
        return API.post(`/register`, data);
    }

    changePassword(id: any, data: any): Promise<any> {
        return API.post(`/changepassword/${id}`, data);
    }

    notificaciones(id: any): Promise<any> {
        return API.get(`/notificaciones/${id}`);
    }

    readNotification(id: any, notificacion_id: any): Promise<any> {
        return API.post(`/notificacion/${id}/${notificacion_id}`);
    }

    sendNotification(roleId: number, data: any): Promise<any> {
        return API.post(`/contribuyente/sendNotification/${roleId}`, data);
    }

    sendEmail(email: any): Promise<any> {
        return API.post(`/sendResetPassword/${email}`);
    }
    
    newPassword(id: any, data: any, type: any): Promise<any> {
        return API.post(`/newPassword/${id}/${type}`, data);
    }
}

export default new LoginService();