
import Vue from 'vue';
import EventBus from '@/plugins/event-bus';
import HorarioService from '@/services/HorarioService';
import { Horario } from '@/models/Horario';
import LicenciaService from '@/services/LicenciaService';
import rules from '@/mixins/rules';
import { VForm } from '@/types/formvalidate';

export default Vue.extend({
    mixins: [rules],
    name: 'Extraordinario',
    props: {
        selectedLicencia: {
            type: Object,
        },
        open:{
            type: Boolean,
            default: false,
        },
        actualizar:{
            type: Boolean,
            default: false,
        },
        solicitud:{
            type: Object,
        },
        tipo: {
            type: String,
            default: 'EXTRA'
        }
    },
    computed: {
        form(): VForm {
            return this.$refs.form as VForm
        }
    },
    watch: {
        selectedLicencia: {
            handler(newVal){
                this.licencia = this.selectedLicencia;
            }
        },
        actualizar(newVal){
            this.findSolicitudExtraordinaria();
        },
        solicitud(newValue: any) {
            if (newValue) {
                if (newValue.estatus_fiscalizacion == 4) {
                    this.estatus_fiscalizacion = 4;
                    this.disabled = true;
                }
            }
        },
        open(value){
            if (this.open && !this.actualizar) {
                this.getHorarioExtraordinario();
            }
        }
    },
    mounted(){
        this.licencia = this.selectedLicencia;
        this.getEstablecimientos();
    },
    data() {
        return {
            horario: null as any,
            menu: false,
            disabled: false,
            horarios : [] as Horario[],
            licencia: null as any,
            deteleHorarios: [] as any[],
            duracionHoras: [] as number[],
            establecimientos: [] as any[],
            establecimiento_id: null as any,
            estatus_fiscalizacion: null as any,
            cantidad_personas: null as any,
        }
    },
    methods: {
        allowedDates(val: any){
            let existeHorario = this.horarios.find((h: any) => h.fecha === val);
            if (this.tipo == 'EXTRA') {
                let horario = this.buscarHorarios(val);
                return horario.horas.length > 0 && !existeHorario;
            } else if (this.tipo == 'VARIEDAD') {
                return !existeHorario;
            }
        },
        getHorarioExtraordinario(establecimiento_id: any = null){
            if (establecimiento_id) {
                this.establecimiento_id = establecimiento_id;
            }
            this.horarios = [];
            HorarioService.getHorarioExtraordinario(this.licencia.id)
            .then((response) => {
                this.horario = response.data;
                if (!this.actualizar) {
                    this.horarios.push(new Horario());
                }
            });
        },
        close(){
            this.deteleHorarios = [];
            this.horarios = [];
            this.establecimiento_id = null;
            this.estatus_fiscalizacion = null;
            this.cantidad_personas = null;
            this.horario = null;
            EventBus.$emit("close-extraordinario");
        },
        addHorario(){
            this.horarios.push(new Horario());
        },
        deleteHorario(index: any){
            if (this.horarios[index].id != 0) {
                this.deteleHorarios.push(this.horarios[index].id);
            }
            this.horarios.splice(index, 1);
        },
        buscarHorarios(fecha: any){
            let dia = this.obtenerDiaSemana(fecha);
            let horarios = this.horario.horario_extraordinario.horarios.find((horario: any) => horario.dia.nombre === dia);
            if (horarios === undefined) {
                return {
                    horas: [],
                    dia: dia,
                };
            }
            let horaIncio = horarios.segmento_horario.hora_inicio;
            let horaFin = horarios.segmento_horario.hora_fin;
            let horas = this.generarArregloHoras(horaIncio, horaFin);
            return {
                horas: horas,
                dia: dia,
            };
        },
        generarArregloHoras(horaInicio: any, horaFin: any) {
            const [horaInicioH, minutoInicio, segundoInicio] = horaInicio.split(':').map(Number);
            const [horaFinH, minutoFin, segundoFin] = horaFin.split(':').map(Number);

            const arregloHoras = [];
            let horaActual = new Date(2024, 0, 1, horaInicioH, minutoInicio, segundoInicio);

            while (
                horaActual.getHours() !== horaFinH ||
                horaActual.getMinutes() !== minutoFin ||
                horaActual.getSeconds() !== segundoFin
            ) {
                arregloHoras.push(
                horaActual.toLocaleTimeString('en-US', {
                    hour: '2-digit',
                    minute: '2-digit',
                    second: '2-digit',
                    hour12: false // Formato de 24 horas
                })
                );
                horaActual.setHours(horaActual.getHours() + 1);
            }

            // Agrega la última hora (horaFin)
            arregloHoras.push(
                horaActual.toLocaleTimeString('en-US', {
                hour: '2-digit',
                minute: '2-digit',
                second: '2-digit',
                hour12: false // Formato de 24 horas
                })
            );

            return arregloHoras.map(hora => (hora === '24:00:00' ? '00:00:00' : hora));
        },
        obtenerDiaSemana(fecha: any) {
            let diasSemana = ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado'];
            let fechaObj = new Date(fecha + ' 00:00:00');
            let numeroDiaSemana = fechaObj.getDay();
            let nombreDiaSemana = diasSemana[numeroDiaSemana];
            return nombreDiaSemana;
        },
        save(){
            this.disabled = true;
            if (!this.form.validate()) {
                this.$toast.error('Complete la información');
                this.disabled = false;
                return;
            }
            LicenciaService.saveSolicitudFiscalizacion(this.selectedLicencia.id, {
                horarios: this.horarios,
                establecimiento_id: this.establecimiento_id,
                tipo: this.tipo,
                cantidad_personas: this.cantidad_personas,
            })
            .then((response) => {
                if (response.data.success) {
                    this.disabled = false;
                    this.$toast.success(response.data.message);
                    EventBus.$emit("save-extraordinario");
                    this.close();
                } else {
                    this.disabled = false;
                    this.$toast.error(response.data.message);
                }
            })
            .catch((error) => {
                this.disabled = false;
                this.$toast.error(error.response.data.message);
            })
        },
        async findSolicitudExtraordinaria(){
            if (this.actualizar && this.solicitud) {
                await LicenciaService.findSolicitudExtraordinaria(this.licencia.id, this.solicitud.id)
                .then(async (response) => {
                    this.establecimiento_id = this.solicitud.establecimiento_id;
                    if (response.data.tipo == 'VARIEDAD') {
                        this.cantidad_personas = response.data.cantidad_personas;
                    }
                    await HorarioService.getHorarioExtraordinario(this.licencia.id)
                    .then((response) => {
                        this.horario = response.data;
                    });
                    this.horarios = response.data.solicitud_detalles;
                })
                .catch((error) => {
                    console.log(error.response.data);
                })
            }
        },
        async update() {
            this.disabled = true;
            await LicenciaService.actualizarSolicitudFiscalizacion(this.licencia.id, this.solicitud.id, {
                horarios: this.horarios,
                delete_horarios: this.deteleHorarios,
                establecimiento_id: this.establecimiento_id,
                tipo: this.tipo,
                cantidad_personas: this.cantidad_personas,
            })
            .then((response) => {
                if (response.data.success) {
                    /* this.getHorarioExtraordinario(); */
                    /* this.findSolicitudExtraordinaria(); */
                    this.$toast.success(response.data.message);
                    this.close();
                } else {
                    this.$toast.error(response.data.message);
                }
                this.disabled = false;
            })
            .catch((error) => {
                this.$toast.error(error.response.data.message);
                this.disabled = false;
            })
        },
        calcularDuracion(index: number) {
            if (this.horarios[index].hora_inicio == this.horarios[index].hora_fin) {
                this.horarios[index].horas_solicitadas = 0;
                this.horarios[index].hora_fin = null;
                this.$toast.error('Las horas de inicio y fin no pueden ser iguales.');
                return;
            }
            const horaInicio = new Date(`2024-01-01 ${this.horarios[index].hora_inicio}`);
            const horaFin = new Date(`2024-01-01 ${this.horarios[index].hora_fin}`);

            if (horaFin < horaInicio) {
                horaFin.setDate(horaFin.getDate() + 1);
            }

            const diferenciaMillis = horaFin.getTime() - horaInicio.getTime();

            const diferenciaHoras = diferenciaMillis / (1000 * 60 * 60);

            this.horarios[index].horas_solicitadas = diferenciaHoras;
        },
        getEstablecimientos(){
            HorarioService.getEstablecimientos()
            .then((response) => {
                this.establecimientos = response.data;
            })
            .catch((error) => {
                console.log(error.response.data);
            })
        },
    }
});
