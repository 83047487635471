
import Vue from 'vue';
import EstablecimientoService from '@/services/EstablecimientoService';

export default Vue.extend({
    name: 'AlertasPredial',
    props: {
        dialogClaveCatastral: {
            type: Boolean,
            default: false
        },
        dialogPredial: {
            type: Boolean,
            default: false
        },
        claveCatastral: {
            type: String,
            default: ''
        }
    },
    data() {
        return {
            dialogView: false,
            documento: {} as any,
        }
    },
    methods: {
        closeDialogClaveCatastral(accept: boolean) {
            this.$emit('closeDialogClaveCatastral', accept);
        },
        closeDialogPredial() {
            this.$emit('closeDialogPredial');
        },
        pagarPredialLinea(){
            window.open('http://sistemas.opb.gob.mx/epredialopb/', '_blank')
        },
        async generarPagoPredial(){
            await EstablecimientoService.newRequest(`licencia/${this.claveCatastral}/generatePagoPredial`,{},'POST','Temp')
            .then((response) => {
                let file = new Blob([response.data],{type: response.headers['content-type']});
                const url = window.URL.createObjectURL(file);
                const tipoArchivo = 'Estado de cuenta.';
                this.closeDialogPredial();
                this.documento = {
                    title: tipoArchivo,
                    url: url,
                    mime: response.headers['content-type'] 
                }
                this.dialogView = true;
            })
        },
    }
})
