
import EstablecimientoService from "@/services/EstablecimientoService";
import CustomFile from "@/components/Common/CustomFile.vue";
import UserService from "@/services/UserService";
import {mapGetters} from "vuex";
import moment from 'moment';
import 'moment/locale/es';
import Vue from 'vue'

export default Vue.extend({
  name: 'MyDocuments',
  components: {CustomFile},
  data: () => ({
    hasDocuments: false,
    Contribuyente: [],
    Establecimientos: [],
  }),
  computed: {
    ...mapGetters({
      usuario: "usuario",
      id: "id",
      contribuyente: "contribuyente",
      mini: "sidebar",
    }),
  },
  mounted() {
    if(this.contribuyente){
      this.getDatosContribuyente();
      this.getEstablecimientos();
    }
  },
  methods: {
    async getDatosContribuyente() {
      let {data} = await UserService.get(this.id);
      this.Contribuyente = data;
    },
    async getEstablecimientos() {
      const {data} = await EstablecimientoService.getByContribuyenteOriginal(this.contribuyente.id);
      this.Establecimientos = data;
    },
    formattedDate(fecha: string): string {
      moment.locale('es');
      const fechaSinTiempo = fecha.split(' ')[0];
      return moment(fechaSinTiempo, 'YYYY-MM-DD').format("dddd D [de] MMMM [del] YYYY");
    },
  },
  filters: {
    formatoFecha(value: any) {
      return value.split(' ')[0];
    }
  }
})
