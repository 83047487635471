
import Vue from "vue";
import ViewPDFService from '@/services/ViewPDFService';

export default Vue.extend({
    name: 'CustomViewPDF',
    props: {
        link: {
            type: String,
        },
    },
    data(){
        return {
            dialog: false,
            documento: null,
        }
    },
    watch: {
        dialog(){
            if(this.dialog){
                this.getPDF()
            } else {
                this.documento = null
            }
        }
    },
    methods: {
        getPDF(){
            ViewPDFService.viewPDF(this.link, {}, 'GET', 'Temp')
            .then((response)=>{
                let file = new Blob([response.data],{type: response.headers['content-type']});
                const url = window.URL.createObjectURL(file);
                const tipoArchivo = 'Titulo'
                //@ts-ignore
                this.documento = {
                    title: tipoArchivo,
                    url: url,
                    mime: response.headers['content-type'] 
                }
                this.dialog= true;
            })
        },
    }
})
