
import Vue from "vue";
import AnuncioService from '@/services/AnuncioService';
import { Establecimiento } from "@/models/Establecimiento";
import router from "@/router";

export default Vue.extend({
  name: "Anuncios",
  mixins: [],
  components: {
  },
  directives: {},
  data: () => ({
    footer: process.env.VUE_APP_FOOTER_INFO,
    loading: false,
    selection: 1,
    list: [],
    idSolicitud: "",
    establecimiento: ""
  }),
  mounted() {
    this.idSolicitud = this.$route.params.idSolicitud;
    this.establecimiento = this.$route.params.establecimiento;
    console.log(this.$route.params.establecimiento);
    this.getData();
  },
  computed: {},
  watch: {},
  methods: {
    async getData(){
      const {data} = await AnuncioService.getByEstablecimiento(this.idSolicitud);
      this.list = data;
    },
    licenciaProvisional(licencia:any){
      if (licencia[0]) {
        if (licencia[0].tipo_licencia_id == 2) {
          return true;
        }
      }
      return false;
    },
    licenciaPermanente(licencia:any){
      if (licencia[0]) {
        console.log(licencia[0].tipo_licencia_id);
        if (licencia[0].tipo_licencia_id == 1) {
          return true;
        }
      }
      return false;
    },
    reciboPagoAnuencia(constancia_uso_suelo:any){
      if (constancia_uso_suelo[0]) {
        if (constancia_uso_suelo[0].estatus_verificacion == 1) {
          return true;
        }
      }
      return false;
    },
    DowloadPdf() {
      window.open(process.env.VUE_APP_API_URL+"provisionalpdf", "_blank");
    },
    DowloadPermanente() {
      window.open(process.env.VUE_APP_API_URL+"permanentepdf", "_blank");
    },
    DowloadReciboPago() {
      window.open(process.env.VUE_APP_API_URL+"recibopagopdf", "_blank");
    },
    async eliminar(solicitud:any){
      AnuncioService.delete(solicitud).then((response) => {
        if (response.data.success) {
          this.$toast.success(response.data.message);
          window.location.reload();
        }else{
          this.$toast.error(response.data.message);
        }
      });
    }
  },
});
