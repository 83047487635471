import { DefaultService } from "@/services/DefaultService";
import API from "@/plugins/axios";

class ZonaUsoService extends DefaultService {
  
  constructor() {
    super('zonausos');
  }
}

export default new ZonaUsoService;