
import Vue from "vue";
import RULES from "@/mixins/rules";
import { mapGetters } from "vuex";
import RequestFormGral from "../RequestFormGral.vue";
import LicenciaService from "@/services/LicenciaService";

export default Vue.extend({
  name: "UpdateEstablishment",
  props: {
      idSolicitud: String,
      value: Object,
    },
  mixins: [RULES],
  components: { RequestFormGral },
  directives: {},
  computed: {
    ...mapGetters({
      usuario: "usuario",
      id: "id",
      contribuyente: "contribuyente",
      mini: "sidebar",
    }),
  },
  data: () => ({
    tipo_proceso: "0",
    establecimiento: "0",
    guardar: false,
    actualizar: false,
    enviado_revision: false,
    creado: false,
    id_creado: 0,
    disabled: false,
    canSubirLicencia: false,
    licenciaId: 0,
    disabledEnviar: false
  }),
  mounted() {
      console.log("mounted index", this.idSolicitud);
      this.establecimiento = this.idSolicitud;
  },
  methods: {
    async submitForm(payload: any){
      this.guardar = false;
      this.actualizar = false;
      console.log("submitform update", payload);
      if (payload.success) {
        this.creado = true;
        this.id_creado = payload.location.id;
        
        if(payload.location.licencia.length){
          this.disabledEnviar = false;
          this.licenciaId = payload.location.licencia[0].id;
          if (payload.location.licencia[0].estatus_licencia == 10 || payload.location.licencia[0].estatus_licencia == 3 || payload.location.licencia[0].estatus_licencia == 4) {
            this.enviado_revision = true;
            this.disabled = true;
          }
        }else{
          this.disabledEnviar = true;
        }
        this.canSubirLicenciaBebidas(payload);
      }
    },
    async enviarRevision(){
      const object = {
        'estatus_licencia': 10,
      }
      //@ts-ignore
      this.$refs.requestFormGral.handleFormSubmit();
      //Check if renovacion is 1 or true send to new service enviarRevision on RenovacionController
      let { data } = await LicenciaService.changeEstatus(this.licenciaId, object);
      if (data.success) {
        this.enviado_revision = true;
        this.disabled = true;
        this.$toast.success(data.message);
      } else {
        this.$toast.error(data.message)
      }
    },
    canSubirLicenciaBebidas(payload: any) {
      const pago = payload.location.archivos.find((archivo: any) => archivo.archivo.tipo_archivo == "pago_licencia_bebidas");
      if (!pago) {
        this.canSubirLicencia = false;
      }else{
        let date = new Date(pago.archivo.created_at);
        date.setMonth(date.getMonth() + 2);
        let fechaActual = new Date();
        if (date >= fechaActual) {
          this.canSubirLicencia = false;
        } else {
          this.canSubirLicencia = true;
        }
      }
      
      console.log('canSubirLicenciaBebidas', pago);
      console.log('canSubirLicenciaBebidas', this.canSubirLicencia);
      
    }
  }
});
