import {DefaultService} from "@/services/DefaultService";
import API from "@/plugins/axios";

class ContribuyenteService extends DefaultService{

    constructor() {
        super('contribuyentes');
    }

    sendGiro(contribuyente: number, data: any): Promise<any>{
        return API.post('licencia/' + contribuyente + "/solicitudGiro", data);
    }

    changeStatus(contribuyente: string|number, status: number): Promise<any>{
        return API.post(`/${this.url}/${contribuyente}/changestatus`,{ estatus: status });
    }

    generatePagoPredial(cvecatastral: any): Promise<any>{
        return API.post('licencia/' + cvecatastral + "/generatePagoPredial");
    }
}

export default new ContribuyenteService();