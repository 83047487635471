
interface _FileDocument {
    code: string,
    file: string,
    binary: string,
    data: any
}

class FileDocument implements _FileDocument {
    code: string
    file: string
    binary: string
    data: any  

    constructor() {
        this.code = '';
        this.file = '';
        this.binary = '';
        this.data = null;
    }
}

interface _SolicitudFiscalizacion {
    id: any
    licencia_id: any
    estatus_fiscalizacion: any
    estatus_edo_cuenta: any
    estatus_pago: any
    documento_firmado: any
    documento: FileDocument
    folio: any
    motivo_rechazo_operador: any
    tipo: any
    cantidad_personas: any
    aprobado: any
}

export class SolicitudFiscalizacion implements _SolicitudFiscalizacion{
    id = 0
    licencia_id = null
    estatus_fiscalizacion = null
    estatus_edo_cuenta = null
    estatus_pago = 0
    documento_firmado = 0
    documento = new FileDocument()
    folio = ''
    motivo_rechazo_operador = ''
    tipo = ''
    cantidad_personas = null
    aprobado = false
}
