import { DefaultService } from "@/services/DefaultService";
import API from "@/plugins/axios";

class BajaEstablecimientoService {
    bajaEstablecimiento(licencia: any, data: any): Promise<any>{
        return API.post(`licencia/${licencia}/bajaEstablecimiento`, data);
    }

    getBajaEstablecimiento(licencia: any): Promise<any>{
        return API.get(`licencia/${licencia}/getBajaEstablecimiento`);
    }

    updateBajaEstablecimiento(licencia: any, solicitud: any, data: any): Promise<any>{
        return API.post(`licencia/${licencia}/updateBajaEstablecimiento/${solicitud}`, data);
    }

    chageEstatusBajaEstablecimiento(licencia: any, solicitud: any, data: any): Promise<any>{
        return API.post(`licencia/${licencia}/chageEstatusBajaEstablecimiento/${solicitud}`, data);
    }

    changeAreasEstatusBajaEstablecimiento(licencia: any, solicitud: any, data: any): Promise<any>{
        return API.post(`licencia/${licencia}/changeAreasEstatusBajaEstablecimiento/${solicitud}`, data);
    }

    notificarPagoAreaBajaEstablecimiento(licencia: any, solicitud: any, data: any): Promise<any>{
        return API.post(`licencia/${licencia}/notificarPagoAreaBajaEstablecimiento/${solicitud}`, data);
    }

    changeAdeudoAreaBajaEstablecimiento(licencia: any, solicitud: any, data: any): Promise<any>{
        return API.post(`licencia/${licencia}/changeAdeudoAreaBajaEstablecimiento/${solicitud}`, data);
    }
}

export default new BajaEstablecimientoService;