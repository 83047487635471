import { DefaultService } from "@/services/DefaultService";
import API from "@/plugins/axios";

class GiroDesarrolloService extends DefaultService {
  
  constructor() {
    super('girodesarrollo');
  }
}

export default new GiroDesarrolloService;