interface _FileDocument {
  code: string,
  file: string,
  binary: string,
  data: any  
}

class FileDocument implements _FileDocument {
  code: string
  file: string
  binary: string
  data: any  

  constructor() {
    this.code = '';
    this.file = '';
    this.binary = '';
    this.data = null;
  }
}

interface _Anuncio {
  id: number | null;
  locacion_id: number | null;
  tipo_anuncio_id: number | null;
  largo: number | null;
  ancho: number | null;
  caras: number | null;
  tipo_instalacion: any;
  iluminado: number | null;
  contenido: string;
  forma_instalacion: string;
  material_id: number | null;
  temporal: number | null;
  fecha_inicio: any;
  fecha_fin: any;
  espectacular: number | null;
  memoria_calculo_estructural: any;
  responsiva_terceros: any;
  fotografia: any;
  fotografia_archivo: any;
  memoria_calculo_estructural_archivo: any;
  responsiva_terceros_archivo: any;
  anterior_permiso_anuncio: any;
  anterior_permiso_archivo: any;
  folio: string;
  estatus_id: number
  renovacion: boolean
  documentos: {
    fotografia_anuncio: FileDocument,
    memoria_calculo: FileDocument,
    responsiva_terceros: FileDocument,
    anterior_permiso: FileDocument
  }
  pieza: number | null;
  mes: number | null;
  dia: number | null;
  is_mecanismo_electrico: any
  is_accion_viento: any
}

export class Anuncio implements _Anuncio {
  [key: string]: number | string | null | File[] | any;
  id = null;
  locacion_id: number = 0;
  tipo_anuncio_id: number = 0;
  largo: number = 0;
  ancho: number = 0;
  caras: number = 0;
  tipo_instalacion: any = "";
  iluminado: number = 0;
  contenido: string = "";
  forma_instalacion: string = "";
  material_id: number = 0;
  temporal: number = 0;
  fecha_inicio: any = "";
  fecha_fin: any = "";
  espectacular: number = 0;
  memoria_calculo_estructural: any = "";
  responsiva_terceros: any = "";
  fotografia: any = "";
  fotografia_archivo: any = "";
  memoria_calculo_estructural_archivo: any = "";
  responsiva_terceros_archivo: any = "";
  anterior_permiso_anuncio: any = "";
  anterior_permiso_archivo: any = "";
  folio: string = "";
  estatus_id: number = 0;
  renovacion: boolean = false;
  documentos: any = {
    fotografia_anuncio: new FileDocument(),
    memoria_calculo: new FileDocument(),
    responsiva_terceros: new FileDocument(),
    anterior_permiso: new FileDocument()
  };
  pieza: number = 0;
  mes: number = 0;
  dia: number = 0;
  is_mecanismo_electrico: any;
  is_accion_viento: any;
}