
import Vue from "vue";
import { mapGetters } from "vuex";

export enum  estatus {
    Validado = "Validada",
    Capturado = "Capturada",
    Rechazado = "Rechazada"
}

export default Vue.extend({
    name: "TimeLine",
    mixins: [],
    props: {
        showModal: {
            type: Boolean,
            default: false
        },
        data:{
            type: []
        }
    },
    data: () => ({
        dialog: false,
    }),
    mounted() {

    },
    computed: {
        ...mapGetters({
            usuario: "usuario",
            id: "id",
            contribuyente: "contribuyente",
            mini: "sidebar",
        }),

        showMeModal: {
            get: function () {
                this.dialog = this.showModal
                return this.dialog
            },

            set: function () {
                this.dialog = !this.showModal
            }
        }
    },
    watch: {},
    methods: {
        closeModal() {
            this.dialog = false
            this.showMeModal = false
            this.$emit('btnClose', this.dialog)
        },
        getColor(estado:number){
            switch (estado) {
                case 2:
                    return "grey"  
                case 3:
                    return "green" 
                case 4:
                    return "green"  
                case 5:
                    return "red"    
                case 8:
                    return "green"  
                case 9:
                    return "red"  
                case 10:
                    return "green"      
                default:
                    return "grey"
            }
        }
    },


});
