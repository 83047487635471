
    import Vue from 'vue'
    import RULES from "@/mixins/rules";
    import {VForm} from "@/types/formvalidate";
    import LoginService from "@/services/LoginService";

    export default Vue.extend({
        name: 'LoginForm',
        mixins: [RULES],
        directives: {},
        components: {},
        data: () => ({
            vista: 0,
            showPassword: false,
            showNewPassword: false,
            showPass1: false,
            showPass2: false,
            formLogin: {
                username: '',
                email: '',
                password: '',
                remember: false,
            },
            formNewPassword:{
                password: '',
                confirmPassword: '',
            },
            clave_temporal: false,
            userId: null,
            resolve: null as any,
            reject: null as any,
            resetPassword: false
        }),
        created() {
        },
        mounted() {
        },
        computed: {
            form(): VForm {
                return this.$refs.signin as VForm
            },
            formClaveTemporal(): VForm {
                return this.$refs.claveTemporal as VForm
            }
        },
        watch: {
        },
        methods: {
            async login(){
                if(this.form.validate()){
                    let {data} = await LoginService.login(this.formLogin);
                    if(data.success){
                      let token = {
                        token: data.data.token
                      }
                      this.userId = data.data.id
                      if (data.data.clave_temporal) {
                        this.clave_temporal = true;
                        await this.$store.dispatch('setToken', token)
                        let confirm = await this.confirmChangePassword();
                        if (confirm) {
                          await this.$store.dispatch('setToken', data.data);
                          await this.$router.push({name: 'FormUpdateContribuyente'});
                        }
                      }else{
                        await this.$store.dispatch('setToken', data.data);
                        if(data.perfil_completo)
                          await this.$router.push({name: 'establecimientos'});
                        else
                          await this.$router.push({name: 'FormUpdateContribuyente'});
                      }
                    }else
                      this.$toast.error(data.message);
                }
            },
            async changePassword(){
              let {data} = await LoginService.changePassword(this.userId, this.formNewPassword);
              if(data.success){
                this.$toast.success(data.message);
                this.resolve(true);
              }else{
                this.$toast.error(data.message);
              }
            },
            confirmChangePassword(){
              return new Promise((resolve, reject) => {
                this.resolve = resolve;
                this.reject = reject;
              })
            },
            async resetPasswordSubmit(){
              await LoginService.sendEmail(this.formLogin.username)
              .then((data)=>{
                this.$toast.success(data.data.message);
                window.location.reload();
              })
              .catch((error)=>{
                if (error.response.status === 404) {
                  this.$toast.error(error.response.data.message);
                  
                }
              })
            }
        }
    })
