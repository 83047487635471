import store from "@/store";
export default async (to, from, next) => {
    let role = store.state.role;

        if(role === 'operativo')
            next({name: 'beneficiarios'})
        /*let { allow = ["*"], deny = [] } = to.meta;
        let isAllow = allow.find((r: string) => r === "*" || r === role);
        let isDeny = deny.find((r: string) => r === "*" || r === role);
        if (!isAllow || isDeny) {
            next("/forbidden");
            return false;
        }*/
}
