
import Vue from 'vue';
import EventBus from '@/plugins/event-bus';
import LicenciaService from '@/services/LicenciaService';
import Extraordinario from './Extraordinario.vue';
import CustomFile from "@/components/Common/CustomFile.vue";
import { SolicitudFiscalizacion } from '@/models/SolicitudFiscalizacion';

export default Vue.extend({
    name: 'SolicitudesExtraordinarios',
    components: {
        Extraordinario, CustomFile
    },
    props: {
        selectedLicencia: {
            type: Object,
        },
        open:{
            type: Boolean,
            default: false,
        }
    },
    computed: {
        
    },
    watch: {
        open: {
            handler(newVal){
                if (newVal){
                    this.licencia = this.selectedLicencia;
                    this.getSolicitudExtraordinarias();
                }
            }
        }
    },
    mounted(){
        EventBus.$on("close-extraordinario", ()=>{
            this.solicitud = null;
            this.actualizar = false;
            this.dialogExtraordinario = false;
        });
        EventBus.$on("save-extraordinario", ()=>{
            this.getSolicitudExtraordinarias();
        });
    },
    data() {
        return {
            menu: false,
            disabled: false,
            actualizar: false,
            dialogExtraordinario: false,
            dialogPDF: false,
            dialogEnviar: false,
            dialogRechazar: false,
            solicitudes : [] as SolicitudFiscalizacion[],
            licencia: null as any,
            solicitud: null,
            documento: null,
            uploadPercentage: 0,
            selectedSolicitud: null,
            disabledEstatus: [4, 3, 10, 14],
            motivoRechazo: '',
            tipo: '',
        }
    },
    methods: {
        getSolicitudExtraordinarias(){
            this.solicitudes = [];
            LicenciaService.getSolicitudExtraordinarias(this.licencia.id)
            .then((response) => {
                let index = 0;
                response.data.forEach((solicitud: any) => {
                    let newSolicitud = new SolicitudFiscalizacion();
                    this.solicitudes.push({
                        ...newSolicitud,
                        ...solicitud,
                    })
                })
            });
        },
        close(){
            this.solicitudes = [];
            EventBus.$emit("close-solicitud-extraordinario");
        },
        openExtraordinario(tipo: string){
            this.tipo = tipo;
            this.dialogExtraordinario = true;
        },
        editarSolicitud(solicitud: any){
            this.tipo = solicitud.tipo;
            this.solicitud = solicitud;
            this.actualizar = true;
            this.dialogExtraordinario = true;
        },
        imprimirSolicitud(solicitud: any){
            let url = `licencia/${this.licencia.id}/imprimirSolicitudExtraordinaria/${solicitud.id}`;
            LicenciaService.imprimirSolicitud(url, {}, 'GET', 'Temp')
            .then((response) => {
                let file = new Blob([response.data], { type: response.headers['content-type'] });
                const url = window.URL.createObjectURL(file);
                const tipoArchivo = 'Solicitud de Horario Extraordinario';
                //@ts-ignore
                this.documento = {
                    title: tipoArchivo,
                    url: url,
                    mime: response.headers['content-type']
                }
                this.dialogPDF = true;
            })
            .catch((error) => {
                
            })
        },
        uploadSolicitudFirmada(documento: any, solicitud: any){
            this.disabled = true;
            let formData = new FormData();
            formData.append(`file`, documento.file);
            formData.append(`code`, documento.code);
            LicenciaService.uploadSolicitudFirmada(this.licencia.id, solicitud.id, formData)
            .then((response) => {
                if (response.data.success) {
                    this.$toast.success("Solicitud actualizada exitosamente");
                    this.getSolicitudExtraordinarias();
                } else {
                    this.$toast.error(response.data.message);
                }
                this.disabled = false;
            })
            .catch((error) => {
                this.$toast.error(error.response.data.message);
                this.disabled = false;
            })
        },
        openAlert(solicitud: any){
            this.tipo = solicitud.tipo;
            this.selectedSolicitud = solicitud;
            this.dialogEnviar = true;
        },
        changeSolicitudEstatus(solicitud: any, estatus: any){
            let data = {
                'estatus_fiscalizacion': estatus
            }
            this.disabled = true;
            LicenciaService.changeSolicitudEstatus(this.licencia.id, solicitud.id, data)
            .then((response) => {
                if (response.data.success) {
                    this.$toast.success("Solicitud actualizada exitosamente");
                    this.cancelarEnvio();
                    this.getSolicitudExtraordinarias();
                } else {
                    this.$toast.error(response.data.message);
                }
                this.disabled = false
            })
            .catch((error) => {
                this.$toast.error(error.response.data.message);
                this.disabled = false
            })
        },
        cancelarEnvio(){
            this.tipo = '';
            this.selectedSolicitud = null;
            this.dialogEnviar = false;
        },
        viewEdoCuentaFiscalizacion(solicitud: any){
            let url = `licencia/${this.licencia.id}/viewEdoCuentaFiscalizacion/${solicitud.id}`
            LicenciaService.viewEdoCuentaFiscalizacion(url, {}, 'GET', 'Temp')
            .then((response) => {
                let file = new Blob([response.data], { type: response.headers['content-type'] });
                const url = window.URL.createObjectURL(file);
                const tipoArchivo = 'Estado de cuenta de horario extraordinario';
                //@ts-ignore
                this.documento = {
                    title: tipoArchivo,
                    url: url,
                    mime: response.headers['content-type']
                }
                this.dialogPDF = true;
            })
            .catch((error) => {
                this.$toast.error(error.response.data.message);
            })
        },
        openRechazarSolicitud(solicitud: any){
            this.motivoRechazo = solicitud.motivo_rechazo_operador
            this.selectedSolicitud = solicitud;
            this.dialogRechazar = true;
        },
        closeRechazarSolicitud(){
            this.dialogRechazar = false;
            this.motivoRechazo = '';
            this.selectedSolicitud = null;
        },
        verConstancia(solicitud: any){
            return solicitud.estatus_fiscalizacion == 4 && solicitud.estatus_edo_cuenta == 4 && solicitud.estatus_pago == 4 && solicitud.firma_operador == 1 && solicitud.firma_fiscalizador == 1;
        },
        viewConstanciaExtraordinaria(solicitud: any){
            this.selectedSolicitud = solicitud;
            let url = `licencia/${this.licencia.id}/viewConstanciaExtraordinaria/${solicitud.id}`
            LicenciaService.viewConstanciaExtraordinaria(url, {}, 'GET', 'Temp')
            .then((response) => {
                let file = new Blob([response.data], { type: response.headers['content-type'] });
                const url = window.URL.createObjectURL(file);
                const tipoArchivo = 'Estado de cuenta de horario extraordinario';
                //@ts-ignore
                this.documento = {
                    title: tipoArchivo,
                    url: url,
                    mime: response.headers['content-type']
                }
                this.dialogPDF = true;
            })
            .catch((error) => {
                this.$toast.error(error.response.data.message);
            })
        }
    }
});
