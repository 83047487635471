
import Vue from "vue";
import UsoSueloService from '@/services/UsoSueloService';

export default Vue.extend({
  name: "UsoSuelo",
  mixins: [],
  components: {
  },
  directives: {},
  data: () => ({
    footer: process.env.VUE_APP_FOOTER_INFO,
    loading: false,
    selection: 1,
    list: [],
    items: [
        { title: 'Click Me' },
        { title: 'Click Me' },
        { title: 'Click Me' },
        { title: 'Click Me 2' },
      ],
  }),
  mounted() {
    this.getData();
  },
  computed: {},
  watch: {},
  methods: {
    async getData(){
      const {data} = await UsoSueloService.getByEstablecimiento('1');
      this.list = data;
    },
  },
});
