
import Vue from "vue";
import EstablecimientoService from '@/services/EstablecimientoService';
import {mapGetters} from "vuex";
import Notification from "@/components/Establishments/Notification.vue";
import TimeLine from "@/components/Common/TimeLine.vue";
import {Anuncio} from "@/models/Anuncio";
import AnuncioForm from "@/components/Anuncios/Create/RequestForm.vue";
import ConveniosForm from "@/components/Establishments/ConveniosForm.vue";
import {LicenciaConvenio} from "@/models/LicenciaConvenio";
import LicenciaConvenioForm from "@/components/Establishments/LicenciaConvenio.vue";
import LicenciaService from "@/services/LicenciaService";
import Extraordinario from "@/components/Establishments/Extraordinario.vue";
import EventBus from "@/plugins/event-bus";
import SolicitudesExtraordinarios from "@/components/Establishments/SolicitudesExtraordinarios.vue";
import CustomFile from "@/components/Common/CustomFile.vue";
import NombreComercial from "@/components/Solicitudes/NombreComercial.vue";
import BajaEstablecimiento from "@/components/Solicitudes/BajaEstablecimiento.vue";
import CambioDomicilio from "@/components/Solicitudes/CambioDomicilio.vue";
import DeleteLocation from "@/components/Common/DeleteLocation.vue";
import { SolicitudAnuncio } from "@/models/SolicitudAnuncio";
import AnuncioService from "@/services/AnuncioService";
import CambioActividadEconomica from "@/components/Solicitudes/CambioActividadEconomica.vue";

export default Vue.extend({
    name: "Establecimientos",
    mixins: [],
    components: {
        Notification, TimeLine, AnuncioForm, ConveniosForm, Extraordinario, SolicitudesExtraordinarios, 
        CustomFile, NombreComercial, BajaEstablecimiento, CambioDomicilio, DeleteLocation, CambioActividadEconomica
    },
    directives: {},
    data: () => ({
        procesos: [
            'Validación',
            'Uso suelo',
            'Dictamen',
            'Residuos'
        ],
        solicitudesAnuncios : [] as any[],
        opcionRenovar: '',
        dialogTimeLine: false,
        dialogSolicitarAnuncio: false,
        procesoListado: [] as any[],
        filters: {
            page: 1,
            external_id: "",
            nombre_comercial: "",
        },
        dialog: false,
        dialogUploadNewFile: false,
        dialogRecibo: false,
        dialogPredial: false,
        dialogCambioDomicilio: false,
        dialogAutorizacionfile: false,
        dialogCambioActividadEconomica: false,
        dialogView: false,
        dialogExtemporaneo: false,
        dialogRenovar: false,
        dialogLicenciasRenovar: false,
        dialogSolicitudExtraordinario: false,
        dialogEstadoGenerado: false,
        selected_item: null,
        claveEstablecimientoRenovar: "",
        donativoRenovar: false,
        claveCatastralRenovar: "",
        rfcRenovar: "",
        footer: process.env.VUE_APP_FOOTER_INFO,
        loading: false,
        selection: 1,
        convenios: [] as any[],
        tipoProceso: null,
        selectedItem: null as any,
        hasClaveCatastral: true,
        list: [],
        pagination: {
            from: 1,
            last_page: 1,
        },
        documento: null,
        hasAnuncios: false,
        hasConvenios: false,
        formAnuncios: [] as Anuncio[],
        disabled: false,
        formConvenios: [] as LicenciaConvenio[],
        dialogLicencia: false,
        title: "",
        dialogViewRecibo: false,
        selectedLicencia: null as any,
        licenciasRenovar: [] as any[],
        contador: "",
        isSuspended: false,
        formData: new Anuncio(),
        dialogFile: false,
        changeFileRecibo: true,
        dialogCambioNombreComercial: false,
        dialogBajaEstablecimiento: false,
        dialogNotificacion: false,
        formRS: {
            mes_fin: null,
        },
        meses: [
              { id: "01", descripcion: "Enero" },
              { id: "02", descripcion: "Febrero" },
              { id: "03", descripcion: "Marzo" },
              { id: "04", descripcion: "Abril" },
              { id: "05", descripcion: "Mayo" },
              { id: "06", descripcion: "Junio" },
              { id: "07", descripcion: "Julio" },
              { id: "08", descripcion: "Agosto" },
              { id: "09", descripcion: "Septiembre" },
              { id: "10", descripcion: "Octubre" },
              { id: "11", descripcion: "Noviembre" },
              { id: "12", descripcion: "Diciembre" },
            ],
        formDataAnuncio: new Anuncio(),
        dialogPDF: false,
        selectedSolicitud : null as any,
        dialogSendReview: false,
        selectedLocacion: null as any,
    }),
    mounted() {
        this.getData();
        EventBus.$on("close-solicitud-extraordinario", () => {
            this.dialogSolicitudExtraordinario = false
        });
        EventBus.$on("update-registers", () => {
            this.getData();
        })
    },
    computed: {
        ...mapGetters({
            usuario: "usuario",
            id: "id",
            contribuyente: "contribuyente",
            mini: "sidebar",
        }),
    },
    watch: {
        opcionRenovar: function () {
            console.log("opcionRenovar",this.opcionRenovar);
            if (this.opcionRenovar == 'donativo') {
                this.claveCatastralRenovar = 'DONATIVO';
                this.donativoRenovar = true;
            }else if (this.opcionRenovar == 'zona_federal') {
                this.claveCatastralRenovar = 'ZONA FEDERAL';
                this.donativoRenovar = true;
            }else if(this.opcionRenovar == 'mercado'){
                this.claveCatastralRenovar = 'MERCADO';
                this.donativoRenovar = true;
            }else{
                this.claveCatastralRenovar = '';
                this.donativoRenovar = false;
            }
        },
        "filters.page": function () {
            this.getData();
            //this.saveFilters();
        },
    },
    methods: {
        cambioDomicilio(item: any){
            this.selectedItem = item;
            this.dialogCambioDomicilio = true;
        },
        cambioActividadEconomica(item: any){
            this.selectedItem = item;
            this.dialogCambioActividadEconomica = true;
        },
        closeCambioDomicilio(){
            this.dialogCambioDomicilio = false;
        },
        closeCambioActividadEconomica(){
            this.dialogCambioActividadEconomica = false;  
        },
        async changeStatus(solicitud: any, estatus: any) {
        let data = {
          'solicitud': solicitud,
          'estatus_id': estatus
        }

        // this.disabled = true;
        await AnuncioService.changeStatus(solicitud.id, data)
          .then((response) => {
            if (response.data.success) {
              this.$toast.success("Solicitud enviada exitosamente");
              this.hasAnuncios = false;
              this.cancelarEnvio();
            } else {
              this.$toast.error(response.data.message);
            }
          })
          .catch((error) => {
            this.$toast.error(error.response.data.message);
          })
      },
      cancelarEnvio() {
        this.selectedSolicitud = null;
        this.dialogSendReview = false;
      },
      openAlertReview(solicitud: any) {
        this.selectedSolicitud = solicitud;
        this.dialogSendReview = true;
      },
      async viewPrintedRequest(solicitud: any) {
        console.log('solicitud a imprimir', solicitud);
        let url = `anuncio/establecimiento/${solicitud.locacion_id}/printRequest/${solicitud.id}`;
        await AnuncioService.viewPrintedRequest(url, {}, 'GET', 'Temp')
          .then((response) => {
            let file = new Blob([response.data], { type: response.headers['content-type'] });
            const url = window.URL.createObjectURL(file);
            const tipoArchivo = 'Solicitud de Anuncio Publicitario';
            //@ts-ignore
            this.documento = {
              title: tipoArchivo,
              url: url,
              mime: response.headers['content-type']
            }
            this.dialogPDF = true;
          })
          .catch((error) => {

          })
      },
      async generarFmtAutorizacion(solicitud: any) {
        await AnuncioService.generarFmtAutorizacion(
            `anuncio/${solicitud.id}/generateformatoautorizacion`,
            'POST',
            'Temp'
        )
            .then((response) => {
              let file = new Blob([response.data],{type: response.headers['content-type']});
              const url = window.URL.createObjectURL(file);
              const tipoArchivo = 'test'
              //@ts-ignore
              this.documento = {
                title: tipoArchivo,
                url: url,
                mime: response.headers['content-type']
              }
              this.dialogAutorizacionfile = true;
              this.$toast.success('Formato de autorización generado.');
            }).catch((error) => {
              this.$toast.error(error.response.data.message)
            })
      },
      formatCustomDate(dateString: Date) {
        const date = new Date(dateString);
        const days = ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado'];
        const months = ['enero', 'febrero', 'marzo', 'abril', 'mayo', 'junio', 'julio', 'agosto', 'septiembre', 'octubre', 'noviembre', 'diciembre'];

        const dayName = days[date.getDay()];
        const day = date.getDate();
        const monthName = months[date.getMonth()];
        const year = date.getFullYear();

        return `${dayName} ${day} de ${monthName} del ${year}`;
      },
      setColor(item: any, type: any) {
        switch (type) {
          case 1:
            if (item) {
              switch (item.id) {
                case 10:
                  return "grey";
                case 4:
                  return "green";
                case 5:
                  return "red";
              }
            }
            break;
        }
        return "";
      },
      setStyle(item: any, type: any) {
        switch (type) {
          case 1:
            if (item) {
              switch (item.id) {
                case 10:
                  return "color: white";
                case 4:
                  return "color: white";
                case 5:
                  return "color: white";
              }
            }
            break;
        }
        return "color: black";
      },
      deleteLocacion(item: any){
            this.getData();
        },
        viewReciboPagoExtemporaneo(item: any){
            this.dialogRecibo = true;
            this.changeFileRecibo = true;
            LicenciaService.getReciboPago(item.id, 'recibo_pago_extemporaneo')
            .then((response) => {
                if (response.data.data) {
                    let object = {
                        id: response.data.data
                    };
                    this.selectedItem.recibo_pago_extemporaneo = object;
                }
            });
        },
        subirReciboPagoExtemporaneo(item: any){
            //this.disabled = true;
            this.changeFileRecibo = false;
            let formData = new FormData();
            formData.append(`file`, item.recibo_pago_extemporaneo_binary);
            formData.append(`code`, 'recibo_pago_extemporaneo');
            //console.log(item);
            LicenciaService.uploadReciboPago(item.id, formData)
            .then((response) => {
                if (response.data.success) {
                    this.$toast.success("Recibo cargado exitosamente");
                    this.dialogRecibo = false;
                    //this.getSolicitudExtraordinarias();
                } else {
                    this.$toast.error(response.data.message);
                    this.dialogRecibo = false;
                }
                //this.disabled = false;
            })
            .catch((error) => {
                this.$toast.error(error.response.data.message);
                //this.disabled = false;
                this.dialogRecibo = false;
            })
        },
        pagarPredialLinea(){
            window.open('http://sistemas.opb.gob.mx/epredialopb/', '_blank')
        },
        setCedula(e: any){
            const fileReader = new FileReader()
            fileReader.addEventListener('load', () => {
                const file = fileReader.result;
                this.formData.constancia_cedula_catastral_archivo = file + "";
            });
            fileReader.readAsDataURL(e);
        },
        async uploadCedulaCatastral(locacion_id: number){
            const object = {
                "cedula_catastral": this.formData.constancia_cedula_catastral_archivo,
            }

      await EstablecimientoService.uploadCedulaCatastral(locacion_id, object)
          .then((response) => {
            //this.hasAnuncios = false;
            this.dialogUploadNewFile = false;
            this.getData();
            this.$toast.success(response.data.message);
          })
    },
    async showFile(id: any) {
      //@ts-ignore
      const response = await EstablecimientoService.descargarFormato(`documentacion/${id}`, {}, 'GET', 'Temp');

      let file = new Blob([response.data], {type: response.headers['content-type']});
      const url = window.URL.createObjectURL(file);

      //@ts-ignore
      this.documento = {
        //@ts-ignore
        title: this.label,
        url: url,
        mime: response.headers['content-type']
      }
      this.dialogFile = true;
    },
    async generarPagoPredial() {
      await EstablecimientoService.newRequest(`licencia/${this.claveCatastralRenovar}/generatePagoPredial`, {}, 'POST', 'Temp')
          .then((response) => {
            let file = new Blob([response.data], {type: response.headers['content-type']});
            const url = window.URL.createObjectURL(file);
            const tipoArchivo = 'Estado de cuenta.'
            //@ts-ignore
            this.documento = {
              title: tipoArchivo,
              url: url,
              mime: response.headers['content-type']
            }
            this.dialogView = true;
          })
    },
    async checkPendingLiquorLicenses() {
      await LicenciaService.checkPendingLiquorLicenses()
          .then((response) => {

          });
    },
    checkAlcoholLicenseDate(item: any) {
      const pago = item.archivos.find((archivo: any) => archivo.archivo.tipo_archivo === 'pago_licencia_bebidas');

      if (!pago) {
        return {
          mes: '',
          can: false
        };
      } else {
        let date = new Date(pago.archivo.created_at);
        date.setMonth(date.getMonth() + 2);
        let now = new Date();
        let mes = '';
        if (date >= now) {
          let timeDifference = date.getTime() - now.getTime();
          let daysDifference = Math.ceil(timeDifference / (1000 * 3600 * 24));
          mes = `Quedan ${daysDifference} días para subir tu licencia de bebidas alcohólicas.`;
        } else {
          mes = "Tu licencia de funcionamiento está suspendida. Debes subir tu licencia de bebidas.";
          this.isSuspended = true;
        }

        return {
          mes: mes,
          can: true
        };
      }
    },
    async getLicencia(item: any) {
      let url = `licencia/${item[0].id}/getlicencia`
      let data = {};

            await EstablecimientoService.getLicencia(url, data, 'GET', '').then((response) => {
                let file = new Blob([response.data], { type: response.headers['content-type'] });
                const url = window.URL.createObjectURL(file);
                const tipoArchivo = 'Licencia de funcionamiento'
                //@ts-ignore
                this.documento = {
                    title: tipoArchivo,
                    url: url,
                    mime: response.headers['content-type']
                }
                this.dialogLicencia = true;
            });
        },
        async imprimirAnuencia(item: any) {
            console.log(item)
            let url = `anuencia_proteccion_civil/${item.anuencia_proteccion_civil[0].id}/imprimiranuencia`;
            let data = {};
            let method = "GET";
            await EstablecimientoService.imprimirAnuencia(url, data, method, '')
                .then((response) => {
                    let file = new Blob([response.data], { type: response.headers['content-type'] });
                    const url = window.URL.createObjectURL(file);
                    const tipoArchivo = 'test'
                    //@ts-ignore
                    this.documento = {
                        title: tipoArchivo,
                        url: url,
                        mime: response.headers['content-type']
                    }
                    this.title = 'Dictamen de anuencia'
                    this.dialogViewRecibo = true;
                })
                .catch((error) => {
                    this.$toast.error(error.response.data.message)
                })
        },
        async imprimirConstancia(item: any) {
            //console.log(item)
            let url = `constancia_uso_suelo/${item.constancia_uso_suelo[0].id}/imprimirconstancia`;
            let data = {};
            let method = "POST";
            const constancia = item.constancia_uso_suelo[0]?.formato_uso_suelo;
            //const constanciaId = constancia.constancia_id;
            const tipo = constancia.tipo_formato_id;
            //const url = `constancia_uso_suelo/getFormat/${constanciaId}`;
            //await UsoSueloService.getFormat(url, {typeId: tipo}, 'POST', 'Temp')
            await EstablecimientoService.imprimir(url, {typeId: tipo}, method, '')
                .then((response) => {
                    let file = new Blob([response.data], { type: response.headers['content-type'] });
                    const url = window.URL.createObjectURL(file);
                    const tipoArchivo = 'test'
                    //@ts-ignore
                    this.documento = {
                        title: tipoArchivo,
                        url: url,
                        mime: response.headers['content-type']
                    }
                    this.title = 'Constancia de uso de suelo'
                    this.dialogViewRecibo = true;
                })
                .catch((error) => {
                    this.$toast.error(error.response.data.message)
                })
        },
        async reloadAnuncios(){
            let { data } = await EstablecimientoService.getSolicitudesAnunciosByEstablecimiento(this.selectedItem.id);
            //this.formAnuncios = data;
            this.solicitudesAnuncios = data;
        },
        async reloadConvenios(){
            this.formConvenios = [];
            let { data } = await EstablecimientoService.getConveniosByEstablecimiento(this.selectedItem.id);
            this.formConvenios = data;
        },
        async modalAnuncio(item: any) {
            this.selectedItem = item; 
            let { data } = await EstablecimientoService.getSolicitudesAnunciosByEstablecimiento(item.id);
            this.solicitudesAnuncios = data;
            this.hasAnuncios = true;
        },
        async modalConvenio(item: any) {
            this.selectedItem = item; 
            let { data } = await EstablecimientoService.getConveniosByEstablecimiento(item.id);
            this.formConvenios = data;
            this.hasConvenios = true;
        },
        setAnuncio(anuncio: Anuncio, index: any) {
            this.formAnuncios[index] = Object.assign({}, anuncio);
        },
        deleteAnuncio(index: number) {
            this.formAnuncios.splice(index, 1);
        },
        incrementAnuncio() {
            this.formAnuncios.push(new Anuncio());
            this.dialogSolicitarAnuncio = true;
            //console.log("incrementAnuncio",this.formAnuncios);
        },
        setConvenio(convenio: LicenciaConvenio, index: any) {
            this.formConvenios[index] = Object.assign({}, convenio);
        },
        deleteConvenio(index: number) {
            this.formConvenios.splice(index, 1);
        },
        incrementConvenio() {
            this.formConvenios.push(new LicenciaConvenio());
        },
        async comprobarPagoExtemporaneo(){
            let { data } = await EstablecimientoService.getPagoExtemporaneo(this.selectedItem.id);
            if (data.success) {
                this.$toast.success(data.message);
                this.dialogExtemporaneo = false;
                this.getData();
            } else {
                this.$toast.error(data.message)
            }
        },
        async generarEstadoCuenta(){
            await EstablecimientoService.newRequest(`establecimientos/estadocuentaextemporaneo/${this.selectedItem.id}`,{},'GET','Temp')
            .then((response) => {
                let file = new Blob([response.data],{type: response.headers['content-type']});
                const url = window.URL.createObjectURL(file);
                const tipoArchivo = 'Estado de cuenta.'
                //@ts-ignore
                this.documento = {
                    title: tipoArchivo,
                    url: url,
                    mime: response.headers['content-type'] 
                }
                this.dialogView = true;
            })
        },
        modalExtemporaneo(list: any){
            this.selectedItem = list;
            if (list.estatus_estado_extemporaneo == 4) {
                this.dialogExtemporaneo = true;
            }else{
                this.$toast.error("Aún no se ha generado tu estado de cuenta");
            }
            //this.dialogExtemporaneo = true;
        },
        checkExtemporaneo(list: any){
            const { extemporaneo,pago_extemporaneo,fecha_alta_hacienda,renovacion,last_movimiento } = list
            //console.log("fecha_alta_hacienda", fecha_alta_hacienda);
          if(renovacion){
            if(extemporaneo === 0){
                if (last_movimiento) {
                    const hoy = new Date(); // Fecha actual
                    const anoActual = hoy.getFullYear();
                    const anoPasado = anoActual - 1;
                    console.log("anoPasado", anoPasado);
                    console.log("last_movimiento anio", last_movimiento.anio);
                    if (last_movimiento.anio != anoPasado) {
                        return true;
                    }
                }
                const FechaHacienda = new Date(fecha_alta_hacienda)
                let fechaExtemporaneo = null;
                let fechaExtemporaneoResponse = null;
                const hoy = new Date(); // Fecha actual
                const anoActual = hoy.getFullYear(); // Obtener el año actual
                EstablecimientoService.getFechaExtemporaneo(anoActual).then((response) => {
                  fechaExtemporaneoResponse = response.data;
                  if (fechaExtemporaneoResponse !== null) {
                    const partesFecha = fechaExtemporaneoResponse.split('-');
                    const anio = parseInt(partesFecha[0], 10);
                    const mes = parseInt(partesFecha[1], 10) - 1;
                    const dia = parseInt(partesFecha[2], 10);
                    fechaExtemporaneo = new Date(anio, mes, dia);
                  } else {
                    fechaExtemporaneo = new Date(anoActual, 4, 1);
                  }
                  const condicion = (FechaHacienda >= fechaExtemporaneo) && pago_extemporaneo == 0
                  return condicion;
                });

            } else {
              return false;
            }
          }else {
            if (extemporaneo === 0) {
              //Obtener fecha límite
              var ActualInit = new Date(); //fecha actual
              const Actual = this.formatDate(ActualInit)
              //console.log("actual", Actual);
              const FechaHacienda = new Date(fecha_alta_hacienda)
              FechaHacienda.setHours(0, 0, 0, 0); //llevar a hora 00:00:00
              FechaHacienda.setDate(FechaHacienda.getDate() + 30); //sumarle 30 días
              const FechaHaciendaFormat = this.formatDate(FechaHacienda)
              //console.log("FechaHaciendaFormat", FechaHaciendaFormat);
              const condicion = (Actual >= FechaHaciendaFormat) && pago_extemporaneo == 0
              return condicion;
            } else {
              return false;
            }
          }
        },
        formatDate(date = new Date()) {
            const year = date.toLocaleString('default', {year: 'numeric'});
            const month = date.toLocaleString('default', {
                month: '2-digit',
            });
            const day = date.toLocaleString('default', {day: '2-digit'});

            return [year, month, day].join('-');
        },
        shoModalInit(item: any) {
            this.procesoListado = [];
            this.dialogTimeLine = true;
            let object;
            object = {
                'titulo': "Validación de datos",
                'descripcion': item.licencia[0].estatus.descripcion,
                'estatus': item.licencia[0].estatus.id,
                'icon': 'mdi-file-document-multiple-outline'
            };
            this.procesoListado.push(object);
            if (item.constancia_uso_suelo.length > 0) {
                object = {
                    'titulo': "Verificación de Uso de Suelo",
                    'descripcion': item.constancia_uso_suelo[0].estatus_verificacion.descripcion,
                    'estatus': item.constancia_uso_suelo[0].estatus_verificacion.id,
                    'icon': 'mdi-shield-check'
                };
                if (item.constancia_uso_suelo[0].estatus_verificacion.id == 11 && item.constancia_uso_suelo[0].estatus_general.id == 4) {
                    object = {
                        'titulo': "Verificación de Uso de Suelo",
                        'descripcion': "No aplica",
                        'estatus': 4,
                        'icon': 'mdi-shield-check'
                    };
                }
            }else{
                if (item.tiene_uso_suelo == 1) {
                    object = {
                        'titulo': "Verificación de Uso de Suelo",
                        'descripcion': 'No aplica',
                        'estatus': 4,
                        'icon': 'mdi-shield-check'
                    };
                }else{
                    object = {
                        'titulo': "Verificación de Uso de Suelo",
                        'descripcion': 'Pendiente',
                        'estatus': 2,
                        'icon': 'mdi-shield-check'
                    };
                }
                
            }
            this.procesoListado.push(object);
            if (item.constancia_uso_suelo.length > 0) {
                object = {
                    'titulo': "Pago de la Constancia de Uso de Suelo",
                    'descripcion': item.constancia_uso_suelo[0].estatus_pago ? item.constancia_uso_suelo[0].estatus_pago.descripcion : 'Pendiente',
                    'estatus': item.constancia_uso_suelo[0].estatus_pago ? item.constancia_uso_suelo[0].estatus_pago.id : 2,
                    'icon': 'mdi-cash-100'
                };
            }else{
                if (item.tiene_uso_suelo == 1) {
                    object = {
                        'titulo': "Pago de la Constancia de Uso de Suelo",
                        'descripcion': 'No aplica',
                        'estatus': 4,
                        'icon': 'mdi-cash-100'
                    };
                }else{
                    object = {
                        'titulo': "Pago de la Constancia de Uso de Suelo",
                        'descripcion': 'Pendiente',
                        'estatus': 2,
                        'icon': 'mdi-cash-100'
                    };
                }
            }
            this.procesoListado.push(object);

            if (item.constancia_uso_suelo.length > 0) {
                object = {
                    'titulo': "Validación de Uso de Suelo",
                    'descripcion': item.constancia_uso_suelo[0].estatus_general ? item.constancia_uso_suelo[0].estatus_general.descripcion : 'Pendiente',
                    'estatus': item.constancia_uso_suelo[0].estatus_general ? item.constancia_uso_suelo[0].estatus_general.id : 2,
                    'icon': 'mdi-home-city'
                };
            }else{
                if (item.tiene_uso_suelo == 1) {
                    object = {
                        'titulo': "Validación de Uso de Suelo",
                        'descripcion': 'No aplica',
                        'estatus': 4,
                        'icon': 'mdi-home-city'
                    };
                }else{
                    object = {
                        'titulo': "Validación de Uso de Suelo",
                        'descripcion': 'Pendiente',
                        'estatus': 2,
                        'icon': 'mdi-home-city'
                    };
                }
            }
            this.procesoListado.push(object);

      if (item.anuencia_proteccion_civil.length > 0) {
        object = {
          'titulo': "Pago del Dictamen de anuencia",
          'descripcion': item.anuencia_proteccion_civil[0].estatus_pago ? item.anuencia_proteccion_civil[0].estatus_pago.descripcion : 'Pendiente',
          'estatus': item.anuencia_proteccion_civil[0].estatus_pago ? item.anuencia_proteccion_civil[0].estatus_pago.id : 2,
          'icon': 'mdi-cash-100'
        };
      } else {
        object = {
          'titulo': "Pago del Dictamen de anuencia",
          'descripcion': 'Pendiente',
          'estatus': 2,
          'icon': 'mdi-cash-100'
        };
      }
      this.procesoListado.push(object);

      if (item.anuencia_proteccion_civil.length > 0) {
        object = {
          'titulo': "Validación del Dictamen de anuencia",
          'descripcion': item.anuencia_proteccion_civil[0].estatus_general ? item.anuencia_proteccion_civil[0].estatus_general.descripcion : 'Pendiente',
          'estatus': item.anuencia_proteccion_civil[0].estatus_general ? item.anuencia_proteccion_civil[0].estatus_general.id : 2,
          'icon': 'mdi-shield-sun'
        };
      } else {
        object = {
          'titulo': "Validación del Dictamen de anuencia",
          'descripcion': 'Pendiente',
          'estatus': 2,
          'icon': 'mdi-shield-sun'
        };
      }
      this.procesoListado.push(object);

      if (item.licencia[0].estatus_pago_residuos_solidos) {
        object = {
          'titulo': "Pago de Residuos sólidos",
          'descripcion': item.licencia[0].estatus_pago_residuos_solidos.descripcion,
          'estatus': item.licencia[0].estatus_pago_residuos_solidos.id,
          'icon': 'mdi-cash-100'
        };
      } else {
        object = {
          'titulo': "Pago de Residuos sólidos",
          'descripcion': 'Pendiente',
          'estatus': 2,
          'icon': 'mdi-cash-100'
        };
      }
      this.procesoListado.push(object);

      if (item.licencia[0].estatus_residuos_solidos) {
        object = {
          'titulo': "Validación de Residuos sólidos",
          'descripcion': item.licencia[0].estatus_residuos_solidos.descripcion,
          'estatus': item.licencia[0].estatus_residuos_solidos.id,
          'icon': 'mdi-delete-empty'
        };
      } else {
        object = {
          'titulo': "Validación de Residuos sólidos",
          'descripcion': 'Pendiente',
          'estatus': 2,
          'icon': 'mdi-delete-empty'
        };
      }
      this.procesoListado.push(object);

      let licencia = item.licencia[0];
      if (licencia.estatus_licencia == 4 && licencia.estatus_director_ingresos == 4 && licencia.estatus_tesorero == 4) {
        object = {
          'titulo': "Licencia aprobada y lista para visualizar",
          'descripcion': 'Lista',
          'estatus': 4,
          'icon': 'mdi-license'
        };
      } else {
        object = {
          'titulo': "Licencia aprobada y lista para visualizar",
          'descripcion': 'Pendiente',
          'estatus': 2,
          'icon': 'mdi-license'
        };
      }
      this.procesoListado.push(object);

    },
    closeModal(value: boolean) {
      this.dialogTimeLine = value
    },
    procesosBar(item: any) {
      var final = 0;
      if (item.constancia_uso_suelo.length > 0) {
        final++;

        if (item.anuencia_proteccion_civil.length > 0) {
          final++;
          if (item.licencia[0].estatus_residuos_solidos) {
            if (item.licencia[0].estatus_residuos_solidos.id == 4) {
              final++;
            }
          }/* else if(item.constancia_uso_suelo.estatus_general.id == 4){
                        final++;
                    } */
                }
            }
            return [0, final];
        },
        iniciarSolicitud() {
            //console.log(this.contribuyente);
            this.$router.push({ name: 'solicitud', query: { tipo: this.contribuyente.tipo_proceso_id } });
            /*if (this.tipoProceso != null && this.hasClaveCatastral)
                this.$router.push({ name: 'solicitud', query: { tipo: this.tipoProceso } });
            else
                this.$toast.error("En caso de no contar con clave catastral debes acudir a las oficinas del Departamento de Licencias");*/
        },
      async getSolicitudImpresa(item: any) {
        let url = '';
        let data = {};

        if (item && item.length > 0 && item[0] && item[0].id !== null) {
          url = `licencia/${item[0].id}/getsolicitudimpresaContribuyente`;
          try {
            const response = await EstablecimientoService.getLicencia(url, data, "GET", "");
            let file = new Blob([response.data], {
              type: response.headers["content-type"],
            });
            const fileUrl = window.URL.createObjectURL(file);
            const tipoArchivo = "Solicitud de licencia";
            //@ts-ignore
            this.documento = {
              title: tipoArchivo,
              url: fileUrl,
              mime: response.headers["content-type"],
            };
            this.selected_item = item;
            this.dialogEstadoGenerado = true;
          } catch (error) {
            console.error('Error al obtener la licencia:', error);
            this.$toast.error('Error al obtener la licencia de funcionamiento');
          }
        } else {
          console.log('No existe licencia ligada a esta locación');
          this.$toast.error('Este establecimiento no tiene licencia de funcionamiento');
        }
      },
        verEstablecimiento(idSolicitud: string){
          this.$router.push({ name: 'update-solicitud', params: { idSolicitud: idSolicitud } });

        },
      iniciarSolicitudSucursal() {
        this.$router.push({ name: 'solicitud', query: { tipo: this.contribuyente.tipo_proceso_id , sucursal: '1' } });
      },
        async inicioRenovacion(location: any, type: string) {
            const {data} = await EstablecimientoService.importLocation(type, location);
            if (data.success) {
                this.getData();
                this.claveCatastralRenovar = "";
                this.dialogRenovar = false;
                this.dialogLicenciasRenovar = false;
            }else{
                this.$toast.error(data.message);
            }
            //console.log(data);
            //this.$router.push({ name: 'solicitud-renovacion', query: { tipo: type, catastral: clave } });
        },
        async getLicencias() {
            if (this.claveEstablecimientoRenovar == "" || this.claveCatastralRenovar == "" || this.rfcRenovar == "") {
                this.$toast.error("Todos los campos son obligatorios");
                return;
            }
            if (!this.donativoRenovar) {
                //primero revisar predial
                EstablecimientoService.getPagoPredial(this.claveCatastralRenovar).then(async (response) => {
                    if (response.data.data) {
                        //lo siguiente
                        this.getDataRenovar();
                    } else {
                        this.$toast.error(response.data.message);
                        this.dialogPredial = true;
                    }
                }).catch(() => {
                    this.$toast.error("Ha ocurrido un error, intenta de nuevo")
                });
            }else{
                this.getDataRenovar();
            }
            
        },
        async getDataRenovar() {
            let { data } = await EstablecimientoService.getLicenciasByClave(this.claveEstablecimientoRenovar, this.claveCatastralRenovar, this.rfcRenovar);
            this.licenciasRenovar = data.data;
            this.dialogRenovar = false;
            this.dialogLicenciasRenovar = true;
        },
        async getData() {
            if (!this.filters.page) {
                this.filters.page = 1;
            }

      if (this.filters.external_id || this.filters.nombre_comercial) {
        this.filters.page = 1;
      }
      if (!this.filters.external_id && !this.filters.nombre_comercial) {
        this.filters.external_id = "";
        this.filters.nombre_comercial = "";
      }

            const { data } = await EstablecimientoService.getByContribuyente(this.contribuyente.id, this.filters);
            
            this.pagination = {
                from: data.from,
                last_page: data.last_page
            }
            //console.log(data);
            data.data.forEach((item: any) => {
                item.recibo_pago_extemporaneo_binary = null;
            })
            this.list = data.data;
            //this.list = data;
            this.checkPendingLiquorLicenses();
        },
        licenciaProvisional(licencia: any) {
            if (licencia[0]) {
                if (licencia[0].tipo_licencia_id == 2) {
                    return true;
                }
            }
            return false;
        },
        licenciaPermanente(licencia: any) {
            if (licencia[0]) {
                if (licencia[0].tipo_licencia_id == 1) {
                    return true;
                }
            }
            return false;
        },
        DowloadPdf(licencia: any) {
            window.open(process.env.VUE_APP_API_URL + "provisionalpdf/" + licencia[0].id, "_blank");
        },
        DowloadPermanente(licencia: any) {
            window.open(process.env.VUE_APP_API_URL + "permanentepdf/" + licencia[0].id, "_blank");
        },
        DowloadReciboPago(licencia: any) {
            window.open(process.env.VUE_APP_API_URL + "recibopagopdf/" + licencia[0].id, "_blank");
        },
        openExtraordinario(item: any){
            this.dialogSolicitudExtraordinario = true;
            this.selectedLicencia = item;
        },
        openCambioNombreComercial(item: any, locacion: any){
            this.dialogCambioNombreComercial = true;
            this.selectedLicencia = item;
            this.selectedLocacion = locacion;
        },
        openBajaEstablecimiento(item: any, locacion: any){
            this.dialogBajaEstablecimiento = true;
            this.selectedLicencia = item;
            this.selectedLocacion = locacion;
        },
        canNewEdoRs(item: any){
            if (item.licencia.length > 0) {
                if(item.licencia[0].anio_fin_edo_cuenta_rs && item.licencia[0].mes_fin_edo_cuenta_rs){
                    let date = new Date(item.licencia[0].anio_fin_edo_cuenta_rs + "-" + item.licencia[0].mes_fin_edo_cuenta_rs + "-01");
                    let now = new Date();
                    return now >= date;
                }
            }
            return false;
        },
        openNotification(item: any){
            this.selectedLicencia = item.licencia[0];
            this.dialogNotificacion = true;
        },
        sendNotification(item: any){
            if(!this.formRS.mes_fin){
                this.$toast.error("Debes seleccionar un mes");
                return;
            }
            
            LicenciaService.notifyEdoRs(item.locacion_id, this.formRS)
            .then((response) => {
                if (response.data.success) {
                    this.dialogNotificacion = false;
                    this.$toast.success(response.data.message);
                } else {
                    this.$toast.error(response.data.message);
                }
            })
            .catch(() => {
                this.$toast.error("Ha ocurrido un error, intenta de nuevo")
            })
        },
        tipoLocacionText(item: any){
            switch (item.tipo_local_id) {
                case 1:
                    return "NORMALES";
                    break;
                case 2:
                    return "MERCADO";
                    break;
                case 3:
                    return "PLAZAS";
                    break;
                case 4:
                    return "TIANGUIS";
                    break;
                case 5:
                    return "TERMINAL";
                    break;
                case 6:
                    return "AEROPUERTO";
                    break;
                case 7:
                    return "CLINICA";
                    break;
                case 8:
                    return "HOSPITAL";
                    break;
                case 9:
                    return "CENTRO COMERCIAL";
                    break;
                case 10:
                    return "CENTRO MEDICO";
                    break;
                case 111:
                    return "DOMICILIO COMERCIAL";
                    break;
                default:
                    return "";
                    break;
            }
        },
        closeCambioNombreComercial(){
          this.dialogCambioNombreComercial = false;
        },
        closeBajaEstablecimiento(){
          this.dialogBajaEstablecimiento = false;
        }
    },
});
