
import Vue from "vue";
import RULES from "@/mixins/rules";

export default Vue.extend({
    name: 'InputFile2',
    props: {
        value: {},
        formRules: {
            type: Array,
            default: () => []
        },
        label: {
            type: String,
            default: () => ''
        }
    },
    computed: {
        model: {
            get() {
                return this.value
            },
            set(value) {
                this.$emit('input', value)
            }
        }
    },
    methods: {
        async setDocument(e: any, type: string) {
            this.model = await this.getData(e);
        },
        getData(file: any){
            return new Promise((resolve, reject) => {
                const fileReader = new FileReader();
                fileReader.addEventListener("load", () => {
                    let file = fileReader.result;
                    resolve(file);
                });
                if (file) {
                    fileReader.readAsDataURL(file);
                }else{
                    resolve(null);
                }
            });
        },
    }
})
