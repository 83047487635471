
/**
 * TODO: Queda pendiente agregar a los otros dos formularios lo mismo que tiene RequestForm.
 * Asi mismo, debo validar si usaré el EstablishmentService.
 */
import Vue from "vue";
import { mapGetters } from "vuex";
import RULES from "@/mixins/rules";
import { googlemaps } from "googlemaps";
import { Establecimiento } from "@/models/Establecimiento";
import EstablecimientoService from "@/services/EstablecimientoService";
import { Domicilio } from "@/models/Domicilio";

export default Vue.extend({
  name: "CreateEstablishment",
  mixins: [RULES],
  directives: {},
  data: () => ({
    defaultLatitud: 18.520326,
    defaultLongitud: -88.305718,
    date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),
    menu: false,
    loading: false,
    editable: false,
    formData: new Establecimiento(),
    disabled: false,
    disabledForm: {
      localidad: true,
      asentamiento: true,
    },
    giroActividades: [] as any[],
    giroSubactividades: [] as any[],
    actividades: [] as any,
    tipoPropiedades: [],
    categorias: [],
    tipoLocales: [] as any[],
    subtiposLocales: [] as any[],
    localidades: [],
    asentamientos: [],
    isSchool: false,
    formDomicilio: new Domicilio(),
    archivos: [],
    estatusPredio: [
      { id: 1, name: "PROPIO" },
      { id: 2, name: "ARRENDADO" },
      { id: 3, name: "COMODATO" },
      { id: 4, name: "BALDIO" },
    ],
    showInput: {},
    giro: "",
    dialog: false,
  }),
  watch: {
    "formData.giro_subactividad_id": async function (value) {
      console.log(value);
    },
    "formData.tipo_local_id": async function (tipoLocalId) {
      if (tipoLocalId !== null && tipoLocalId !== undefined) {
        //@ts-ignore
        await this.getSubtipoLocalesByTipoLocal(tipoLocalId);
      }
    },
  },
  mounted() {
    //@ts-ignore
    this.inicializacion();
    //@ts-ignore
    this.getData();
    this.formData.tipo_proceso_id = this.$route.query.tipo;
    this.formData.clave_catastral = this.$route.query.catastral;
    //@ts-ignore
    this.getDataCatastral();
  },
  computed: {
    ...mapGetters({
      usuario: "usuario",
      id: "id",
      contribuyente: "contribuyente",
      mini: "sidebar",
    }),
    isNotOwner: function(){
      return this.formData.tipo_propiedad_id != 1 && this.formData.tipo_propiedad_id != 4 && this.formData.tipo_propiedad_id != null
    },
    isBaldio: function() {
      return this.formData.tipo_propiedad_id == 4;
    },
    hasAttachFiles: function(){
      return this.isNotOwner || this.esHoteleria || this.isBaldio || this.hasAlcohol || this.isSchool
    },
    esHoteleria: function(){
      if(this.formData.giro_subactividad_id!=null){
        //@ts-ignore
        return this.formData.giro_subactividad_id.descripcion == "HOTEL" || this.formData.giro_subactividad_id.descripcion === "MOTEL"
        /*return this.formData.giro_subactividad_id.some(
          (actividad: any) => actividad.descripcion === "HOTEL" || actividad.descripcion === "MOTEL"
        );*/
      }else{
        return false;
      }
    },
    hasAlcohol: function(){
      if(this.formData.giro_subactividad_id!=null){
        //@ts-ignore
        return this.formData.giro_subactividad_id.req_patente == "SI"
        /*return this.formData.giro_subactividad_id.some(
          (actividad: any) => actividad.req_patente === "SI"
        );*/
      }else{
        return false;
      }
    }
  },
  methods: {
    async handleFormSubmit() {
      this.disabled = true;

      this.formData.latitud = this.formDomicilio.latitud;
      this.formData.longitud = this.formDomicilio.latitud;

      let response = await EstablecimientoService.create(this.formData)
        .then((response) => {
          if(response.data.success){
            this.$toast.success("Establecimiento registrado exitosamente");
            this.$emit("formSubmitted", response.data);
          }else{
            this.$toast.error("No se pudo registrar el establecimiento, revise sus información");
          }
          
        })
        .catch((error) => {
          this.disabled = false;
        });
    },
    async getData() {
      await EstablecimientoService.getGiroActividades().then((response) => {
        this.giroActividades = response.data;
      });
      await EstablecimientoService.getTipoPropiedades().then((response) => {
        this.tipoPropiedades = response.data;
      });
      await EstablecimientoService.getCategorias().then((response) => {
        this.categorias = response.data;
      });
      await EstablecimientoService.getTipoLocales().then((response) => {
        this.tipoLocales = response.data;
      });
      await EstablecimientoService.getActividades().then((response) => {
        this.actividades = response.data;
      });
    },
    async getDataCatastral() {
      /*let { data } = await EstablecimientoService.getLicenciasByClave(this.formData.clave_catastral, this.contribuyente.id_contribuyente_opb);
      let dataOpb = data.data.resultados;
      this.formData.nombre_comercial = dataOpb.d_establecimiento;
      this.formData.telefono_oficina = dataOpb.tel;*/
    },
    async getSubactividadesByGiro(giroActividadesIds: number[]) {
      try {
        const response = await EstablecimientoService.getGirosSubactividades(
          giroActividadesIds
        );
        let giros = response.data;
        this.giroSubactividades = [];
        giros.forEach((giro: any) => {
          let subactividades = giro.actividades;
          subactividades.forEach((subactividad: any) => {
            this.giroSubactividades = [
              ...this.giroSubactividades,
              subactividad,
            ];
          });
        });
      } catch (error) {
        console.error("Error al obtener subactividades:", error);
      }
    },

    async getSubtipoLocalesByTipoLocal(tipoLocalId: number) {
      try {
        const response = await EstablecimientoService.getSubtiposLocales(
          tipoLocalId
        );
        let tipos = response.data;
        tipos.forEach((tipo: any) => {
          let subtipos = tipo.subtipos;
          this.subtiposLocales = [];
          subtipos.forEach((subtipo: any) => {
            this.subtiposLocales = [...this.subtiposLocales, subtipo];
          });
        });
      } catch (error) {
        console.error("Error al obtener subactividades:", error);
      }
    },
    inicializacion() {
      let latitud =
        this.formDomicilio.latitud !== 0 && this.formDomicilio.latitud !== null
          ? this.formDomicilio.latitud
          : this.defaultLatitud;
      let longitud =
        this.formDomicilio.longitud !== 0 &&
          this.formDomicilio.longitud !== null
          ? this.formDomicilio.longitud
          : this.defaultLongitud;

      var mapOptions = {
        center: new google.maps.LatLng(latitud, longitud),
        zoom: 13,
        mapTypeId: google.maps.MapTypeId.ROADMAP,
        scrollwheel: false,
        disableDoubleClickZoom: true,
      };

      var mapa = new google.maps.Map(document.getElementById("map-canvas")!);
      mapa.setOptions(mapOptions);

      var marcador: any = null;
      var initialLocationuser = null;
      let defaultInit = false;

      /*if (navigator.geolocation && this.formDomicilio.latitud === 0) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            initialLocationuser = new google.maps.LatLng(
              position.coords.latitude,
              position.coords.longitude
            );
            mapa.setCenter(initialLocationuser);
            marcador = new google.maps.Marker({
              position: initialLocationuser,
              map: mapa,
            });
          },
          () => {
            initialLocationuser = new google.maps.LatLng(latitud, longitud);
            mapa.setCenter(initialLocationuser);
            marcador = new google.maps.Marker({
              position: initialLocationuser,
              map: mapa,
            });
          }
        );
      } else {*/
        initialLocationuser = new google.maps.LatLng(latitud, longitud);
        mapa.setCenter(initialLocationuser);
        marcador = new google.maps.Marker({
          position: initialLocationuser,
          map: mapa,
        });
      //}

      var geocoder = new google.maps.Geocoder();
      var infowindow = new google.maps.InfoWindow();

      google.maps.event.addListener(mapa, "click", (evento: any) => {
        if (marcador != null) marcador.setMap(null);
        //Obtengo las coordenadas separadas
        var latitud = evento.latLng.lat();
        var longitud = evento.latLng.lng();
        this.formDomicilio.latitud = latitud;
        this.formDomicilio.longitud = longitud;

        var coordenadas = new google.maps.LatLng(latitud, longitud);
        marcador = new google.maps.Marker({
          position: coordenadas,
          map: mapa,
          animation: google.maps.Animation.DROP,
          title: "Sede",
        });
        var latlng = { lat: parseFloat(latitud), lng: parseFloat(longitud) };
        geocoder.geocode({ location: latlng }, (results: any, status: any) => {
          if (status === "OK") {
            if (results[0]) {
              mapa.setZoom(14);

              let address = results[0].address_components;

              let cp = address.filter(
                (item: any) => item.types[0] == "postal_code"
              );

              infowindow.setContent(results[0].formatted_address);
              infowindow.open(mapa, marcador);
            } else {
              window.alert("No results found");
            }
          } else {
            window.alert("Geocoder failed due to: " + status);
          }
        });
      });
    },
    setImage(e: any, type: string){
      const fileReader = new FileReader();
      fileReader.addEventListener("load", () => {
        var file = fileReader.result;
        //@ts-ignore
        this.formData.fotos[type] = file + "";
      });
      fileReader.readAsDataURL(e);
    },
    setDocument(e: any, code: string) {
      const fileReader = new FileReader();
      fileReader.addEventListener("load", () => {
        var file = fileReader.result;
        //@ts-ignore
        let formFile = this.formData.documentos.findIndex(item => item.code == code)
        console.log(formFile);
        if(formFile > -1){
          //@ts-ignore
          this.formData.documentos[formFile].file = file + "";
        }else{
          //@ts-ignore
          this.formData.documentos.push({
            //@ts-ignore
            code: code,
            //@ts-ignore
            file: file + ""
          })
        }
      });
      fileReader.readAsDataURL(e);
    },
    showFile(file: any) {
      let archivo = null;
      archivo = this.formUser[file];

      if (archivo != null)
        //@ts-ignore
        window.open(archivo.url, "_blank", "noreferrer");
    },
    cancelChangeFile(file: any) {
      //@ts-ignore
      this.showInput[file] = false;
    },
    changeFile(file: any) {
      //@ts-ignore
      this.showInput[file] = true;
    },
  },
});
