import { DefaultService } from "@/services/DefaultService";
import axios from "@/plugins/custom-axios";
import { AxiosInstance } from "axios";

class AnuncioService {
  private API: AxiosInstance;
  private url: string = "";
  private token: string = "licencias0pB2023$.@Aa.2024";
  constructor() {
    this.API = axios('https://caribeblanco.opb.gob.mx:3000/api/sim/');
  }

  searchByClaveCatastral(clave: string): Promise<any> {
    return this.API.post(`domicilio/catastral`,{
      token: this.token,
      clave_catastral: clave
    });
  }
}

export default new AnuncioService;