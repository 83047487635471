import Vue from 'vue';
import VueRouter,{ RouteConfig  } from 'vue-router'
// @ts-ignore
import VueRouteMiddleware from 'vue-route-middleware'
// @ts-ignore
import AuthMiddleware from '@/router/middleware/auth';
// @ts-ignore
import RedirectMiddleware from '@/router/middleware/redirect';
// @ts-ignore
import AllowMiddleware from '@/router/middleware/allow-role';
// @ts-ignore
import HasProfileMiddleware from '@/router/middleware/has-profile';

import Full from '@/containers/Full.vue';
import Dashboard from '@/views/Dashboard.vue'
import Establishment from '@/views/Establecimientos/ListadoEstablecimientos.vue'
import CreateEstablishment from '@/components/Establishments/Create/index.vue'
import RenoveEstablishment from '@/components/Establishments/Renove/index.vue'
import CreateAnuncio from '@/components/Anuncios/Create/index.vue'
import CreateUsoSuelo from '@/components/UsoSuelo/Create/index.vue'
import UpdateEstablishment from '@/components/Establishments/Update/index.vue'
import IndexAnuncios from '@/components/Anuncios/index.vue'
import IndexUsoSuelo from '@/components/UsoSuelo/index.vue'
import Usuarios from '@/views/Usuarios.vue'
import Perfil from '@/views/Perfil.vue'
import FormUpdateContribuyente from '@/views/Contribuyentes/FormUpdate.vue'
import Tramites from '@/views/Tramites/Tramites.vue';
import Ventanilla from '@/views/Tramites/Ventanilla.vue';
import FullLogin from '@/containers/FullLogin.vue'
import Login from '@/views/auth/Login.vue'
import SignUp from '@/views/auth/SignUp.vue'
import IndexCambioDomicilio from '@/components/Establishments/CambioDomicilio/index.vue'
import FormCambioDomicilio from '@/components/Establishments/CambioDomicilio/Form.vue'
import MyDocuments from "@/views/Contribuyentes/MyDocuments.vue";
const Municipios = () => import(/* webpackChunkName: "municipios" */ "@/views/Catalogos/Municipios.vue")
const Localidades = () => import(/* webpackChunkName: "localidades" */ "@/views/Catalogos/Localidades.vue")

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
    {
        path: '/',
        name: 'FullLogin',
        redirect: '/signin',
        component: FullLogin,
        meta: {
            middleware: [ RedirectMiddleware ]
        },
        children: [
            {
                path: 'signin',
                name: 'LoginForm',
                component: Login,
            },
            {
                path: 'signup',
                name: 'SignUp',
                component: SignUp,
            }
        ]
    },
    {
        path: '/admin',
        name: 'Full',
        redirect: '/admin/dashboard',
        component: Full,
        children: [
            {
                path: 'dashboard',
                name: 'dashboard',
                component: Dashboard,
                meta: {
                    middleware: [ AuthMiddleware, AllowMiddleware ]
                }
            },
            {
                path: 'ventanilla',
                name: 'ventanilla',
                component: Ventanilla,
                meta: {
                    middleware: [ AuthMiddleware, AllowMiddleware ]
                }
            },
            {
                path: 'usuarios',
                name: 'usuarios',
                component: Usuarios,
                meta: {
                    middleware: [ AuthMiddleware ]
                }
            },
            {
                path: 'perfil',
                name: 'perfil',
                component: Perfil,
                meta: {
                    middleware: [ AuthMiddleware ]
                }
            },
            {
                path: 'establecimientos',
                name: 'establecimientos',
                component: Establishment,
                meta: {
                    middleware: [ AuthMiddleware, AllowMiddleware, HasProfileMiddleware ]
                }
            },
            {
                path: 'establecimientos/solicitud/:idSolicitud',
                props: true,
                name: 'update-solicitud',
                component: UpdateEstablishment,
                meta: {
                    middleware: [ AuthMiddleware, AllowMiddleware, HasProfileMiddleware ]
                }
            },
            {
                path: 'establecimientos/anuncios/:idSolicitud',
                props: true,
                name: 'index-anuncios',
                component: IndexAnuncios,
                meta: {
                    middleware: [ AuthMiddleware, AllowMiddleware, HasProfileMiddleware ]
                }
            },
            {
                path: 'establecimientos/uso-suelo/:idSolicitud',
                props: true,
                name: 'index-usosuelo',
                component: IndexUsoSuelo,
                meta: {
                    middleware: [ AuthMiddleware, AllowMiddleware, HasProfileMiddleware ]
                }
            },
            {
                path: 'establecimientos/solicitud',
                name: 'solicitud',
                component: CreateEstablishment,
                meta: {
                    middleware: [ AuthMiddleware, AllowMiddleware, HasProfileMiddleware ]
                }
            },
            {
                path: 'establecimientos/solicitud-renovacion',
                props: true,
                name: 'solicitud-renovacion',
                component: RenoveEstablishment,
                meta: {
                    middleware: [ AuthMiddleware, AllowMiddleware, HasProfileMiddleware ]
                }
            },
            {
                path: 'anuncio/solicitud/:idEstablecimiento',
                props: true,
                name: 'solicitud-anuncio',
                component: CreateAnuncio,
                meta: {
                    middleware: [ AuthMiddleware, AllowMiddleware, HasProfileMiddleware ]
                }
            },
            {
                path: 'usosuelo/solicitud',
                name: 'solicitud-usosuelo',
                component: CreateUsoSuelo,
                meta: {
                    middleware: [ AuthMiddleware, AllowMiddleware, HasProfileMiddleware ]
                }
            },
            {
                path: 'datos-contribuyente',
                name: 'FormUpdateContribuyente',
                component: FormUpdateContribuyente,
                meta: {
                    middleware: [ AuthMiddleware ]
                }
            },
            {
                path: 'mis-documentos',
                name: 'MisDocumentos',
                component: MyDocuments,
                meta: {
                    middleware: [ AuthMiddleware ]
                }
            },
            {
                path: 'tramites',
                name: 'tramites',
                component: Tramites,
                meta: {
                    middleware: [ AuthMiddleware, AllowMiddleware, HasProfileMiddleware  ]
                }
            },
            {
                path: 'catalogos',
                redirect: 'catalogos/entregables',
                name: 'Catalogos',
                meta: {
                    middleware: [ AuthMiddleware ]
                },
                component: {
                    render(c: any) {
                        return c('router-view');
                    }
                },
                children: [
                    {
                        path: 'municipios',
                        name: 'municipios',
                        component: Municipios,
                    },
                    {
                        path: 'localidades',
                        name: 'localidades',
                        component: Localidades,
                    },
                ]
            },
            {
                path: 'establecimientos/cambio_domicilio/:licecniaId',
                props: true,
                name: 'index-cambio-domicilio',
                component: IndexCambioDomicilio,
                meta: {
                    middleware: [ AuthMiddleware, AllowMiddleware, HasProfileMiddleware ]
                }
            },
            {
                path: 'establecimientos/cambio_domicilio/:licecniaId/solicitud',
                props: true,
                name: 'form-cambio-domicilio',
                component: FormCambioDomicilio,
                meta: {
                    middleware: [ AuthMiddleware, AllowMiddleware, HasProfileMiddleware ]
                }
            },
        ]
    },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach(VueRouteMiddleware());

export default router
