import { DefaultService } from "@/services/DefaultService";
import API from "@/plugins/axios";

class EstablecimientoService extends DefaultService {
  
  constructor() {
    super('establecimientos');
  }

  getByContribuyente(id: string, filters: any): Promise<any>{
    let query = new URLSearchParams(filters).toString();
    return API.get(`establecimientos/contribuyente/${id}` + '?' + query);
  }

  getByContribuyenteOriginal(id: string): Promise<any>{
    return API.get(`establecimientos/contribuyente/${id}`);
  }

  getGiroActividades(): Promise<any>{
    return API.get('giroactividades');
  }

  getTipoPropiedades(): Promise<any>{
    return API.get('tipopropiedades/list');
  }

  getGiroSubactividades(giro_actividad_id: number): Promise<any>{
    return API.get('giroactividades/' + giro_actividad_id);
  }

  getGirosSubactividades(giroActividadesIds: number[]): Promise<any>{
    return API.post('giroactividades/multiples', 
      {
        giro_actividades_ids: giroActividadesIds
      }
    );

  }

  getCategorias(): Promise<any>{
    return API.get('categorias/list');
  }

  getTipoLocales(): Promise<any>{
    return API.get('tipolocales');
  }

  getSubtiposLocales(tipo_local_id: number): Promise<any>{
    return API.get('tipolocales/subtipos/' + tipo_local_id);
  }

  getAsentamientos(cp: string): Promise<any>{
    return API.get(`cat/cp/${cp}`);
  }

  getLocalidades(entidad: string|number, municipio: string|number): Promise<any>{
    return API.get(`cat/${entidad}/${municipio}/localidades`);
  }

  getColonias(localidadId: string|number): Promise<any>{
    return API.get(`cat/colonias/${localidadId}`);
  }

  getActividades(){
    return API.get(`actividadeconomicas/list`);
  }

  getActividadesSecundarias(){
    return API.get(`actividadeconomicassecundarias/list`);
  }

  descargarFormato(url: any, data: any, type: any, name: string){
    return API.request({
        url: url,
        data: data,
        method: type,
        responseType: 'blob'
    });
  }

  getLicenciasByClave(clave: any, catastral: any, rfc: any): Promise<any>{
    return API.get(`establecimientos/getByClaveCatastral/${clave}/${catastral}/${rfc}`);
  }
  getLicenciasByClaveCatastral(catastral: any): Promise<any>{
    return API.get(`establecimientos/getByClave/${catastral}`);
  }
  sendFiles(data: any, onUploadProgress: any){
    return API.request({
        url: `establecimientos/storage/files`,
        data: data,
        method: `POST`,
        headers: {
          'Content-Type': 'multipart/form-data'
        },
        withCredentials: true,
        onUploadProgress
    });
  }
  
  getNotificaciones(establecimientoId: number): Promise<any>{
    return API.get(`establecimientos/notificaciones/${establecimientoId}`);
  }

  readNotificaciones(establecimientoId: number, notificacionId: number): Promise<any>{
    return API.post(`establecimientos/notificaciones/${establecimientoId}/${notificacionId}`);
  }

  newRequest(url: any, data: any, type: any, name: string): Promise<any>{
    return API.request({
      url: url,
      data: data,
      method: type,
      responseType: 'blob'
    });
    /* return API.get(); */
  }

  getPagoExtemporaneo(establecimiento: number): Promise<any>{
    return API.get(`establecimientos/getPagoExtemporaneo/${establecimiento}`);
  }

  updateNotification(establecimientoId: number, notificacionId: number): Promise<any>{
    return API.post(`establecimientos/notificaciones/${establecimientoId}/${notificacionId}`);
  }

  getPagoPredial(clave_catastral: any): Promise<any>{
    return API.get(`establecimientos/getPagoPredial/${clave_catastral}`);
  }

  getSolicitudesAnunciosByEstablecimiento(establecimiento: number): Promise<any>{
    return API.get(`establecimientos/getSolicitudesAnunciosByEstablecimiento/${establecimiento}}`);
  }

  getConveniosByEstablecimiento(establecimiento: number): Promise<any>{
    return API.get(`establecimientos/getConveniosByEstablecimiento/${establecimiento}}`);
  }

  importLocation(type: any, data: any){
    return API.post(`licencia/establecimientos/import/${type}`, data);
  }
  
  verifyZofemat(clave_catastral: number): Promise<any>{
    return API.post(`establecimientos/verifyzofemat/${clave_catastral}`);
  }

  expedienteZofemat(expediente: any): Promise<any>{
    return API.post(`establecimientos/expedientezofemat/${expediente}`);
  }

  imprimirAnuencia(url: any, data: any, type: any, name: string): Promise<any>{
    return API.request({
      url: url,
      data: data,
      method: type,
      responseType: 'blob'
    });
  }

  imprimir(url: any, data: any, type: any, name: string): Promise<any>{
    return API.request({
      url: url,
      data: data,
      method: type,
      responseType: 'blob'
    });
  }

  getLicencia(url: any, data: any, type: any, name: string){
    return API.request({
        url: url,
        data: data,
        method: type,
        responseType: 'blob'
    });
  }

  uploadCedulaCatastral(locacion_id: number, data: any): Promise<any>{
    return API.post(`establecimientos/uploadCedulaCatastral/${locacion_id}`, data);
  }
  getFilesByLocation(establecimiento: number): Promise<any>{
    return API.get(`establecimientos/getFilesByLocation/${establecimiento}`);
  }
  deleteFiles(archivo: number,locacion: any = null ,contribuyente: any = null,representante: any = null): Promise<any>{
    return API.post(`establecimientos/${archivo}/${locacion}/${contribuyente}/${representante}/deleteFile`);
  }
  deleteLocacion(establecimiento: number, motivoEliminar: string, estatusLicencia: number): Promise<any>{
    return API.get(`establecimientos/delete/${establecimiento}/${motivoEliminar}/${estatusLicencia}`);
  }

  getFechaExtemporaneo(anio: number): Promise<any>{
      return API.get(`getFechaConfigExtemporaneo/${anio}`);
  }
}

export default new EstablecimientoService;