
    import Vue from 'vue'
    import EventBus from '@/plugins/event-bus'
    import Footer from '@/components/Common/Footer.vue';

    export default Vue.extend({
        name: 'FullLogin',
        components: {
            Footer
        },
        data: () => ({
            dialog: false,
            overlay: false,
        }),
        created(){
            EventBus.$on('before-request', (record: any) => {
                this.overlay = true;
            });
            EventBus.$on('after-response', (record: any) => {
                this.overlay = false;
            });
            EventBus.$on('response-error', (record: any) => {
                this.overlay = false;
            });
        },
    })
